import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { motion } from 'framer-motion';
import type { ShapeType } from '../types/shapeTypes';

const shapeTypes: ShapeType[] = [
  {
    id: "round",
    title: "Round",
    description: "Equally full on top and bottom, with a rounded appearance. This shape most closely matches standard bra design and manufacturing, making it the reference point many bra makers use when creating their styles.",
    impact: "This shape tends to be the most straightforward for sizing as it closely matches standard bra cup design. Most bra styles work well, but pay attention to coverage. If experiencing spillage in your usual size, it's genuinely a size issue rather than a shape mismatch.",
    fitConsiderations: [
      "Most bra styles work well with this shape",
      "Full coverage and balconette styles provide excellent support",
      "T-shirt bras typically fit true to size",
      "Push-up styles can be chosen based on desired lift rather than fit necessity"
    ],
    sizingTips: [
      "Start with your calculated size - it's likely to be accurate",
      "If experiencing quadboob effect, size up in the cup",
      "Band size should follow your underbust measurement exactly",
      "When trying new styles, your regular size is a reliable starting point"
    ],
    fitIssues: {
      commonProblems: [
        {
          issue: "Cup wrinkling despite correct band size",
          explanation: "Even with round shapes, molded cups might create small air pockets, especially when arms are raised.",
          solutions: "Look for bras with stretch lace upper sections or try seamed cups that follow natural movement patterns."
        },
        {
          issue: "Center gore floating despite correct cup size",
          explanation: "Round shapes often have more central fullness, which can push against the center gore even in the right size.",
          solutions: "Try plunge styles with lower center gores, or look for bras with more flexible gore materials."
        }
      ],
      preventativeMeasures: [
        "Always ensure the wire width matches your breast root",
        "Check that cups fully encapsulate without compression",
        "Try bras both sitting and standing"
      ]
    },
    imagePath: "/images/rounded-breast-shape.webp",
  },
  {
    id: "teardrop",
    title: "Teardrop",
    description: "Similar to round but slightly less full on top, creating a gentle slope toward the nipple. This is a very common shape that works well with many bra styles.",
    impact: "Your regular size usually works, but cup style matters more than size adjustments. The slight difference in upper fullness means some styles might fit better than others.",
    fitConsiderations: [
      "Balconette and plunge styles often work best",
      "Full-coverage bras might gap at the top",
      "Demi cups can provide excellent shape",
      "Stretch lace upper cups work well"
    ],
    sizingTips: [
      "Start with your regular size in most styles",
      "Consider going down a cup size in full coverage",
      "Check for upper cup gapping",
      "Focus on cup style rather than size changes"
    ],
    fitIssues: {
      commonProblems: [
        {
          issue: "Upper cup gapping in full coverage styles",
          explanation: "The natural slope means less tissue to fill the upper part of full coverage cups.",
          solutions: "Try balconette or demi styles, or look for bras with stretch lace tops that conform to your shape."
        },
        {
          issue: "Spillage at bottom despite good upper fit",
          explanation: "Teardrop shapes often have more immediate projection at the wire.",
          solutions: "Look for bras with deeper bottom cups or consider seamed cups over molded styles."
        }
      ],
      preventativeMeasures: [
        "Check how the upper cup lies against your tissue",
        "Ensure the wire sits in your inframammary fold",
        "Test bras with different arm positions"
      ]
    },
    imagePath: "/images/tear-drop-breast-shape.webp",
  },
  {
    id: "eastwest",
    title: "East-West",
    description: "Breasts point outward to some degree, with nipples pointing away from the center. This natural positioning affects how breast tissue sits in different cup styles.",
    impact: "Standard sizing may need adjustment - often down a cup size but with a different style choice. The outward orientation means certain styles will work better for creating a more forward-facing appearance.",
    fitConsiderations: [
      "Look for bras with strong side support panels",
      "Push-up or plunge styles help create centeredness",
      "Side slings can help direct tissue forward",
      "Consider seamed cups over molded"
    ],
    sizingTips: [
      "Try going down a cup size in push-up styles",
      "Keep your regular band size for support",
      "Start with regular size in plunge styles",
      "Consider side support bras first"
    ],
    fitIssues: {
      commonProblems: [
        {
          issue: "Cups empty at center, fitting well at sides",
          explanation: "The natural outward orientation means less central fullness than expected.",
          solutions: "Look for bras with side support panels or try styles with less upper cup fabric near the center."
        },
        {
          issue: "Wires digging into sides",
          explanation: "East-west shapes often have more tissue along the sides.",
          solutions: "Try bras with more flexible wires or wider wire channels. Consider side-support styles."
        }
      ],
      preventativeMeasures: [
        "Check side coverage first in new styles",
        "Pay attention to where cup meets the arm",
        "Test bras with arm movement"
      ]
    },
    imagePath: "/images/east-west-breast-shape.webp",
  },
  {
    id: "athletic",
    title: "Athletic/Shallow",
    description: "Athletic or shallow breasts have tissue spread over a wider area of the chest wall, creating a less projected profile. Rather than extending outward from the chest, the tissue is distributed more evenly and flatly across a broader base.",
    impact: "May require a different approach to sizing, focusing on band size for support and cup style for coverage. Athletic shapes often benefit from styles that create a more defined silhouette.",
    fitConsiderations: [
      "Look for bras with wide, padded straps for added support",
      "Seamed cups can help create a more defined shape",
      "Consider sports bras for high-impact activities",
      "Minimizer bras can help reduce bounce"
    ],
    sizingTips: [
      "Focus on band size for support",
      "Cup size may vary based on style choice",
      "Try sports bras for high-impact activities",
      "Consider minimizer bras for reducing bounce"
    ],
    fitIssues: {
      commonProblems: [
        {
          issue: "Difficulty finding supportive bras",
          explanation: "Athletic shapes often require more support due to the wider distribution of tissue.",
          solutions: "Look for bras with wide, padded straps and consider sports bras for high-impact activities."
        },
        {
          issue: "Cups gaping due to lack of projection",
          explanation: "Athletic shapes have less natural projection, which can cause cups to gape.",
          solutions: "Try seamed cups or consider push-up styles to create a more defined shape."
        }
      ],
      preventativeMeasures: [
        "Ensure a snug band fit",
        "Check for adequate cup coverage",
        "Test bras with arm movement"
      ]
    },
    imagePath: "/images/athletic-breast-shape.webp",
  },
  {
    id: "bellshape",
    title: "Bell Shape",
    description: "Characterized by being slimmer at the top and fuller at the bottom, creating a shape similar to a bell. This shape features more volume in the lower portion of the breast, with less fullness in the upper region.",
    impact: "May require a different approach to sizing, focusing on cup style for coverage and support. Bell shapes often benefit from styles that provide additional support at the bottom of the cup.",
    fitConsiderations: [
      "Look for bras with deeper bottom cups",
      "Seamed cups can help create a more defined shape",
      "Consider balconette or demi styles",
      "Minimizer bras can help reduce bounce"
    ],
    sizingTips: [
      "Focus on cup style for coverage and support",
      "Try deeper bottom cups for added support",
      "Consider balconette or demi styles",
      "Minimizer bras can help reduce bounce"
    ],
    fitIssues: {
      commonProblems: [
        {
          issue: "Difficulty finding supportive bras",
          explanation: "Bell shapes often require more support at the bottom of the cup due to the increased volume.",
          solutions: "Look for bras with deeper bottom cups and consider seamed cups for added support."
        },
        {
          issue: "Cups gaping due to lack of upper fullness",
          explanation: "Bell shapes have less natural fullness in the upper region, which can cause cups to gape.",
          solutions: "Try balconette or demi styles to reduce gapping, or consider push-up styles to create a more defined shape."
        }
      ],
      preventativeMeasures: [
        "Ensure a snug band fit",
        "Check for adequate cup coverage",
        "Test bras with arm movement"
      ]
    },
    imagePath: "/images/bell-breast-shape.webp",
  },
  {
    id: "closeset",
    title: "Close Set",
    description: "Close set breasts have minimal natural spacing between them at the center of the chest, with breast tissue beginning very close together. This shape is characterized by less than a finger's width of space between breasts at the center chest.",
    impact: "May require a different approach to sizing, focusing on center gore comfort and cup style for coverage. Close set shapes often benefit from styles with a lower center gore.",
    fitConsiderations: [
      "Look for bras with a lower center gore",
      "Plunge styles can help reduce pressure on the center gore",
      "Consider balconette or demi styles",
      "Soft cup bras can provide added comfort"
    ],
    sizingTips: [
      "Focus on center gore comfort",
      "Try plunge styles to reduce pressure on the center gore",
      "Consider balconette or demi styles",
      "Soft cup bras can provide added comfort"
    ],
    fitIssues: {
      commonProblems: [
        {
          issue: "Center gore digging into skin",
          explanation: "Close set shapes often experience pressure on the center gore due to the minimal spacing between breasts.",
          solutions: "Look for bras with a lower center gore or consider plunge styles to reduce pressure."
        },
        {
          issue: "Cups gaping due to lack of space between breasts",
          explanation: "Close set shapes have less natural space between breasts, which can cause cups to gape.",
          solutions: "Try balconette or demi styles to reduce gapping, or consider push-up styles to create a more defined shape."
        }
      ],
      preventativeMeasures: [
        "Ensure a snug band fit",
        "Check for adequate cup coverage",
        "Test bras with arm movement"
      ]
    },
    imagePath: "/images/close-set-breast-shape.webp",
  },
  {
    id: "relaxed",
    title: "Relaxed",
    description: "Relaxed breast shape features softer tissue that naturally sits lower on the chest, often with nipples pointing slightly downward. This shape is entirely natural and common, particularly after breastfeeding, weight changes, or with age.",
    impact: "May require a different approach to sizing, focusing on band size for support and cup style for coverage. Relaxed shapes often benefit from styles that provide additional support and lift.",
    fitConsiderations: [
      "Look for bras with wide, padded straps for added support",
      "Seamed cups can help create a more defined shape",
      "Consider minimizer bras for reducing bounce",
      "Soft cup bras can provide added comfort"
    ],
    sizingTips: [
      "Focus on band size for support",
      "Try seamed cups for added support and definition",
      "Consider minimizer bras for reducing bounce",
      "Soft cup bras can provide added comfort"
    ],
    fitIssues: {
      commonProblems: [
        {
          issue: "Difficulty finding supportive bras",
          explanation: "Relaxed shapes often require more support due to the softer tissue.",
          solutions: "Look for bras with wide, padded straps and consider seamed cups for added support."
        },
        {
          issue: "Cups gaping due to lack of projection",
          explanation: "Relaxed shapes have less natural projection, which can cause cups to gape.",
          solutions: "Try seamed cups or consider push-up styles to create a more defined shape."
        }
      ],
      preventativeMeasures: [
        "Ensure a snug band fit",
        "Check for adequate cup coverage",
        "Test bras with arm movement"
      ]
    },
    imagePath: "/images/relaxed-breast-shape.webp",
  },
  {
    id: "asymmetrical",
    title: "Asymmetrical",
    description: "One breast is noticeably larger than the other. This is a very common and natural occurrence, affecting most people to some degree. The size difference can range from subtle to more noticeable.",
    impact: "May require a different approach to sizing, focusing on finding a bra that accommodates the larger breast. Asymmetrical shapes often benefit from styles with adjustable cups or removable pads.",
    fitConsiderations: [
      "Look for bras with adjustable cups or removable pads",
      "Consider bras with a molded cup design",
      "Seamed cups can help create a more defined shape",
      "Minimizer bras can help reduce bounce"
    ],
    sizingTips: [
      "Focus on finding a bra that accommodates the larger breast",
      "Try adjustable cups or removable pads for a customizable fit",
      "Consider molded cup designs for added support",
      "Minimizer bras can help reduce bounce"
    ],
    fitIssues: {
      commonProblems: [
        {
          issue: "Difficulty finding bras that fit both breasts",
          explanation: "Asymmetrical shapes often require a bra that can accommodate the larger breast.",
          solutions: "Look for bras with adjustable cups or removable pads, or consider molded cup designs for added support."
        },
        {
          issue: "Cups gaping due to size difference",
          explanation: "Asymmetrical shapes have a size difference between breasts, which can cause cups to gape.",
          solutions: "Try adjustable cups or removable pads to customize the fit, or consider push-up styles to create a more defined shape."
        }
      ],
      preventativeMeasures: [
        "Ensure a snug band fit",
        "Check for adequate cup coverage",
        "Test bras with arm movement"
      ]
    },
    imagePath: "/images/asymmetrical-breast-shape.webp",
  },
  {
    id: "sideset",
    title: "Side-Set",
    description: "Characterized by a wide space between breasts, with natural separation at the center chest. This shape differs from East-West in that the breasts themselves face forward, but have more space between them than average.",
    impact: "May require a different approach to sizing, focusing on center gore comfort and cup style for coverage. Side-set shapes often benefit from styles with a lower center gore.",
    fitConsiderations: [
      "Look for bras with a lower center gore",
      "Plunge styles can help reduce pressure on the center gore",
      "Consider balconette or demi styles",
      "Soft cup bras can provide added comfort"
    ],
    sizingTips: [
      "Focus on center gore comfort",
      "Try plunge styles to reduce pressure on the center gore",
      "Consider balconette or demi styles",
      "Soft cup bras can provide added comfort"
    ],
    fitIssues: {
      commonProblems: [
        {
          issue: "Center gore digging into skin",
          explanation: "Side-set shapes often experience pressure on the center gore due to the wide space between breasts.",
          solutions: "Look for bras with a lower center gore or consider plunge styles to reduce pressure."
        },
        {
          issue: "Cups gaping due to lack of space between breasts",
          explanation: "Side-set shapes have less natural space between breasts, which can cause cups to gape.",
          solutions: "Try balconette or demi styles to reduce gapping, or consider push-up styles to create a more defined shape."
        }
      ],
      preventativeMeasures: [
        "Ensure a snug band fit",
        "Check for adequate cup coverage",
        "Test bras with arm movement"
      ]
    },
    imagePath: "/images/side-set-breast-shape.webp",
  },
  {
    id: "fundamental",
    title: "Fundamental",
    description: "Fundamental breast shape represents what might be considered an \"average\" or \"standard\" breast shape, with moderate projection, even fullness distribution, and a balanced position on the chest wall.",
    impact: "May require a standard approach to sizing, focusing on finding a bra that provides adequate support and coverage. Fundamental shapes often benefit from a wide range of bra styles.",
    fitConsiderations: [
      "Look for bras with a balanced design",
      "Consider bras with a moderate level of projection",
      "Seamed cups can help create a more defined shape",
      "Minimizer bras can help reduce bounce"
    ],
    sizingTips: [
      "Focus on finding a bra that provides adequate support and coverage",
      "Try a balanced design for a comfortable fit",
      "Consider seamed cups for added support and definition",
      "Minimizer bras can help reduce bounce"
    ],
    fitIssues: {
      commonProblems: [
        {
          issue: "Difficulty finding supportive bras",
          explanation: "Fundamental shapes often require a bra that provides adequate support and coverage.",
          solutions: "Look for bras with a balanced design and consider seamed cups for added support."
        },
        {
          issue: "Cups gaping due to lack of projection",
          explanation: "Fundamental shapes have moderate projection, which can cause cups to gape.",
          solutions: "Try seamed cups or consider push-up styles to create a more defined shape."
        }
      ],
      preventativeMeasures: [
        "Ensure a snug band fit",
        "Check for adequate cup coverage",
        "Test bras with arm movement"
      ]
    },
    imagePath: "/images/fundamental-breast-shape.webp",
  },
  {
    id: "conical",
    title: "Conical",
    description: "Conical breasts have a cone-like shape, often being more pointed than rounded. This shape typically has a narrower base at the chest wall and comes to a more defined point.",
    impact: "Standard bra sizing might suggest a cup size larger than what actually fits comfortably, as conical tissue often needs less volume at the apex of the cup.",
    fitConsiderations: [
      "Lightly lined bras can help round out the shape if desired",
      "Look for bras with stretch fabric to accommodate the pointed apex",
      "Avoid heavily molded cups unless you want to significantly change the shape",
      "Consider seamed cups for better shape adaptation"
    ],
    sizingTips: [
      "Try going down a cup size from what measurements suggest",
      "Focus on ensuring the wire width matches your breast root",
      "Test different styles in the same size to find what works with your shape",
      "When using push-up styles, stay true to size as the padding helps round out the shape"
    ],
    fitIssues: {
      commonProblems: [
        {
          issue: "Gaps at the apex of molded cups",
          explanation: "Conical shapes come to a more defined point than the rounded apex most bra cups are designed for.",
          solutions: "Look for seamed cups that can follow your natural shape rather than molded cups. Consider stretch lace cups that conform to your tissue."
        },
        {
          issue: "Band rides up despite feeling tight enough",
          explanation: "The more concentrated projection of conical shapes can cause the band to angle upward.",
          solutions: "Try bras with deeper apex projection but less overall cup volume. Consider styles with stronger side support."
        }
      ],
      preventativeMeasures: [
        "Check how the apex of the cup aligns with your natural shape",
        "Test bras both standing and leaning to ensure consistent fit",
        "Consider how different fabrics might work with your natural projection"
      ]
    },
    imagePath: "/images/conical-breast-shape.webp",
  },
  {
    id: "fullontop",
    title: "Full on Top",
    description: "Full on top breasts have more tissue above the nipple line than below, creating an upward-weighted distribution. This shape often appears more prominent in clothing.",
    impact: "Traditional bra sizes might need adjustment upward in the cup, particularly in full-coverage styles. The extra volume in the upper portion can create spillage even when the bra technically measures as the correct size.",
    fitConsiderations: [
      "Full coverage styles work well but might need a larger cup size",
      "Balconette styles need careful fitting to prevent quadboob effect",
      "Stretch lace upper sections can accommodate the fuller top gracefully",
      "Avoid demi cups unless sizing up significantly"
    ],
    sizingTips: [
      "Start with one cup size larger than your measurements indicate",
      "Test fit by leaning forward and standing back up",
      "Ensure the center gore tacks without pushing breast tissue up",
      "When trying new styles, always check for upper cup containment"
    ],
    fitIssues: {
      commonProblems: [
        {
          issue: "Constant spillage despite sizing up",
          explanation: "Full on top shapes have more tissue above the nipple than many bra styles are designed to accommodate.",
          solutions: "Look for bras with higher cup edges or more open top sections. Consider stretch lace upper cups that can accommodate the fuller upper tissue."
        },
        {
          issue: "Center gore digs in while cups seem too small",
          explanation: "The upper fullness can push against the center gore, causing discomfort even when the cup volume is technically correct.",
          solutions: "Try plunge styles with lower center gores. Look for bras with more flexible gore materials."
        }
      ],
      preventativeMeasures: [
        "Always check the upper cup fit first when trying new styles",
        "Test bras with different arm positions as upper fullness can shift significantly",
        "Consider how different necklines will work with your shape"
      ]
    },
    imagePath: "/images/full-on-top-breast-shape.webp",
  },
];

const tabs = [
  { id: 'shapes', name: 'Shape Guide' },
  { id: 'tips', name: 'Fitting Tips' },
];

// Timeline data for breast shape changes
const timelineData = [
  {
    stage: "Puberty",
    title: "Adolescent Development",
    description: "Initial breast development with varying growth patterns",
    icon: "🌱",
    changes: [
      "Gradual tissue development",
      "May experience asymmetrical growth",
      "Shape continues to evolve"
    ]
  },
  {
    stage: "Young Adult",
    title: "Early Adulthood",
    description: "Stabilization of breast shape and size",
    icon: "⭐",
    changes: [
      "More defined shape emerges",
      "Tissue becomes firmer",
      "Natural position established"
    ]
  },
  {
    stage: "Pregnancy",
    title: "Pregnancy & Nursing",
    description: "Significant changes during pregnancy and breastfeeding",
    icon: "🤱",
    changes: [
      "Increased size and fullness",
      "Changes in tissue density",
      "Possible shape adjustments"
    ]
  },
  {
    stage: "Post-Pregnancy",
    title: "Post-Pregnancy Changes",
    description: "Adjustments after pregnancy and nursing",
    icon: "🎀",
    changes: [
      "Return to pre-pregnancy size",
      "Possible changes in fullness",
      "May need different support"
    ]
  },
  {
    stage: "Maturity",
    title: "Natural Aging",
    description: "Gradual changes through different life stages",
    icon: "🌟",
    changes: [
      "Changes in tissue elasticity",
      "Possible position changes",
      "Different support needs"
    ]
  }
];

// Updated steps for shape identification with more detailed content
const identificationSteps = [
  {
    step: 1,
    title: "The Mirror Test",
    description: "Natural observation in front of a mirror",
    detailedContent: {
      mainText: "This foundational step helps you understand your natural breast position and shape when standing normally.",
      keyPoints: [
        "Stand naturally in front of a full-length mirror with shoulders relaxed",
        "Observe where your nipples point (forward, outward, or downward)",
        "Notice if your breasts sit high, centered, or low on your chest wall",
        "Look for any asymmetry (completely normal and very common)"
      ],
      tips: [
        "Use good lighting",
        "Try both front and side views",
        "Take photos for reference (optional)"
      ],
      whatToLookFor: [
        "Natural position of nipples",
        "Overall breast placement on chest",
        "Distance between breasts",
        "General breast fullness"
      ]
    }
  },
  {
    step: 2,
    title: "The Leaning Test",
    description: "Dynamic assessment of breast tissue",
    detailedContent: {
      mainText: "This test reveals your true breast shape and volume distribution by letting gravity show how your tissue naturally falls.",
      keyPoints: [
        "Bend forward at 90 degrees, letting breasts hang naturally",
        "Notice how your tissue distributes when hanging",
        "Observe the natural fall line of your breast tissue",
        "Pay attention to where fullness concentrates"
      ],
      tips: [
        "Keep your back straight",
        "Let arms hang relaxed",
        "Try slight movements to see tissue behavior"
      ],
      whatToLookFor: [
        "Shape when hanging (round, elongated, conical)",
        "Distribution of fullness",
        "Natural center point of breast tissue",
        "Any differences between sides"
      ]
    }
  },
  {
    step: 3,
    title: "The Clock Method",
    description: "Detailed analysis of tissue distribution",
    detailedContent: {
      mainText: "This precise method helps you understand exactly where your breast tissue is distributed, which is crucial for finding the right bra style.",
      keyPoints: [
        "Imagine your breast as a clock face with nipple at center",
        "Note fullness in each quadrant (12-3, 3-6, 6-9, 9-12)",
        "Compare upper vs lower fullness",
        "Assess inner vs outer fullness"
      ],
      tips: [
        "Use both standing and leaning positions",
        "Consider marking the clock positions on your mirror",
        "Compare both breasts separately"
      ],
      whatToLookFor: [
        "Where most tissue concentrates",
        "Even vs uneven distribution",
        "Upper/lower balance",
        "Inner/outer fullness ratio"
      ]
    }
  }
];

export default function BreastShapeGuide() {
  const [expandedSection, setExpandedSection] = useState<string>('');
  const [activeTab, setActiveTab] = useState<'shapes' | 'tips'>('shapes');
  const [openShapes, setOpenShapes] = useState<string[]>([]);
  const [activeStep, setActiveStep] = useState(1);

  const toggleShape = (id: string) => {
    if (openShapes.includes(id)) {
      setOpenShapes(openShapes.filter((shape) => shape !== id));
    } else {
      setOpenShapes([...openShapes, id]);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      <Helmet>
        <title>Breast Shape & Sizing Guide - Find Your Perfect Fit | BraSizeCalculatorPro</title>
        <meta name="description" content="Learn how breast shape affects bra sizing and fit. Our comprehensive guide helps you identify your shape and find the perfect bra styles for your unique needs." />
        <meta name="keywords" content="breast shape guide, bra sizing, breast types, bra fitting, breast shape and size, bra styles by shape" />
        <link rel="canonical" href="https://brasizecalculatorpro.com/breast-shape-guide" />
      </Helmet>

      <div className="container mx-auto px-4 py-12">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">Breast Shape & Sizing Guide</h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Breast shape significantly influences how bras fit and which styles work best for your body. Understanding your shape helps identify common fit issues before they occur. Different shapes need different approaches to sizing.
          </p>
        </div>

        {/* Tab Navigation */}
        <div className="mb-8">
          <div className="border-b border-gray-200">
            <nav className="flex justify-center" aria-label="Tabs">
              <div className="flex space-x-8">
                {tabs.map((tab) => (
                  <button
                    key={tab.id}
                    onClick={() => setActiveTab(tab.id as 'shapes' | 'tips')}
                    className={`
                      py-4 px-6 border-b-2 font-medium text-lg transition-colors duration-200
                      ${activeTab === tab.id
                        ? 'border-purple-500 text-purple-900'
                        : 'border-transparent text-gray-500 hover:text-purple-700 hover:border-purple-300'
                      }
                    `}
                  >
                    {tab.name}
                  </button>
                ))}
              </div>
            </nav>
          </div>
        </div>

        {/* Shapes Content */}
        {activeTab === 'shapes' && (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
              {shapeTypes.map((shapeType) => (
                <div
                  key={shapeType.id}
                  className="bg-white rounded-xl shadow overflow-hidden transition-all duration-300 hover:shadow-md"
                >
                  <div className="p-6">
                    {shapeType.imagePath && (
                      <div className="mb-4 flex justify-center">
                        <img
                          src={shapeType.imagePath}
                          alt={`${shapeType.title} breast shape illustration`}
                          className="w-32 h-32 object-contain rounded-lg"
                        />
                      </div>
                    )}
                    <div className="border-b border-gray-100 pb-4">
                      <button
                        onClick={() => toggleShape(shapeType.id)}
                        className="w-full flex justify-between items-center"
                      >
                        <h3 className="text-xl font-semibold text-purple-900">{shapeType.title}</h3>
                        {openShapes.includes(shapeType.id) ? (
                          <ChevronUp className="w-5 h-5 text-purple-500" />
                        ) : (
                          <ChevronDown className="w-5 h-5 text-purple-500" />
                        )}
                      </button>
                      
                      <p className="text-gray-600 mt-3 leading-relaxed">{shapeType.description}</p>
                    </div>

                    {openShapes.includes(shapeType.id) && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="mt-4 space-y-6"
                      >
                        <div className="bg-purple-50 rounded-lg p-4">
                          <h4 className="font-medium text-purple-900 mb-3">Impact on Sizing</h4>
                          <p className="text-gray-600 leading-relaxed">{shapeType.impact}</p>
                        </div>

                        <div>
                          <h4 className="font-medium text-xl text-purple-900 mb-3">Fit Considerations</h4>
                          <ul className="space-y-2">
                            {shapeType.fitConsiderations.map((consideration, index) => (
                              <li key={index} className="flex text-gray-600 leading-relaxed">
                                <span className="text-purple-500 mr-2">•</span>
                                <span>{consideration}</span>
                              </li>
                            ))}
                          </ul>
                        </div>

                        <div>
                          <h4 className="font-medium text-xl text-purple-900 mb-3">Sizing Tips</h4>
                          <ul className="space-y-2">
                            {shapeType.sizingTips.map((tip, index) => (
                              <li key={index} className="flex text-gray-600 leading-relaxed">
                                <span className="text-purple-500 mr-2">•</span>
                                <span>{tip}</span>
                              </li>
                            ))}
                          </ul>
                        </div>

                        <div className="bg-purple-50 rounded-lg p-4">
                          <h4 className="font-medium text-xl text-purple-900 mb-3">Common Issues</h4>
                          {shapeType.fitIssues.commonProblems.map((problem, index) => (
                            <div key={index} className="mb-4 last:mb-0">
                              <p className="font-medium text-purple-800 mb-2">{problem.issue}</p>
                              <div className="pl-4 border-l-2 border-purple-200">
                                <p className="text-gray-600 leading-relaxed mb-2">{problem.explanation}</p>
                                <p className="text-gray-600 leading-relaxed">{problem.solutions}</p>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div>
                          <h4 className="font-medium text-xl text-purple-900 mb-3">Preventative Measures</h4>
                          <ul className="space-y-2">
                            {shapeType.fitIssues.preventativeMeasures.map((measure, index) => (
                              <li key={index} className="flex text-gray-600 leading-relaxed">
                                <span className="text-purple-500 mr-2">•</span>
                                <span>{measure}</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </motion.div>
                    )}
                  </div>
                </div>
              ))}
            </div>

            {/* Enhanced Identification Guide Section with Interactive Buttons */}
            <div className="max-w-4xl mx-auto mb-12">
              <div className="bg-white rounded-xl shadow-lg p-8">
                <h2 className="text-2xl font-semibold text-purple-900 mb-6">How to Identify Your Breast Shape</h2>
                <p className="text-gray-600 mb-8">
                Understanding your breast shape is essential for finding the perfect bra fit and preventing common fitting issues before they occur. Accurate breast shape assessment requires careful observation and patience. By using three key assessment methods, you'll develop a clear understanding of your unique shape characteristics, which will guide you to better bra choices and more successful fits.
                </p>

                {/* Step Navigation Buttons */}
                <div className="flex justify-center gap-4 mb-8">
                  {identificationSteps.map((step, index) => (
                    <button
                      key={step.step}
                      onClick={() => setActiveStep(step.step)}
                      className={`
                        flex-1 max-w-[200px] p-4 rounded-lg transition-all duration-300
                        ${activeStep === step.step 
                          ? 'bg-purple-600 text-white shadow-lg transform scale-105' 
                          : 'bg-purple-50 text-purple-900 hover:bg-purple-100'}
                      `}
                    >
                      <div className="text-lg font-semibold mb-1">Step {step.step}</div>
                      <div className={`text-sm ${activeStep === step.step ? 'text-purple-100' : 'text-purple-700'}`}>
                        {step.title}
                      </div>
                    </button>
                  ))}
                </div>

                {/* Active Step Content */}
                {identificationSteps.map((step) => (
                  <motion.div
                    key={step.step}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ 
                      opacity: activeStep === step.step ? 1 : 0,
                      y: activeStep === step.step ? 0 : 20,
                      display: activeStep === step.step ? 'block' : 'none'
                    }}
                    transition={{ duration: 0.3 }}
                  >
                    <div className="bg-purple-50 rounded-lg p-6">
                      <div className="flex items-center mb-4">
                        <div className="w-12 h-12 bg-purple-600 rounded-full flex items-center justify-center text-white font-bold text-xl mr-4">
                          {step.step}
                        </div>
                        <div>
                          <h3 className="text-xl font-semibold text-purple-900">{step.title}</h3>
                          <p className="text-purple-700">{step.description}</p>
                        </div>
                      </div>

                      <p className="text-gray-600 mb-6">{step.detailedContent.mainText}</p>

                      <div className="grid md:grid-cols-2 gap-6">
                        <div>
                          <h4 className="font-semibold text-purple-900 mb-3">Key Steps</h4>
                          <ul className="space-y-2">
                            {step.detailedContent.keyPoints.map((point, idx) => (
                              <li key={idx} className="flex items-start space-x-2">
                                <div className="w-6 h-6 rounded-full bg-purple-200 flex-shrink-0 flex items-center justify-center text-purple-700 font-medium mt-0.5">
                                  {idx + 1}
                                </div>
                                <span className="text-gray-600">{point}</span>
                              </li>
                            ))}
                          </ul>
                        </div>

                        <div>
                          <h4 className="font-semibold text-purple-900 mb-3">What to Look For</h4>
                          <ul className="space-y-2">
                            {step.detailedContent.whatToLookFor.map((item, idx) => (
                              <li key={idx} className="flex items-center text-gray-600">
                                <span className="text-purple-500 mr-2">•</span>
                                <span>{item}</span>
                              </li>
                            ))}
                          </ul>

                          <div className="mt-6">
                            <h4 className="font-semibold text-purple-900 mb-3">Pro Tips</h4>
                            <ul className="space-y-2">
                              {step.detailedContent.tips.map((tip, idx) => (
                                <li key={idx} className="flex items-center text-gray-600">
                                  <span className="text-yellow-500 mr-2">💡</span>
                                  <span>{tip}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                ))}

                {/* Navigation Arrows */}
                <div className="flex justify-between mt-6">
                  <button
                    onClick={() => setActiveStep(prev => Math.max(1, prev - 1))}
                    className={`px-4 py-2 rounded-lg transition-all duration-300 ${
                      activeStep === 1 ? 'invisible' : 'bg-purple-100 text-purple-700 hover:bg-purple-200'
                    }`}
                  >
                    ← Previous Step
                  </button>
                  <button
                    onClick={() => setActiveStep(prev => Math.min(3, prev + 1))}
                    className={`px-4 py-2 rounded-lg transition-all duration-300 ${
                      activeStep === 3 ? 'invisible' : 'bg-purple-100 text-purple-700 hover:bg-purple-200'
                    }`}
                  >
                    Next Step →
                  </button>
                </div>
              </div>
            </div>

            {/* Shape Changes Timeline Section */}
            <div className="max-w-4xl mx-auto mb-12">
              <div className="bg-white rounded-xl shadow-lg p-8 mb-8">
                <h2 className="text-2xl font-semibold text-purple-900 mb-6">How Breast Shape Changes Over Time</h2>
                <p className="text-gray-600 mb-8">
                  Breast shape naturally evolves throughout different life stages. Understanding these changes helps you adapt your bra choices for optimal support and comfort.
                </p>
                
                <div className="relative">
                  {/* Timeline line */}
                  <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-0.5 bg-purple-200"></div>
                  
                  {/* Timeline items */}
                  {timelineData.map((item, index) => (
                    <div key={item.stage} className={`relative flex items-center mb-8 ${
                      index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'
                    }`}>
                      {/* Content */}
                      <motion.div 
                        initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5, delay: index * 0.1 }}
                        className={`w-5/12 ${index % 2 === 0 ? 'pr-8 text-right' : 'pl-8'}`}
                      >
                        <div className="bg-purple-50 rounded-lg p-6 hover:shadow-md transition-shadow duration-300">
                          <div className="text-2xl mb-2">{item.icon}</div>
                          <h3 className="text-xl font-semibold text-purple-900 mb-2">{item.title}</h3>
                          <p className="text-gray-600 mb-4">{item.description}</p>
                          <ul className="space-y-2">
                            {item.changes.map((change, idx) => (
                              <li key={idx} className="flex text-gray-600">
                                <span className="text-purple-500 mr-2">•</span>
                                <span>{change}</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </motion.div>
                      
                      {/* Timeline point */}
                      <div className="absolute left-1/2 transform -translate-x-1/2 w-4 h-4 bg-purple-500 rounded-full">
                        <div className="absolute w-8 h-8 bg-purple-200 rounded-full -m-2 animate-ping"></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}

        {/* Tips Content */}
        {activeTab === 'tips' && (
          <div className="space-y-8">

          <div className="space-y-12 max-w-7xl mx-auto px-4">
            {/* General Guidelines Section */}
            <div className="bg-white rounded-2xl shadow p-8 transform hover:shadow-sm transition-all duration-300">
              <h2 className="text-2xl font-semibold text-purple-900 mb-8 relative pb-3 after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-24 after:h-1 after:bg-purple-500 after:rounded-full">Bra Fitting Guidelines</h2>
              <div className="bg-purple-50 rounded-xl p-8">
                <p className="text-gray-600 leading-relaxed mb-6 text-lg">
                The way a bra interacts with different parts of your body will reveal whether it's properly supporting and fitting you. Observe these key fit areas below when trying on your new bra.
                </p>
                <div className="grid md:grid-cols-2 gap-6">
                  <div className="bg-white rounded-lg p-6 shadow-sm">
                    <div className="flex items-center text-gray-600 leading-relaxed">
                      <div className="w-8 h-8 bg-purple-100 rounded-lg flex items-center justify-center mr-4 flex-shrink-0">
                        <span className="text-purple-700">1</span>
                      </div>
                      <span>The band should be snug but not tight, sitting parallel to the ground</span>
                    </div>
                  </div>
                  <div className="bg-white rounded-lg p-6 shadow-sm">
                    <div className="flex items-center text-gray-600 leading-relaxed">
                      <div className="w-8 h-8 bg-purple-100 rounded-lg flex items-center justify-center mr-4 flex-shrink-0">
                        <span className="text-purple-700">2</span>
                      </div>
                      <span>Cups should fully encapsulate breast tissue without spillage or gaping</span>
                    </div>
                  </div>
                  <div className="bg-white rounded-lg p-6 shadow-sm">
                    <div className="flex items-center text-gray-600 leading-relaxed">
                      <div className="w-8 h-8 bg-purple-100 rounded-lg flex items-center justify-center mr-4 flex-shrink-0">
                        <span className="text-purple-700">3</span>
                      </div>
                      <span>The center gore should lay flat against your sternum</span>
                    </div>
                  </div>
                  <div className="bg-white rounded-lg p-6 shadow-sm">
                    <div className="flex items-center text-gray-600 leading-relaxed">
                      <div className="w-8 h-8 bg-purple-100 rounded-lg flex items-center justify-center mr-4 flex-shrink-0">
                        <span className="text-purple-700">4</span>
                      </div>
                      <span>Straps should provide support without digging into shoulders</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
{/* How Shape Affects Fit Section */}
<div className="bg-white rounded-2xl shadow-lg p-8 transform hover:shadow-xl transition-all duration-300">
              <h2 className="text-2xl font-semibold text-purple-900 mb-8 relative pb-3 after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-24 after:h-1 after:bg-purple-500 after:rounded-full">How Shape Affects Fit</h2>
              <div className="grid md:grid-cols-2 gap-8">
                <div className="bg-purple-50 rounded-xl p-6 hover:bg-purple-100 transition-colors duration-300">
                  <h3 className="text-xl font-semibold text-purple-800 mb-4">Cup Volume Distribution</h3>
                  <p className="text-gray-700">Different breast shapes distribute volume differently within the cup. Understanding your shape helps select styles that accommodate your unique distribution pattern.</p>
                </div>
                <div className="bg-purple-50 rounded-xl p-6 hover:bg-purple-100 transition-colors duration-300">
                  <h3 className="text-xl font-semibold text-purple-800 mb-4">Support Requirements</h3>
                  <p className="text-gray-700">Your breast shape influences the type and level of support needed. Some shapes benefit from additional side support, while others need more lift or separation.</p>
                </div>
                <div className="bg-purple-50 rounded-xl p-6 hover:bg-purple-100 transition-colors duration-300">
                  <h3 className="text-xl font-semibold text-purple-800 mb-4">Style Compatibility</h3>
                  <p className="text-gray-700">Certain bra styles work better with specific breast shapes. Understanding this relationship helps in selecting the most flattering and comfortable options.</p>
                </div>
                <div className="bg-purple-50 rounded-xl p-6 hover:bg-purple-100 transition-colors duration-300">
                  <h3 className="text-xl font-semibold text-purple-800 mb-4">Size Adjustments</h3>
                  <p className="text-gray-700">Your shape may require size adjustments from standard measurements. Some shapes need to size up or down in the cup, while maintaining the same band size.</p>
                </div>
              </div>
            </div>
            
            {/* Shape-Specific Considerations */}
            <div className="bg-white rounded-2xl shadow-lg p-8 transform hover:shadow-xl transition-all duration-300">
              <h2 className="text-2xl font-semibold text-purple-900 mb-8 relative pb-3 after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-24 after:h-1 after:bg-purple-500 after:rounded-full">Shape-Specific Considerations</h2>
              <div className="bg-purple-50 rounded-xl p-8">
                <div className="flex items-center mb-6">
                  <div className="w-12 h-12 bg-purple-200 rounded-lg flex items-center justify-center mr-4">
                    <span className="text-purple-700 text-2xl">💡</span>
                  </div>
                  <h3 className="text-xl font-medium text-purple-900">Fitting Advice by Shape</h3>
                </div>
                <p className="text-gray-600 leading-relaxed mb-6 text-lg">
                  Start with your measured size but be prepared to make shape-specific adjustments. The goal is to find bras that work with your natural shape rather than fighting against it.
                </p>
                <ul className="space-y-4">
                  <li className="flex items-start text-gray-600 leading-relaxed bg-white p-4 rounded-lg shadow-sm">
                    <span className="text-purple-500 mr-3 text-lg">•</span>
                    <span>Asymmetrical shapes may benefit from removable padding for customization</span>
                  </li>
                  <li className="flex items-start text-gray-600 leading-relaxed bg-white p-4 rounded-lg shadow-sm">
                    <span className="text-purple-500 mr-3 text-lg">•</span>
                    <span>Full-on-top shapes often need more upper cup coverage</span>
                  </li>
                  <li className="flex items-start text-gray-600 leading-relaxed bg-white p-4 rounded-lg shadow-sm">
                    <span className="text-purple-500 mr-3 text-lg">•</span>
                    <span>Wide-set shapes may prefer plunge styles for better centering</span>
                  </li>
                </ul>
              </div>

              {/* Common Issues by Shape */}
              <div className="grid md:grid-cols-3 gap-8 mt-8">
                <div className="bg-purple-50 rounded-xl p-6 hover:bg-purple-100 transition-colors duration-300">
                  <div className="flex items-center mb-4">
                    <div className="w-10 h-10 bg-purple-200 rounded-lg flex items-center justify-center mr-3">
                      <span className="text-purple-700 text-lg">🔍</span>
                    </div>
                    <h3 className="font-semibold text-purple-900">Gaping Solutions</h3>
                  </div>
                  <ul className="space-y-3 text-gray-600">
                    <li className="bg-white p-3 rounded-lg shadow-sm">
                      <span className="font-medium text-purple-700 block mb-1">Round/Teardrop</span>
                      <span>True sizing issue</span>
                    </li>
                    <li className="bg-white p-3 rounded-lg shadow-sm">
                      <span className="font-medium text-purple-700 block mb-1">Bell Shape</span>
                      <span>Style mismatch rather than size issue</span>
                    </li>
                    <li className="bg-white p-3 rounded-lg shadow-sm">
                      <span className="font-medium text-purple-700 block mb-1">Slender</span>
                      <span>Might need smaller cup size</span>
                    </li>
                  </ul>
                </div>
                <div className="bg-purple-50 rounded-xl p-6 hover:bg-purple-100 transition-colors duration-300">
                  <div className="flex items-center mb-4">
                    <div className="w-10 h-10 bg-purple-200 rounded-lg flex items-center justify-center mr-3">
                      <span className="text-purple-700 text-lg">⚖️</span>
                    </div>
                    <h3 className="font-semibold text-purple-900">Spillage Solutions</h3>
                  </div>
                  <ul className="space-y-3 text-gray-600">
                    <li className="bg-white p-3 rounded-lg shadow-sm">
                      <span className="font-medium text-purple-700 block mb-1">Round</span>
                      <span>Size up in cup</span>
                    </li>
                    <li className="bg-white p-3 rounded-lg shadow-sm">
                      <span className="font-medium text-purple-700 block mb-1">East-West</span>
                      <span>Try same size in different style first</span>
                    </li>
                    <li className="bg-white p-3 rounded-lg shadow-sm">
                      <span className="font-medium text-purple-700 block mb-1">Asymmetrical</span>
                      <span>Size to larger breast</span>
                    </li>
                  </ul>
                </div>
                <div className="bg-purple-50 rounded-xl p-6 hover:bg-purple-100 transition-colors duration-300">
                  <div className="flex items-center mb-4">
                    <div className="w-10 h-10 bg-purple-200 rounded-lg flex items-center justify-center mr-3">
                      <span className="text-purple-700 text-lg">🎯</span>
                    </div>
                    <h3 className="font-semibold text-purple-900">Center Gore Issues</h3>
                  </div>
                  <ul className="space-y-3 text-gray-600">
                    <li className="bg-white p-3 rounded-lg shadow-sm">
                      <span className="font-medium text-purple-700 block mb-1">Side-Set</span>
                      <span>Focus on center-pull styles in regular size</span>
                    </li>
                    <li className="bg-white p-3 rounded-lg shadow-sm">
                      <span className="font-medium text-purple-700 block mb-1">Close-Set</span>
                      <span>Consider plunge styles to avoid gore pressure</span>
                    </li>
                    <li className="bg-white p-3 rounded-lg shadow-sm">
                      <span className="font-medium text-purple-700 block mb-1">East-West</span>
                      <span>Look for stronger center gore support</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        )}
      </div>
    </div>
    
  );
}
