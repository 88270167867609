import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import QuickConverter from '../components/QuickConverter';
import NewBraSizeCalculator from '../components/NewBraSizeCalculator';

const WrongSizeImpact: React.FC = () => {
  const statistics = [
    {
      percentage: 80,
      label: "Women wear the wrong bra size",
      detail: "Research shows that 4 out of 5 women are wearing incorrectly sized bras"
    },
    {
      percentage: 70,
      label: "Wear bras that are too small",
      detail: "Most women are wearing bras that provide insufficient support"
    },
    {
      percentage: 10,
      label: "Wear bras that are too large",
      detail: "Leading to inadequate support and comfort issues"
    }
  ];

  const consequences = [
    {
      icon: "🤕",
      title: "Pain & Discomfort",
      description: "Back, neck, and shoulder pain from improper support"
    },
    {
      icon: "👔",
      title: "Poor Posture",
      description: "Incorrect support can lead to posture issues"
    },
    {
      icon: "🏃‍♀️",
      title: "Limited Activity",
      description: "Discomfort can restrict physical activities"
    },
    {
      icon: "👕",
      title: "Clothing Fit",
      description: "Affects how clothes fit and look"
    }
  ];

  return (
    <div className="py-16 bg-purple-50 rounded-xl max-w-5xl mx-auto px-6">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-3xl font-bold text-center text-purple-800 mb-6">
          The Impact of Wearing the Wrong Size
        </h2>
        
        <p className="text-gray-600 text-center mb-12">
          Research published in Chiropractic & Osteopathy reveals a significant issue 
          in bra fitting, with serious implications for women's health and comfort.
        </p>

        {/* Statistics Cards */}
        <div className="grid md:grid-cols-3 gap-6 mb-16">
          {statistics.map((stat, index) => (
            <div key={index} className="bg-white rounded-xl shadow-sm p-6 text-center">
              <div className="text-4xl font-bold text-purple-600 mb-2">
                {stat.percentage}%
              </div>
              <div className="font-semibold mb-2">{stat.label}</div>
              <div className="text-sm text-gray-600">{stat.detail}</div>
            </div>
          ))}
        </div>

        {/* Consequences Grid */}
        <h3 className="text-2xl font-bold text-center text-purple-800 mb-8">
          Common Issues from Poor Fit
        </h3>
        
        <div className="grid md:grid-cols-2 gap-6">
          {consequences.map((item, index) => (
            <div key={index} className="bg-white rounded-lg p-6 flex items-start">
              <div className="text-3xl mr-4">{item.icon}</div>
              <div>
                <h4 className="font-semibold mb-1">{item.title}</h4>
                <p className="text-gray-600">{item.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* CTA Section */}
        <div className="text-center mt-12">
          <button 
            onClick={() => window.location.href = '/#calculator'}
            className="inline-flex items-center bg-purple-600 text-white px-8 py-3 rounded-lg hover:bg-purple-700 transition-colors group"
          >
            <span>Calculate Your Bra Size Now</span>
            <svg 
              className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
          <p className="text-sm italic text-gray-400 mt-6">
            Source: Wood K, Cameron M, Fitzgerald K. "Breast size, bra fit and thoracic pain in young women: a correlational study". Chiropractic & Osteopathy 2008
          </p>
        </div>
      </div>
    </div>
  );
};

const Home: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>BraSizeCalculatorPro - Free Online Bra Size Calculator</title>
        <meta name="description" content="Get your perfect bra size with our free online calculator. Accurate measurements, expert guidance, and instant results for US, UK, EU, and other international sizes." />
        <meta name="keywords" content="bra size calculator, bra measurement, bra fitting, cup size calculator, band size calculator, online bra size" />
        <link rel="canonical" href="https://brasizecalculatorpro.com" />
      </Helmet>
      <div>
        {/* Hero Section with gradient background */}
        <div className="bg-gradient-to-b from-purple-100 via-purple-50 to-white w-full">
          <div className="max-w-5xl mx-auto px-6">
            <div className="text-center py-16">
              <h1 className="text-5xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
                Find Your Perfect Bra Size
              </h1>
              <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
                Get accurate measurements and personalized recommendations in just a few simple steps. Experience comfort and confidence with a perfectly fitted bra.
              </p>
              <div className="flex justify-center mt-12 gap-4">
                <div className="text-center px-6">
                  <div className="text-3xl font-bold text-purple-500">50k+</div>
                  <div className="text-gray-600">Women Helped</div>
                </div>
                <div className="text-center px-6 border-l border-gray-200">
                  <div className="text-3xl font-bold text-purple-500">98%</div>
                  <div className="text-gray-600">Accuracy Rate</div>
                </div>
                <div className="text-center px-6 border-l border-gray-200">
                  <div className="text-3xl font-bold text-purple-500">200+</div>
                  <div className="text-gray-600">Size Options</div>
                </div>
              </div>
            </div>
          </div>
        </div>

          {/* New Enhanced Calculator (Testing) */}
          <div id="calculator" className="mt-4 pt-10 border-purple-100">
            <div className="max-w-5xl mx-auto px-6">
              <div className="text-center mb-8">
                <h2 className="text-3xl font-bold mb-4 text-purple-800">Enhanced Bra Size Calculator</h2>
                <p className="text-lg text-gray-600 max-w-2xl mx-auto">
                  Try our new enhanced calculator with fit issues analysis and personalized recommendations.
                  Our calculator uses precise measurements and expert algorithms to determine your ideal bra size. 
                </p>
              </div>
              <div className="max-w-4xl mx-auto">
                <NewBraSizeCalculator />
              </div>
            </div>
          </div>

          <div className="mt-24 mb-16 rounded-xl border border-gray-100 p-8 max-w-5xl mx-auto">
            <h2 className="text-2xl font-bold text-center mb-12 text-purple-800">How to Measure Your Bra Size Accurately</h2>
            <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto px-6">
              <div className="text-center">
                <div className="relative w-48 h-48 mx-auto mb-4">
                  <img 
                    src="/images/underbust-measurement.png" 
                    alt="How to measure underbust" 
                    className="w-full h-full object-contain"
                  />
                  <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center absolute top-0 right-0">
                    <span className="text-xl font-bold text-purple-500">1</span>
                  </div>
                </div>
                <h3 className="font-medium mb-2">Measure Underbust</h3>
                <p className="text-sm text-gray-600">
                  Measure directly under your bust, around your ribcage. Keep the measuring tape parallel to the ground.
                </p>
              </div>
              <div className="text-center">
                <div className="relative w-48 h-48 mx-auto mb-4">
                  <img 
                    src="/images/bust-measurement.png" 
                    alt="How to measure bust" 
                    className="w-full h-full object-contain"
                  />
                  <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center absolute top-0 right-0">
                    <span className="text-xl font-bold text-purple-500">2</span>
                  </div>
                </div>
                <h3 className="font-medium mb-2">Measure Bust</h3>
                <p className="text-sm text-gray-600">
                  Measure around the fullest part of your bust while wearing an unpadded bra. Keep the tape level and snug but not tight.
                </p>
              </div>
              <div className="text-center">
                <div className="relative w-48 h-48 mx-auto mb-4">
                  <img 
                    src="/images/calculator.png" 
                    alt="Calculate your size" 
                    className="w-full h-full object-contain"
                  />
                  <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center absolute top-0 right-0">
                    <span className="text-xl font-bold text-purple-500">3</span>
                  </div>
                </div>
                <h3 className="font-medium mb-2">Get Your Size</h3>
                <p className="text-sm text-gray-600">
                  Enter your measurements into our calculator for your perfect bra size and sister sizes.
                </p>
              </div>
            </div>
            <div className="text-center mt-8 max-w-5xl mx-auto px-6">
              <Link
                to="/bra-size-measuring-guide"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 border-2 border-purple-600 rounded-lg hover:bg-purple-50 transition-colors group"
              >
                <span>View Detailed Measuring Guide</span>
                <svg 
                  className="w-4 h-4 ml-2 group-hover:translate-x-1 transition-transform" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </Link>
            </div>
          </div>


          <div className="container mx-auto">


          <div className="bg-purple-50 rounded-xl p-6 mt-14 mb-14 max-w-5xl mx-auto">
            <div className="py-8">
              <h2 className="text-3xl font-bold text-center mb-10 text-purple-800">Why Bra Size Tags Can't Always Be Trusted</h2>
              <div className="grid md:grid-cols-2 gap-6">
                <div className="bg-white rounded-xl shadow-sm p-6">
                  <h3 className="text-lg font-semibold mb-3 text-purple-600">Common Issues with Size Tags</h3>
                  <ul className="space-y-4">
                    <li className="flex items-center">
                      <div className="flex-shrink-0 w-6 h-6 mr-3">
                        <svg className="w-6 h-6 text-purple-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                      </div>
                      <p className="text-gray-700">Size variations between different brands and manufacturers</p>
                    </li>
                    <li className="flex items-center">
                      <div className="flex-shrink-0 w-6 h-6 mr-3">
                        <svg className="w-6 h-6 text-purple-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                      </div>
                      <p className="text-gray-700">Different sizing systems across countries and regions</p>
                    </li>
                    <li className="flex items-center">
                      <div className="flex-shrink-0 w-6 h-6 mr-3">
                        <svg className="w-6 h-6 text-purple-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                      </div>
                      <p className="text-gray-700">Changes in manufacturing processes over time</p>
                    </li>
                  </ul>
                </div>
                <div className="bg-white rounded-xl shadow-sm p-6">
                  <h3 className="text-lg font-semibold mb-3 text-purple-600">What to Do Instead</h3>
                  <ul className="space-y-4">
                    <li className="flex items-center">
                      <div className="flex-shrink-0 w-6 h-6 mr-3">
                        <svg className="w-6 h-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p className="text-gray-700">Use our calculator for accurate measurements</p>
                    </li>
                    <li className="flex items-center">
                      <div className="flex-shrink-0 w-6 h-6 mr-3">
                        <svg className="w-6 h-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p className="text-gray-700">Try sister sizes for better fit options</p>
                    </li>
                    <li className="flex items-center">
                      <div className="flex-shrink-0 w-6 h-6 mr-3">
                        <svg className="w-6 h-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p className="text-gray-700">Focus on comfort and fit rather than the size tag</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-16 mb-16">
            <QuickConverter />
          </div>

          <WrongSizeImpact />

          <div className="mt-16 mb-16">
            <h2 className="text-3xl font-bold text-center mb-8 text-purple-800">How To Use Our Bra & Cup Size Calculator</h2>
            <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto px-6">
              <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
                <h3 className="text-xl font-semibold mb-4 text-purple-600">Simple Steps to Follow</h3>
                <ol className="space-y-4">
                  <li className="flex items-center">
                    <div className="flex-shrink-0 w-8 h-8 bg-purple-100 rounded-full flex items-center justify-center text-purple-600 font-semibold mr-3">1</div>
                    <p className="text-gray-700">Select your preferred unit of measurement (inches or centimeters)</p>
                  </li>
                  <li className="flex items-center">
                    <div className="flex-shrink-0 w-8 h-8 bg-purple-100 rounded-full flex items-center justify-center text-purple-600 font-semibold mr-3">2</div>
                    <p className="text-gray-700">Enter your underbust measurement (band size)</p>
                  </li>
                  <li className="flex items-center">
                    <div className="flex-shrink-0 w-8 h-8 bg-purple-100 rounded-full flex items-center justify-center text-purple-600 font-semibold mr-3">3</div>
                    <p className="text-gray-700">Enter your bust measurement at the fullest point</p>
                  </li>
                  <li className="flex items-center">
                    <div className="flex-shrink-0 w-8 h-8 bg-purple-100 rounded-full flex items-center justify-center text-purple-600 font-semibold mr-3">4</div>
                    <p className="text-gray-700">Get your recommended bra size and sister sizes instantly</p>
                  </li>
                </ol>
              </div>
              <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
                <h3 className="text-xl font-semibold mb-4 text-purple-600">Pro Tips</h3>
                <ul className="space-y-4">
                  <li className="flex items-center">
                    <div className="flex-shrink-0 w-6 h-6 mr-3">
                      <svg className="w-6 h-6 text-purple-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <p className="text-gray-700">Measure yourself while wearing an unpadded bra or no bra</p>
                  </li>
                  <li className="flex items-center">
                    <div className="flex-shrink-0 w-6 h-6 mr-3">
                      <svg className="w-6 h-6 text-purple-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <p className="text-gray-700">Keep the measuring tape parallel to the ground</p>
                  </li>
                  <li className="flex items-center">
                    <div className="flex-shrink-0 w-6 h-6 mr-3">
                      <svg className="w-6 h-6 text-purple-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <p className="text-gray-700">Don't pull the tape too tight - it should be snug but comfortable</p>
                  </li>
                  <li className="flex items-center">
                    <div className="flex-shrink-0 w-6 h-6 mr-3">
                      <svg className="w-6 h-6 text-purple-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <p className="text-gray-700">Take multiple measurements to ensure accuracy</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="bg-purple-50 rounded-xl p-8 mb-16 max-w-5xl mx-auto">
            <div className="max-w-4xl mx-auto px-6">
              <h2 className="text-3xl font-bold text-center text-purple-800 mb-10">About Bra Size Calculator Pro</h2>
              <div className="grid md:grid-cols-3 gap-8">
                <div className="text-center">
                  <div className="w-16 h-16 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-4">
                    <svg className="w-8 h-8 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                    </svg>
                  </div>
                  <h3 className="text-xl font-semibold mb-2">Save Your Measurements</h3>
                  <p className="text-gray-600">Keep track of your measurements over time and see how your size changes</p>
                </div>
                <div className="text-center">
                  <div className="w-16 h-16 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-4">
                    <svg className="w-8 h-8 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                    </svg>
                  </div>
                  <h3 className="text-xl font-semibold mb-2">Brand Size Comparisons</h3>
                  <p className="text-gray-600">Compare your size across different brands and find your perfect fit</p>
                </div>
                <div className="text-center">
                  <div className="w-16 h-16 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-4">
                    <svg className="w-8 h-8 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </div>
                  <h3 className="text-xl font-semibold mb-2">Size History Tracking</h3>
                  <p className="text-gray-600">Monitor size changes and get personalized recommendations</p>
                </div>
              </div>
              <div className="text-center mt-8">
                <button className="inline-flex items-center bg-purple-600 text-white px-8 py-3 rounded-lg hover:bg-purple-700 transition-colors group">
                  <span>Upgrade to Pro (Soon!)</span>
                  <svg className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
