import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { sizeConversions, cupSizes } from '../data/sizingConverterData';

interface TabProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
}

type TabId = 'international' | 'sister' | 'tips';

interface Tab {
  id: TabId;
  label: string;
}

const Tab: React.FC<TabProps> = React.memo(({ label, isActive, onClick }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200 ${
      isActive
        ? 'bg-purple-600 text-white shadow'
        : 'text-gray-600 hover:bg-purple-50'
    }`}
  >
    {label}
  </button>
));

// Helper function to convert inches to centimeters
const inchesToCm = (inches: number): number => Math.round(inches * 2.54);

// Helper function to get measurement range
const getMeasurementRange = (size: number): { inches: string; cm: string } => {
  return {
    inches: `${size}-${size + 1}"`,
    cm: `${inchesToCm(size)}-${inchesToCm(size + 1)} cm`
  };
};

const tabs: Tab[] = [
  { id: 'international', label: 'International Size Charts' },
  { id: 'sister', label: 'Sister Size Calculator' },
  { id: 'tips', label: 'Sizing Tips' }
];

const SizeCharts: React.FC = () => {
  const [activeTab, setActiveTab] = useState<TabId>('international');
  const [showInches, setShowInches] = useState(true);
  const [selectedSize, setSelectedSize] = useState<string | null>(null);
  const [selectedSystem, setSelectedSystem] = useState<'US/UK' | 'EU' | 'FR'>('US/UK');

  // Size conversion functions
  const convertSize = (size: string, toSystem: 'US/UK' | 'EU' | 'FR'): string => {
    const [band, cup] = [parseInt(size.slice(0, 2)), size.slice(2)];
    
    // Using the exact mappings from sizingSisterData.ts
    const bandMappings: Record<string, { EU: number; FR: number }> = {
      '30': { EU: 65, FR: 80 },
      '32': { EU: 70, FR: 85 },
      '34': { EU: 75, FR: 90 },
      '36': { EU: 80, FR: 95 }
    };

    const cupMappings: Record<string, { EU: string; FR: string }> = {
      'A': { EU: 'A', FR: 'A' },
      'B': { EU: 'B', FR: 'B' },
      'C': { EU: 'C', FR: 'C' },
      'D': { EU: 'D', FR: 'D' },
      'DD': { EU: 'E', FR: 'E' }
    };
    
    switch (toSystem) {
      case 'US/UK':
        return size;
      case 'EU':
        return `${bandMappings[band.toString()]?.EU || band}${cupMappings[cup]?.EU || cup}`;
      case 'FR':
        return `${bandMappings[band.toString()]?.FR || band}${cupMappings[cup]?.FR || cup}`;
      default:
        return size;
    }
  };

  const convertToUS = (size: string, fromSystem: 'US/UK' | 'EU' | 'FR'): string | null => {
    let band = '';
    let cup = '';
    
    // Reverse mappings based on sizingSisterData.ts
    const euToUS: Record<string, string> = {
      '65': '30', '70': '32', '75': '34', '80': '36'
    };
    
    const frToUS: Record<string, string> = {
      '80': '30', '85': '32', '90': '34', '95': '36'
    };
    
    switch (fromSystem) {
      case 'US/UK':
        return size;
      case 'EU':
        const matchEU = size.match(/(\d+)([A-E])/);
        if (!matchEU) return null;
        band = euToUS[matchEU[1]] || String(parseInt(matchEU[1]));
        cup = matchEU[2];
        break;
      case 'FR':
        const matchFR = size.match(/(\d+)([A-E])/);
        if (!matchFR) return null;
        band = frToUS[matchFR[1]] || String(parseInt(matchFR[1]));
        cup = matchFR[2];
        break;
    }
    
    const result = `${band}${cup}`;
    return result;
  };

  const convertCupEU = (cup: string): string => {
    if (cup === 'DD') return 'E';
    return cup;
  };

  const convertCupFR = (cup: string): string => {
    if (cup === 'DD') return 'E';
    return cup;
  };

  // Create band size conversion table from sizeConversions data
  const bandSizeTable = [
    {
      us: 26,
      uk: 26,
      eu: 55,
      fr_es: 70,
      it: -1,
      jp: 55,
      au_nz: 4,
      measurement: { inches: '26-27"', cm: '66-69 cm' }
    },
    {
      us: 28,
      uk: 28,
      eu: 60,
      fr_es: 75,
      it: 0,
      jp: 60,
      au_nz: 6,
      measurement: { inches: '28-29"', cm: '71-74 cm' }
    },
    {
      us: 30,
      uk: 30,
      eu: 65,
      fr_es: 80,
      it: 1,
      jp: 65,
      au_nz: 8,
      measurement: { inches: '30-31"', cm: '76-79 cm' }
    },
    {
      us: 32,
      uk: 32,
      eu: 70,
      fr_es: 85,
      it: 2,
      jp: 70,
      au_nz: 10,
      measurement: { inches: '32-33"', cm: '81-84 cm' }
    },
    {
      us: 34,
      uk: 34,
      eu: 75,
      fr_es: 90,
      it: 3,
      jp: 75,
      au_nz: 12,
      measurement: { inches: '34-35"', cm: '86-89 cm' }
    },
    {
      us: 36,
      uk: 36,
      eu: 80,
      fr_es: 95,
      it: 4,
      jp: 80,
      au_nz: 14,
      measurement: { inches: '36-37"', cm: '91-94 cm' }
    },
    {
      us: 38,
      uk: 38,
      eu: 85,
      fr_es: 100,
      it: 5,
      jp: 85,
      au_nz: 16,
      measurement: { inches: '38-39"', cm: '96-99 cm' }
    },
    {
      us: 40,
      uk: 40,
      eu: 90,
      fr_es: 105,
      it: 6,
      jp: 90,
      au_nz: 18,
      measurement: { inches: '40-41"', cm: '102-104 cm' }
    },
    {
      us: 42,
      uk: 42,
      eu: 95,
      fr_es: 110,
      it: 7,
      jp: 95,
      au_nz: 20,
      measurement: { inches: '42-43"', cm: '107-109 cm' }
    },
    {
      us: 44,
      uk: 44,
      eu: 100,
      fr_es: 115,
      it: 8,
      jp: 100,
      au_nz: 22,
      measurement: { inches: '44-45"', cm: '112-114 cm' }
    },
    {
      us: 46,
      uk: 46,
      eu: 105,
      fr_es: 120,
      it: 9,
      jp: 105,
      au_nz: 24,
      measurement: { inches: '46-47"', cm: '117-119 cm' }
    },
    {
      us: 48,
      uk: 48,
      eu: 110,
      fr_es: 125,
      it: 10,
      jp: 110,
      au_nz: 26,
      measurement: { inches: '48-49"', cm: '122-124 cm' }
    },
    {
      us: 50,
      uk: 50,
      eu: 115,
      fr_es: 130,
      it: 11,
      jp: 115,
      au_nz: 28,
      measurement: { inches: '50-51"', cm: '127-129 cm' }
    },
    {
      us: 52,
      uk: 52,
      eu: 120,
      fr_es: 135,
      it: 12,
      jp: 120,
      au_nz: 30,
      measurement: { inches: '52-53"', cm: '132-134 cm' }
    },
    {
      us: 54,
      uk: 54,
      eu: 125,
      fr_es: 140,
      it: 13,
      jp: 125,
      au_nz: 32,
      measurement: { inches: '54-55"', cm: '137-139 cm' }
    },
    {
      us: 56,
      uk: 56,
      eu: 130,
      fr_es: 145,
      it: 14,
      jp: 130,
      au_nz: 34,
      measurement: { inches: '56-57"', cm: '142-144 cm' }
    },
    {
      us: 58,
      uk: 58,
      eu: 135,
      fr_es: 150,
      it: 15,
      jp: 135,
      au_nz: 36,
      measurement: { inches: '58-59"', cm: '147-149 cm' }
    },
    {
      us: 60,
      uk: 60,
      eu: 140,
      fr_es: 155,
      it: 16,
      jp: 140,
      au_nz: 38,
      measurement: { inches: '60-61"', cm: '152-154 cm' }
    }
  ];

  // Create cup size conversion table
  const cupSizeTable = cupSizes.US.map((usSize, index) => ({
    us: usSize,
    uk: cupSizes.UK[index] || '-',
    eu: convertCupEU(cupSizes.EU[index] || '-'),
    fr_es: convertCupFR(cupSizes.FR_ES[index] || '-'),
    it: cupSizes.IT[index] || '-',
    jp: cupSizes.JP[index] || '-',
    au_nz: cupSizes.AU_NZ[index] || '-',
    difference: getMeasurementRange(index + 0.5)
  }));

  // Sister sizes mapping
  const sisterSizes: Record<string, [string, string]> = {
    "30A": ["28B", "32AA"],
    "30B": ["28C", "32A"],
    "30C": ["28D", "32B"],
    "30D": ["28DD", "32C"],
    "30DD": ["28E", "32D"],
    "32A": ["30B", "34AA"],
    "32B": ["30C", "34A"],
    "32C": ["30D", "34B"],
    "32D": ["30DD", "34C"],
    "32DD": ["30E", "34D"],
    "34A": ["32B", "36AA"],
    "34B": ["32C", "36A"],
    "34C": ["32D", "36B"],
    "34D": ["32DD", "36C"],
    "34DD": ["32E", "36D"],
    "36A": ["34B", "38AA"],
    "36B": ["34C", "38A"],
    "36C": ["34D", "38B"],
    "36D": ["34DD", "38C"],
    "36DD": ["34E", "38D"]
  };

  const handleTabChange = useCallback((tab: TabId) => {
    setActiveTab(tab);
  }, []);

  const handleSizeSelect = useCallback((size: string) => {
    setSelectedSize(size);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      <Helmet>
        <title>International Bra Size Charts & Conversions | BraSizeCalculatorPro</title>
        <meta name="description" content="Complete bra size charts and conversion tables for US, UK, EU, FR, and IT sizing. Find your size across different international standards." />
        <meta name="keywords" content="bra size chart, international bra sizes, bra size conversion table, US UK EU bra sizes" />
        <link rel="canonical" href="https://brasizecalculatorpro.com/size-charts" />
      </Helmet>

      <div className="container mx-auto px-4 py-12">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
            International Bra Size Charts
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Find your perfect fit across different international sizing systems
          </p>
        </div>

        {/* Tab Navigation */}
        <div className="mb-8">
          <div className="border-b border-gray-200">
            <nav className="flex justify-center" aria-label="Tabs">
              <div className="flex space-x-8">
                {tabs.map((tab) => (
                  <button
                    key={tab.id}
                    onClick={() => handleTabChange(tab.id)}
                    className={`
                      py-4 px-6 border-b-2 font-medium text-lg transition-colors duration-200
                      ${activeTab === tab.id
                        ? 'border-purple-600 text-purple-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                      }
                    `}
                  >
                    {tab.label}
                  </button>
                ))}
              </div>
            </nav>
          </div>
        </div>

        <div className="mt-8">
          {activeTab === 'international' && (
            <div className="space-y-12">
              <div className="bg-white rounded-xl shadow-lg overflow-hidden">
                <div className="p-8">
                  <div className="flex justify-between items-center mb-8">
                    <h2 className="text-2xl font-bold text-purple-800">Band Size Conversion Chart</h2>
                    <button
                      onClick={() => setShowInches(!showInches)}
                      className="px-6 py-3 text-sm font-medium rounded-xl bg-purple-100 text-purple-600 hover:bg-purple-200 transition-colors flex items-center gap-2"
                    >
                      <span>{showInches ? 'Show Centimeters' : 'Show Inches'}</span>
                    </button>
                  </div>
                  <div className="overflow-x-auto">
                    <table className="min-w-full">
                      <thead>
                        <tr>
                          <th className="py-4 px-6 text-center text-purple-600 text-sm font-semibold border-b-2 border-purple-100">US</th>
                          <th className="py-4 px-6 text-center text-purple-600 text-sm font-semibold border-b-2 border-purple-100">UK</th>
                          <th className="py-4 px-6 text-center text-purple-600 text-sm font-semibold border-b-2 border-purple-100">EU</th>
                          <th className="py-4 px-6 text-center text-purple-600 text-sm font-semibold border-b-2 border-purple-100">FR/BE/ES</th>
                          <th className="py-4 px-6 text-center text-purple-600 text-sm font-semibold border-b-2 border-purple-100">IT</th>
                          <th className="py-4 px-6 text-center text-purple-600 text-sm font-semibold border-b-2 border-purple-100">AU/NZ</th>
                          <th className="py-4 px-6 text-center text-purple-600 text-sm font-semibold border-b-2 border-purple-100">Measurement</th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-100">
                        {bandSizeTable.map((row, i) => (
                          <tr key={i} className={`hover:bg-purple-50 transition-colors ${i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
                            <td className="py-4 px-6 text-center text-sm text-gray-900 font-medium">{row.us}</td>
                            <td className="py-4 px-6 text-center text-sm text-gray-900">{row.uk}</td>
                            <td className="py-4 px-6 text-center text-sm text-gray-900">{row.eu}</td>
                            <td className="py-4 px-6 text-center text-sm text-gray-900">{row.fr_es}</td>
                            <td className="py-4 px-6 text-center text-sm text-gray-900">{row.it}</td>
                            <td className="py-4 px-6 text-center text-sm text-gray-900">{row.au_nz}</td>
                            <td className="py-4 px-6 text-center text-sm text-gray-900 font-medium">
                              {showInches ? row.measurement.inches : row.measurement.cm}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-xl shadow overflow-hidden">
                <div className="p-8">
                  <div className="flex justify-between items-center mb-8">
                    <h2 className="text-2xl font-bold text-purple-800">Cup Size Conversion Chart</h2>
                    <button
                      onClick={() => setShowInches(!showInches)}
                      className="px-6 py-3 text-sm font-medium rounded-xl bg-purple-100 text-purple-600 hover:bg-purple-200 transition-colors flex items-center gap-2"
                    >
                      <span>{showInches ? 'Show Centimeters' : 'Show Inches'}</span>
                    </button>
                  </div>
                  <div className="overflow-x-auto">
                    <table className="min-w-full">
                      <thead>
                        <tr>
                          <th className="py-4 px-6 text-center text-purple-600 text-sm font-semibold border-b-2 border-purple-100">US/UK</th>
                          <th className="py-4 px-6 text-center text-purple-600 text-sm font-semibold border-b-2 border-purple-100">EU</th>
                          <th className="py-4 px-6 text-center text-purple-600 text-sm font-semibold border-b-2 border-purple-100">FR/ES</th>
                          <th className="py-4 px-6 text-center text-purple-600 text-sm font-semibold border-b-2 border-purple-100">IT</th>
                          <th className="py-4 px-6 text-center text-purple-600 text-sm font-semibold border-b-2 border-purple-100">JP</th>
                          <th className="py-4 px-6 text-center text-purple-600 text-sm font-semibold border-b-2 border-purple-100">AU/NZ</th>
                          <th className="py-4 px-6 text-center text-purple-600 text-sm font-semibold border-b-2 border-purple-100">Difference</th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-100">
                        {cupSizeTable.map((row, i) => (
                          <tr key={i} className={`hover:bg-purple-50 transition-colors ${i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
                            <td className="py-4 px-6 text-center text-sm text-gray-900 font-medium">{row.us}</td>
                            <td className="py-4 px-6 text-center text-sm text-gray-900">{row.eu}</td>
                            <td className="py-4 px-6 text-center text-sm text-gray-900">{row.fr_es}</td>
                            <td className="py-4 px-6 text-center text-sm text-gray-900">{row.it}</td>
                            <td className="py-4 px-6 text-center text-sm text-gray-900">{row.jp}</td>
                            <td className="py-4 px-6 text-center text-sm text-gray-900">{row.au_nz}</td>
                            <td className="py-4 px-6 text-center text-sm text-gray-900 font-medium">
                              {showInches ? row.difference.inches : row.difference.cm}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeTab === 'sister' && (
            <div className="space-y-12">
              <div className="bg-white rounded-xl shadow-lg overflow-hidden">
                <div className="p-8">
                  <h2 className="text-2xl font-bold text-purple-800 mb-4">Sister Size Quick Converter</h2>
                  <p className="text-gray-600 mb-8">
                    Sister sizes are bra sizes with the same cup volume but different band sizes.
                    When you go up in the band size, go down in the cup size (and vice versa) to maintain the same volume.
                  </p>

                  <div className="grid md:grid-cols-2 gap-8">
                    <div className="bg-purple-50 rounded-xl p-6">
                      <div className="flex justify-between items-center mb-6">
                        <h3 className="text-lg font-semibold text-purple-900">Select Your Current Size</h3>
                        <div className="flex gap-2">
                          {['US/UK', 'EU', 'FR'].map((system) => (
                            <button
                              key={system}
                              onClick={() => {
                                setSelectedSystem(system as 'US/UK' | 'EU' | 'FR');
                                setSelectedSize(null);
                              }}
                              className={`px-3 py-1.5 text-sm font-medium rounded-lg transition-all duration-200 ${
                                selectedSystem === system
                                  ? 'bg-purple-600 text-white shadow-sm'
                                  : 'bg-white hover:bg-purple-100 text-gray-900'
                              }`}
                            >
                              {system}
                            </button>
                          ))}
                        </div>
                      </div>
                      <div className="grid grid-cols-5 gap-2">
                        {[
                          '30A', '30B', '30C', '30D', '30DD',
                          '32A', '32B', '32C', '32D', '32DD',
                          '34A', '34B', '34C', '34D', '34DD',
                          '36A', '36B', '36C', '36D', '36DD'
                        ].map((size) => (
                          <button
                            key={size}
                            onClick={() => handleSizeSelect(size)}
                            className={`p-2 mb-1 text-sm font-medium rounded-xl transition-all duration-200 text-center ${
                              selectedSize === size
                                ? 'bg-purple-600 text-white shadow-md'
                                : 'bg-white hover:bg-purple-100 text-gray-900'
                            }`}
                          >
                            {selectedSystem === 'US/UK' 
                              ? size 
                              : convertSize(size, selectedSystem)}
                          </button>
                        ))}
                      </div>
                    </div>

                    <div className="bg-gradient-to-br from-purple-50 to-pink-50 p-6 rounded-xl shadow-sm">
                      <h3 className="text-lg font-semibold text-purple-900 mb-4">Sister Sizes</h3>
                      {selectedSize ? (
                        <div className="space-y-4">
                          <div className="flex items-center justify-center space-x-4">
                            <div className="text-center border border-purple-300 bg-white rounded-xl p-3 w-28">
                              <div className="text-sm font-medium text-purple-700">Smaller Band</div>
                              <div className="text-xl font-semibold text-purple-800 mt-3 min-h-[32px]">
                                {convertSize(sisterSizes[selectedSize][0], selectedSystem)}
                              </div>
                            </div>
                            <div className="text-center border border-purple-300 bg-white rounded-xl p-5 w-32">
                              <div className="text-sm font-medium text-purple-700">Your Size</div>
                              <div className="text-3xl font-bold text-purple-900 mt-3 min-h-[40px]">
                                {convertSize(selectedSize, selectedSystem)}
                              </div>
                            </div>
                            <div className="text-center border border-purple-300 bg-white rounded-xl p-3 w-28">
                              <div className="text-sm font-medium text-purple-700">Larger Band</div>
                              <div className="text-xl font-semibold text-purple-800 mt-3 min-h-[32px]">
                                {convertSize(sisterSizes[selectedSize][1], selectedSystem)}
                              </div>
                            </div>
                          </div>
                          <p className="text-sm text-purple-700 text-center mt-6">
                            Try these sizes if your current size isn't providing the perfect fit.
                          </p>
                        </div>
                      ) : (
                        <div className="flex items-center justify-center h-32">
                          <p className="text-purple-700 text-center">Select your current size to see sister sizes</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center">
                <Link
                  to="/bra-sister-sizes"
                  className="inline-flex items-center px-8 py-4 text-lg font-medium text-white bg-purple-600 rounded-xl hover:bg-purple-700 transition-colors shadow-lg hover:shadow-xl"
                >
                  Full Sister Size Calculator
                  <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                  </svg>
                </Link>
                <p className="text-sm text-gray-500 mt-4">
                  Get more detailed sister size calculations and recommendations
                </p>
              </div>
            </div>
          )}

          {activeTab === 'tips' && (
            <div className="space-y-8">
              <div className="grid md:grid-cols-2 gap-8">
                <div className="bg-white rounded-xl shadow overflow-hidden">
                  <div className="bg-purple-50 px-8 py-6 border-b border-purple-100">
                    <h3 className="text-xl font-semibold text-purple-900">Band Measurement Tips</h3>
                  </div>
                  <div className="p-8">
                    <ul className="space-y-6">
                      <li className="flex items-start gap-4">
                        <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full bg-purple-100 text-purple-600">1</span>
                        <div>
                          <p className="font-medium text-gray-900">Measure Under Bust</p>
                          <p className="mt-1 text-gray-600">Measure directly under your breasts where the band sits</p>
                        </div>
                      </li>
                      <li className="flex items-start gap-4">
                        <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full bg-purple-100 text-purple-600">2</span>
                        <div>
                          <p className="font-medium text-gray-900">Keep Level</p>
                          <p className="mt-1 text-gray-600">Ensure the measuring tape is parallel to the ground</p>
                        </div>
                      </li>
                      <li className="flex items-start gap-4">
                        <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full bg-purple-100 text-purple-600">3</span>
                        <div>
                          <p className="font-medium text-gray-900">Proper Tension</p>
                          <p className="mt-1 text-gray-600">The tape should be snug but not tight</p>
                        </div>
                      </li>
                      <li className="flex items-start gap-4">
                        <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full bg-purple-100 text-purple-600">4</span>
                        <div>
                          <p className="font-medium text-gray-900">Round Number</p>
                          <p className="mt-1 text-gray-600">Round to the nearest whole number for accuracy</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="bg-white rounded-xl shadow overflow-hidden">
                  <div className="bg-purple-50 px-8 py-6 border-b border-purple-100">
                    <h3 className="text-xl font-semibold text-purple-900">Cup Measurement Tips</h3>
                  </div>
                  <div className="p-8">
                    <ul className="space-y-6">
                      <li className="flex items-start gap-4">
                        <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full bg-purple-100 text-purple-600">1</span>
                        <div>
                          <p className="font-medium text-gray-900">Fullest Point</p>
                          <p className="mt-1 text-gray-600">Measure around the fullest part of your bust</p>
                        </div>
                      </li>
                      <li className="flex items-start gap-4">
                        <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full bg-purple-100 text-purple-600">2</span>
                        <div>
                          <p className="font-medium text-gray-900">Parallel Position</p>
                          <p className="mt-1 text-gray-600">Keep the measuring tape parallel to the ground</p>
                        </div>
                      </li>
                      <li className="flex items-start gap-4">
                        <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full bg-purple-100 text-purple-600">3</span>
                        <div>
                          <p className="font-medium text-gray-900">Gentle Measurement</p>
                          <p className="mt-1 text-gray-600">Don't pull the tape measure too tight</p>
                        </div>
                      </li>
                      <li className="flex items-start gap-4">
                        <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full bg-purple-100 text-purple-600">4</span>
                        <div>
                          <p className="font-medium text-gray-900">Proper Attire</p>
                          <p className="mt-1 text-gray-600">Wear an unpadded bra for best results</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-xl shadow overflow-hidden">
                <div className="bg-red-50 px-8 py-6 border-b border-red-100">
                  <h3 className="text-xl font-semibold text-red-900">Signs of a Poor Fit</h3>
                </div>
                <div className="p-8">
                  <div className="grid md:grid-cols-2 gap-x-12 gap-y-6">
                    <div className="space-y-6">
                      <div className="flex items-start gap-4">
                        <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full bg-red-100 text-red-600">!</span>
                        <div>
                          <p className="font-medium text-gray-900">Band Position</p>
                          <p className="mt-1 text-gray-600">Band rides up in the back</p>
                        </div>
                      </div>
                      <div className="flex items-start gap-4">
                        <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full bg-red-100 text-red-600">!</span>
                        <div>
                          <p className="font-medium text-gray-900">Strap Issues</p>
                          <p className="mt-1 text-gray-600">Straps dig into shoulders</p>
                        </div>
                      </div>
                      <div className="flex items-start gap-4">
                        <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full bg-red-100 text-red-600">!</span>
                        <div>
                          <p className="font-medium text-gray-900">Cup Fit</p>
                          <p className="mt-1 text-gray-600">Cup spillage or gaping</p>
                        </div>
                      </div>
                    </div>
                    <div className="space-y-6">
                      <div className="flex items-start gap-4">
                        <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full bg-red-100 text-red-600">!</span>
                        <div>
                          <p className="font-medium text-gray-900">Underwire Comfort</p>
                          <p className="mt-1 text-gray-600">Underwire pokes or pinches</p>
                        </div>
                      </div>
                      <div className="flex items-start gap-4">
                        <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full bg-red-100 text-red-600">!</span>
                        <div>
                          <p className="font-medium text-gray-900">Center Gore</p>
                          <p className="mt-1 text-gray-600">Center gore doesn't lay flat</p>
                        </div>
                      </div>
                      <div className="flex items-start gap-4">
                        <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full bg-red-100 text-red-600">!</span>
                        <div>
                          <p className="font-medium text-gray-900">Band Tightness</p>
                          <p className="mt-1 text-gray-600">Band feels too tight or loose</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-12 text-center">
                <Link
                  to="/bra-size-measuring-guide"
                  className="inline-flex items-center px-8 py-4 text-lg font-medium text-white bg-purple-500 rounded-xl hover:bg-purple-400 transition-colors shadow hover:shadow-md"
                >
                  View Detailed Measuring Guide
                  <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                  </svg>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SizeCharts;
