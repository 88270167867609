import React, { useState } from 'react';
import { Measurements, SizeResult, Step } from '../types/braSizeTypes';
import { EnhancedMeasurements, FitIssue } from '../types/fitTypes';
import { RecommendationResult } from '../types/brandTypes';
import { calculateEnhancedBraSize } from '../utils/braSizeCalculator';
import { generateBrandRecommendations } from '../utils/recommendationEngine';
import { calculateConfidence } from '../utils/confidenceCalculator';
import fitIssues from '../data/fitIssues';
import { brandDatabase } from '../data/brandDatabase';
import { getLetterSize, SizingSystem } from '../data/sizingSystems';

const NewBraSizeCalculator: React.FC = () => {
  const [step, setStep] = useState<Step>('unit');
  const [measurements, setMeasurements] = useState<EnhancedMeasurements>({
    underbust: 0,
    bust: 0,
    unit: 'inches',
    fitIssues: [] as FitIssue['id'][],
    pricePreference: '$',
    stylePreferences: []
  });
  const [result, setResult] = useState<SizeResult | null>(null);
  const [confidence, setConfidence] = useState<number>(0);
  const [recommendations, setRecommendations] = useState<RecommendationResult | null>(null);
  const [showTooltip, setShowTooltip] = useState<string | null>(null);

  const tooltips = {
    underbust: "Measure directly under your bust, around your ribcage. The tape should be snug but not tight.",
    bust: "Measure around the fullest part of your bust while wearing an unpadded bra. Keep the tape parallel to the ground.",
    currentSize: "Your current bra size helps us provide more accurate recommendations.",
    fitIssues: "Common fit issues help us understand how your current bra fits and provide better recommendations."
  };

  const InfoIcon = () => (
    <svg className="w-4 h-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
  );

  const handleCalculate = () => {
    // Define minimum and maximum measurements (in cm)
    const minMeasurements = {
      centimeters: { underbust: 50, bust: 55 }, // Adjusted for younger users
      inches: { underbust: 20, bust: 22 }
    };
    const maxMeasurements = {
      centimeters: { underbust: 190, bust: 215 }, // Adjusted for plus-size
      inches: { underbust: 75, bust: 85 }
    };

    // Get the correct measurements based on unit
    const currentMinMeasurements = minMeasurements[measurements.unit];
    const currentMaxMeasurements = maxMeasurements[measurements.unit];

    // Check if measurements are within realistic ranges
    if (measurements.underbust < currentMinMeasurements.underbust ||
        measurements.underbust > currentMaxMeasurements.underbust ||
        measurements.bust < currentMinMeasurements.bust ||
        measurements.bust > currentMaxMeasurements.bust) {
      alert(`Please check your measurements. The values you entered are outside our supported range (${measurements.unit}):
Underbust: ${currentMinMeasurements.underbust}-${currentMaxMeasurements.underbust} ${measurements.unit}
Bust: ${currentMinMeasurements.bust}-${currentMaxMeasurements.bust} ${measurements.unit}`);
      return;
    }

    // Check if bust is larger than underbust
    if (measurements.bust <= measurements.underbust) {
      alert("Your bust measurement should be larger than your underbust measurement. Please check your measurements.");
      return;
    }

    // Minimum difference between bust and underbust (adjusted for all sizes)
    const minDifference = measurements.unit === 'centimeters' ? 2 : 0.8;
    if (measurements.bust - measurements.underbust < minDifference) {
      alert("The difference between your bust and underbust measurements seems too small. Please double-check your measurements.");
      return;
    }

    const calculatedSize = calculateEnhancedBraSize(
      measurements,
      measurements.fitIssues || []
    );

    const calculatedConfidence = calculateConfidence(measurements);
    const calculatedRecommendations = generateBrandRecommendations(
      calculatedSize,
      measurements,
      measurements.fitIssues || []
    );

    setResult(calculatedSize);
    setConfidence(calculatedConfidence);
    setRecommendations(calculatedRecommendations);
    setStep('result');
  };

  const getProgressWidth = (currentStep: Step): string => {
    switch (currentStep) {
      case 'unit':
        return '33%';
      case 'measurements':
        return '66%';
      case 'result':
        return '100%';
      default:
        return '0%';
    }
  };

  const handleFitIssueToggle = (issueId: FitIssue['id']) => {
    const currentFitIssues = measurements.fitIssues || [];
    setMeasurements(m => ({
      ...m,
      fitIssues: currentFitIssues.includes(issueId)
        ? currentFitIssues.filter(id => id !== issueId)
        : [...currentFitIssues, issueId]
    }));
  };

  if (step === 'unit') {
    return (
      <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="h-2 bg-gray-100">
          <div 
            className="h-full bg-purple-500 transition-all duration-300"
            style={{ width: getProgressWidth(step) }}
          />
        </div>
        <div className="p-6">
          <h2 className="text-xl font-semibold mb-9 text-center">Select Your Unit of Measurement</h2>
          <div className="flex gap-9 justify-center">
            <button
              className={`px-6 py-3 rounded-lg text-lg ${
                measurements.unit === 'inches' 
                  ? 'bg-purple-500 text-white' 
                  : 'bg-gray-100 text-gray-700'
              }`}
              onClick={() => setMeasurements(m => ({ ...m, unit: 'inches' }))}
            >
              Inches
            </button>
            <button
              className={`px-6 py-3 rounded-lg text-lg ${
                measurements.unit === 'centimeters' 
                  ? 'bg-purple-500 text-white' 
                  : 'bg-gray-100 text-gray-700'
              }`}
              onClick={() => setMeasurements(m => ({ ...m, unit: 'centimeters' }))}
            >
              Centimeters
            </button>
          </div>
          <button
            className="w-full py-3 bg-purple-500 text-white rounded-lg mt-9 text-lg"
            onClick={() => setStep('measurements')}
          >
            Next
          </button>
        </div>
      </div>
    );
  }

  if (step === 'measurements') {
    return (
      <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="h-2 bg-gray-100">
          <div 
            className="h-full bg-purple-500 transition-all duration-300"
            style={{ width: getProgressWidth(step) }}
          />
        </div>
        <div className="p-6">
          <h2 className="text-xl font-semibold mb-6 text-center">Enter Your Measurements</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-base font-medium text-gray-700 mb-2 flex items-center">
                Band/Underbust Measurement ({measurements.unit})
                <div 
                  className="ml-2 relative"
                  onMouseEnter={() => setShowTooltip('underbust')}
                  onMouseLeave={() => setShowTooltip(null)}
                >
                  <InfoIcon />
                  {showTooltip === 'underbust' && (
                    <div className="absolute left-6 top-0 w-64 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
                      {tooltips.underbust}
                    </div>
                  )}
                </div>
              </label>
              <input
                type="number"
                className="w-full p-3 border rounded-lg"
                value={measurements.underbust || ''}
                onChange={(e) => setMeasurements(m => ({ ...m, underbust: parseFloat(e.target.value) || 0 }))}
                placeholder={`Enter underbust measurement in ${measurements.unit}`}
              />
            </div>

            <div>
              <label className="block text-base font-medium text-gray-700 mb-2 flex items-center">
                Bust Measurement ({measurements.unit})
                <div 
                  className="ml-2 relative"
                  onMouseEnter={() => setShowTooltip('bust')}
                  onMouseLeave={() => setShowTooltip(null)}
                >
                  <InfoIcon />
                  {showTooltip === 'bust' && (
                    <div className="absolute left-6 top-0 w-64 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
                      {tooltips.bust}
                    </div>
                  )}
                </div>
              </label>
              <input
                type="number"
                className="w-full p-3 border rounded-lg"
                value={measurements.bust || ''}
                onChange={(e) => setMeasurements(m => ({ ...m, bust: parseFloat(e.target.value) || 0 }))}
                placeholder={`Enter bust measurement in ${measurements.unit}`}
              />
            </div>

            <div>
              <label className="block text-base font-medium text-gray-700 mb-2 flex items-center">
                Current Bra Size (Optional)
                <div 
                  className="ml-2 relative"
                  onMouseEnter={() => setShowTooltip('currentSize')}
                  onMouseLeave={() => setShowTooltip(null)}
                >
                  <InfoIcon />
                  {showTooltip === 'currentSize' && (
                    <div className="absolute left-6 top-0 w-64 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
                      {tooltips.currentSize}
                    </div>
                  )}
                </div>
              </label>
              <input
                type="text"
                className="w-full p-3 border rounded-lg"
                value={measurements.currentSize}
                onChange={(e) => setMeasurements(m => ({ ...m, currentSize: e.target.value }))}
                placeholder="e.g., 34C"
              />
            </div>

            <div>
              <label className="block text-base font-medium text-gray-700 mb-2 flex items-center">
                Current Fit Issues (Optional)
                <div 
                  className="ml-2 relative"
                  onMouseEnter={() => setShowTooltip('fitIssues')}
                  onMouseLeave={() => setShowTooltip(null)}
                >
                  <InfoIcon />
                  {showTooltip === 'fitIssues' && (
                    <div className="absolute left-6 top-0 w-64 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
                      {tooltips.fitIssues}
                    </div>
                  )}
                </div>
              </label>
              <div className="grid grid-cols-2 gap-2 mt-2">
                {fitIssues.map((issue: FitIssue) => (
                  <div
                    key={issue.id}
                    className={`px-4 py-2 rounded-lg cursor-pointer transition-colors flex items-center ${
                      measurements.fitIssues?.includes(issue.id)
                        ? 'bg-purple-50 text-purple-700 border border-purple-500 border-solid'
                        : 'bg-white border border-gray-200 text-gray-700'
                    }`}
                    onClick={() => handleFitIssueToggle(issue.id)}
                  >
                    <div className={`w-5 h-5 rounded border mr-3 flex items-center justify-center ${
                      measurements.fitIssues?.includes(issue.id)
                        ? 'border-purple-500 bg-purple-500'
                        : 'border-gray-300'
                    }`}>
                      {measurements.fitIssues?.includes(issue.id) && (
                        <svg className="w-3 h-3 text-white" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                      )}
                    </div>
                    {issue.label}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="flex gap-4 mt-6">
            <button
              className="flex-1 py-3 bg-gray-100 text-gray-700 text-lg rounded-lg hover:bg-gray-200"
              onClick={() => {
                setStep('unit');
                const calculatorSection = document.getElementById('calculator');
                if (calculatorSection) {
                  calculatorSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
              }}
            >
              ← Back
            </button>
            <button
              className="flex-1 py-3 bg-purple-500 text-white text-lg rounded-lg hover:bg-purple-600"
              onClick={handleCalculate}
            >
              Calculate Size →
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id="calculator" className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-sm">
      {result && (
        <>
          <div className="bg-gradient-to-br from-purple-50 to-purple-100 rounded-2xl p-6 text-center mb-6">
            <h2 className="text-3xl font-bold mb-2 text-gray-800">Your Perfect Fit</h2>
            <div className="text-7xl font-bold text-purple-600 mb-4">
              {result.internationalSizes.US}
              {result.letterSize && (
                <div className="text-2xl mt-2 text-purple-400">
                  {result.letterSize}
                </div>
              )}
            </div>
            <div className="flex justify-center gap-2 mb-4">
              {confidence && (
                <span className={`px-3 py-1 rounded-full text-sm ${
                  confidence * 100 > 80 ? 'bg-green-100 text-green-700' :
                  confidence * 100 > 60 ? 'bg-yellow-100 text-yellow-700' :
                  'bg-red-100 text-red-700'
                }`}>
                  {Math.round(confidence * 100)}% Confidence
                </span>
              )}
            </div>
            <p className="text-gray-600 max-w-md mx-auto">
              Based on your measurements, this size should provide optimal support and comfort
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-8 mb-8">
            <div className="bg-white p-6 rounded-xl border border-gray-100">
              <h3 className="flex items-center text-xl font-semibold mb-4">
                <span className="mr-2">🔄</span>
                Sister Sizes
              </h3>
              <p className="text-gray-600 mb-4">
                These sizes have the same cup volume but different band sizes:
              </p>
              <div className="flex gap-2 mb-4">
                <div className="flex-1 px-4 py-3 bg-purple-50 rounded-lg text-center text-purple-700 font-medium">
                  {result.sisterSizes[0]?.bandSize}{result.sisterSizes[0]?.cupSize}
                  <div className="text-xs text-gray-500 mt-1">Tighter Band</div>
                </div>
                <div className="flex-1 px-4 py-3 bg-purple-100 rounded-lg text-center text-purple-700 font-medium border-2 border-purple-200">
                  {result.bandSize}{result.cupSize}
                  <div className="text-xs text-gray-500 mt-1">Calculated Size</div>
                </div>
                <div className="flex-1 px-4 py-3 bg-purple-50 rounded-lg text-center text-purple-700 font-medium">
                  {result.sisterSizes[result.sisterSizes.length - 1]?.bandSize}
                  {result.sisterSizes[result.sisterSizes.length - 1]?.cupSize}
                  <div className="text-xs text-gray-500 mt-1">Looser Band</div>
                </div>
              </div>
              <button 
                onClick={() => window.location.href = 'http://localhost:3000/bra-sister-sizes'} 
                className="text-purple-600 hover:text-purple-700 text-sm font-medium cursor-pointer"
              >
                Try Our Sister Sizes Calculator →
              </button>
            </div>

            <div className="bg-white p-6 rounded-xl border border-gray-100">
              <h3 className="flex items-center text-xl font-semibold mb-4">
                <span className="mr-2">🌍</span>
                International Sizes
              </h3>
              <div className="space-y-3">
                {[
                  { label: 'US' as SizingSystem, value: result.internationalSizes.US },
                  { label: 'UK' as SizingSystem, value: result.internationalSizes.UK },
                  { label: 'EU' as SizingSystem, value: result.internationalSizes.EU },
                  { label: 'FR_ES' as SizingSystem, value: result.internationalSizes.FR_ES },
                  { label: 'IT' as SizingSystem, value: result.internationalSizes.IT },
                  { label: 'AU_NZ' as SizingSystem, value: result.internationalSizes.AU_NZ },
                  { label: 'JP' as SizingSystem, value: result.internationalSizes.JP }
                ].map(size => {
                  const bandSizeMatch = size.value?.match(/\d+/);
                  const cupSizeMatch = size.value?.match(/[A-Z]+/);
                  const bandSize = bandSizeMatch ? parseInt(bandSizeMatch[0]) : 0;
                  const cupSize = cupSizeMatch ? cupSizeMatch[0] : '';
                  const letterSize = getLetterSize(bandSize, cupSize, size.label);
                  return (
                    <div key={size.label} className="flex justify-between items-center p-2 bg-gray-50 rounded-lg">
                      <span className="text-gray-600">{size.label.replace('_', '/')}</span>
                      <span className="font-medium text-purple-600">
                        {size.value} {letterSize ? `(${letterSize})` : ''}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {(measurements.fitIssues || []).length > 0 && (
            <div className="bg-white p-6 rounded-xl border border-gray-100 mb-8">
              <h3 className="flex items-center text-xl font-semibold mb-4">
                <span className="mr-2">✨</span>
                Fit Solutions
              </h3>
              <div className="space-y-4">
                {(measurements.fitIssues || []).map(issueId => {
                  const issue = fitIssues.find((fi: FitIssue) => fi.id === issueId);
                  return issue ? (
                    <div key={issue.id} className="p-4 bg-purple-50 rounded-lg">
                      <h4 className="font-medium text-purple-700 mb-2">{issue.label}</h4>
                      <p className="text-gray-600 text-sm">{issue.solution}</p>
                    </div>
                  ) : null;
                })}
              </div>
            </div>
          )}

          {/* Personalized Recommendations */}
          {/* {recommendations && recommendations.brands.length > 0 && (
            <div className="bg-white p-6 rounded-xl border border-gray-100">
              <h3 className="text-2xl font-semibold mb-6">Personalized Recommendations</h3>
              <div className="grid gap-4">
                {recommendations.brands.map((brandName, index) => {
                  const brand = brandDatabase.find(b => b.name === brandName);
                  if (!brand) return null;
                  return (
                    <div key={brand.name} className="p-4 rounded-lg bg-gradient-to-r from-purple-50 to-white border border-purple-100">
                      <div className="flex justify-between items-start mb-2">
                        <div>
                          <h4 className="text-lg font-semibold text-gray-800">{brand.name}</h4>
                          <p className="text-sm text-gray-500">{brand.price} • {brand.availability}</p>
                        </div>
                        <div className="px-3 py-1 bg-purple-100 text-purple-700 rounded-full text-sm">
                          {recommendations.scores[index]}% Match
                        </div>
                      </div>
                      <p className="text-gray-600 text-sm mb-3">
                        {recommendations.reasonings[index]}
                      </p>
                      <div className="flex flex-wrap gap-2">
                        {brand.features.map(feature => (
                          <span key={feature} className="px-2 py-1 bg-white text-purple-600 text-xs rounded-full border border-purple-200">
                            {feature}
                          </span>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )} */}

          <div className="mt-8 flex justify-center">
            <button
              onClick={() => {
                setStep('unit');
                setMeasurements({
                  underbust: 0,
                  bust: 0,
                  unit: 'inches',
                  fitIssues: [],
                  pricePreference: '$',
                  stylePreferences: []
                });
                setResult(null);
                setRecommendations(null);
                setConfidence(0);
                const calculatorSection = document.getElementById('calculator');
                if (calculatorSection) {
                  calculatorSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
              }}
              className="px-8 py-3 bg-gray-100 text-gray-700 rounded-xl text-lg font-medium hover:bg-gray-200 transition-all transform hover:scale-105"
            >
              Start Over
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default NewBraSizeCalculator;
