import { BrandProfile } from '../types/brandTypes';

export const brandDatabase: BrandProfile[] = [
  {
    name: "Victoria's Secret",
    sizeRange: {
      bands: [30, 32, 34, 36, 38, 40],
      cups: ["A", "B", "C", "D", "DD", "DDD"]
    },
    features: ["adjustable straps", "hook-and-eye closure", "underwire"],
    specialties: ["everyday-comfort", "push-up", "t-shirt"],
    style: ["t-shirt", "push-up", "wireless"],
    price: "$$",
    availability: "wide"
  },
  {
    name: "ThirdLove",
    sizeRange: {
      bands: [30, 32, 34, 36, 38, 40, 42, 44, 46, 48],
      cups: ["AA", "A", "B", "C", "D", "DD", "DDD", "G", "H", "I"]
    },
    features: ["memory foam cups", "pleated straps", "tagless"],
    specialties: ["half-cups", "everyday-comfort", "t-shirt"],
    style: ["t-shirt", "full coverage", "wireless"],
    price: "$$$",
    availability: "online"
  },
  {
    name: "Wacoal",
    sizeRange: {
      bands: [30, 32, 34, 36, 38, 40, 42, 44],
      cups: ["A", "B", "C", "D", "DD", "DDD", "G", "H"]
    },
    features: ["seamless cups", "comfort straps", "wireless options"],
    specialties: ["full-cups", "everyday-comfort", "minimizers"],
    style: ["full coverage", "minimizer", "sports"],
    price: "$$$",
    availability: "department stores"
  }
];
