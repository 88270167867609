// Define the sizing system type
export type SizingSystem = 'US' | 'UK' | 'EU' | 'FR_ES' | 'IT' | 'JP' | 'AU_NZ';

// Define the size type
export type SizeType = 'smaller' | 'current' | 'larger';

// Define the size data structure
export interface BraSize {
  bandSize: number;
  cupSize: string;
  type: SizeType;
}

// Define cup size progression for each system
export const cupSizes: Record<SizingSystem, string[]> = {
  UK: [
    'AA', 'A', 'B', 'C', 'D', 'DD', 'E', 'F', 'FF', 'G', 'GG', 'H', 'HH',
    'J', 'JJ', 'K', 'KK', 'L', 'LL', 'M', 'MM', 'N', 'NN', 'O', 'OO', 'P', 'PP'
  ],
  US: [
    'AA', 'A', 'B', 'C', 'D', 'DD/E', 'DDD/F', 'DDDD/G', 'H', 'I', 'J', 'K',
    'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
  ],
  EU: [
    'AA', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
    'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
  ],
  FR_ES: [
    'AA', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
    'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
  ],
  IT: [
    '-', 'A', 'B', 'C', 'D', 'DD', 'E', 'F', 'FF', 'G', 'GG', 'H', 'HH',
    'J', 'JJ', 'K', 'KK', 'L', 'LL', 'M', 'MM', 'N', 'NN', 'O', 'OO', 'P', 'PP'
  ],
  JP: [
    'AA', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
    'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
  ],
  AU_NZ: [
    'AA', 'A', 'B', 'C', 'D', 'DD', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L',
    'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y'
  ]
};

// Define band sizes for each system
export const bandSizes: Record<SizingSystem, number[]> = {
  US: [26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60],
  UK: [26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60],
  EU: [55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140],
  FR_ES: [70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155],
  IT: [-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
  JP: [55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140],
  AU_NZ: [4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38]
};

// Define size conversions between systems
export const sizeConversions = [
  { US: 26, UK: 26, EU: 55, FR_ES: 60, IT: -1, JP: 55, AU_NZ: 4 },
  { US: 28, UK: 28, EU: 60, FR_ES: 65, IT: 0, JP: 60, AU_NZ: 6 },
  { US: 30, UK: 30, EU: 65, FR_ES: 70, IT: 1, JP: 65, AU_NZ: 8 },
  { US: 32, UK: 32, EU: 70, FR_ES: 75, IT: 2, JP: 70, AU_NZ: 10 },
  { US: 34, UK: 34, EU: 75, FR_ES: 80, IT: 3, JP: 75, AU_NZ: 12 },
  { US: 36, UK: 36, EU: 80, FR_ES: 85, IT: 4, JP: 80, AU_NZ: 14 },
  { US: 38, UK: 38, EU: 85, FR_ES: 90, IT: 5, JP: 85, AU_NZ: 16 },
  { US: 40, UK: 40, EU: 90, FR_ES: 95, IT: 6, JP: 90, AU_NZ: 18 },
  { US: 42, UK: 42, EU: 95, FR_ES: 100, IT: 7, JP: 95, AU_NZ: 20 },
  { US: 44, UK: 44, EU: 100, FR_ES: 105, IT: 8, JP: 100, AU_NZ: 22 },
  { US: 46, UK: 46, EU: 105, FR_ES: 110, IT: 9, JP: 105, AU_NZ: 24 },
  { US: 48, UK: 48, EU: 110, FR_ES: 115, IT: 10, JP: 110, AU_NZ: 26 },
  { US: 50, UK: 50, EU: 115, FR_ES: 120, IT: 11, JP: 115, AU_NZ: 28 },
  { US: 52, UK: 52, EU: 120, FR_ES: 125, IT: 12, JP: 120, AU_NZ: 30 },
  { US: 54, UK: 54, EU: 125, FR_ES: 130, IT: 13, JP: 125, AU_NZ: 32 },
  { US: 56, UK: 56, EU: 130, FR_ES: 135, IT: 14, JP: 130, AU_NZ: 34 },
  { US: 58, UK: 58, EU: 135, FR_ES: 140, IT: 15, JP: 135, AU_NZ: 36 },
  { US: 60, UK: 60, EU: 140, FR_ES: 145, IT: 16, JP: 140, AU_NZ: 38 }
];

// Helper function to convert band size between systems
export function convertBandSize(size: number, fromSystem: SizingSystem, toSystem: SizingSystem): number | null {
  const conversion = sizeConversions.find(conv => conv[fromSystem] === size);
  return conversion ? conversion[toSystem] : null;
}

// Helper function to convert cup size between systems
export function convertCupSize(size: string, fromSystem: SizingSystem, toSystem: SizingSystem): string | null {
  const fromIndex = cupSizes[fromSystem].indexOf(size);
  if (fromIndex === -1) return null;
  
  const toSizes = cupSizes[toSystem];
  return fromIndex < toSizes.length ? toSizes[fromIndex] : null;
}

// Calculate sister sizes
export function calculateSisterSizes(bandSize: number, cupSize: string, system: SizingSystem): BraSize[] {
  const cupIndex = cupSizes[system].indexOf(cupSize);
  if (cupIndex === -1) return [];

  const bandIndex = bandSizes[system].indexOf(bandSize);
  if (bandIndex === -1) return [];

  const sisterSizes: BraSize[] = [];

  // Add smaller sister size (go down in band, up in cup)
  if (bandIndex > 0 && cupIndex < cupSizes[system].length - 1) {
    sisterSizes.push({
      bandSize: bandSizes[system][bandIndex - 1],
      cupSize: cupSizes[system][cupIndex + 1],
      type: 'smaller'
    });
  }

  // Add current size
  sisterSizes.push({
    bandSize,
    cupSize,
    type: 'current'
  });

  // Add larger sister size (go up in band, down in cup)
  if (bandIndex < bandSizes[system].length - 1 && cupIndex > 0) {
    sisterSizes.push({
      bandSize: bandSizes[system][bandIndex + 1],
      cupSize: cupSizes[system][cupIndex - 1],
      type: 'larger'
    });
  }

  return sisterSizes;
}

// Get all valid sizes for a system
export function getValidSizes(system: SizingSystem): string[] {
  return bandSizes[system].flatMap(band => 
    cupSizes[system].map(cup => `${band}${cup}`)
  );
}

// Convert a size to another sizing system
export function convertSize(bandSize: number, cupSize: string, fromSystem: SizingSystem, toSystem: SizingSystem): string | null {
  const newBand = convertBandSize(bandSize, fromSystem, toSystem);
  const newCup = convertCupSize(cupSize, fromSystem, toSystem);
  
  return newBand && newCup ? `${newBand}${newCup}` : null;
}
