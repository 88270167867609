import React from 'react';
import { Link } from 'react-router-dom';

const TermsOfService: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto">
          {/* Hero Section */}
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
              Terms of Service
            </h1>
            <p className="text-gray-600">
              Last updated: December 7, 2024
            </p>
          </div>

          {/* Table of Contents */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 mb-8">
            <h2 className="text-xl font-semibold mb-4">Table of Contents</h2>
            <nav className="space-y-2">
              <a href="#agreement" className="block text-purple-600 hover:text-purple-700">1. Agreement to Terms</a>
              <a href="#services" className="block text-purple-600 hover:text-purple-700">2. Our Services</a>
              <a href="#intellectual-property" className="block text-purple-600 hover:text-purple-700">3. Intellectual Property Rights</a>
              <a href="#user-accounts" className="block text-purple-600 hover:text-purple-700">4. User Accounts</a>
              <a href="#prohibited" className="block text-purple-600 hover:text-purple-700">5. Prohibited Activities</a>
              <a href="#disclaimer" className="block text-purple-600 hover:text-purple-700">6. Disclaimer</a>
              <a href="#limitation" className="block text-purple-600 hover:text-purple-700">7. Limitation of Liability</a>
              <a href="#contact" className="block text-purple-600 hover:text-purple-700">8. Contact Us</a>
            </nav>
          </div>

          {/* Content Sections */}
          <div className="space-y-8">
            <section id="agreement" className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
              <h2 className="text-2xl font-semibold mb-4">1. Agreement to Terms</h2>
              <div className="prose prose-purple max-w-none">
                <p className="text-gray-600">
                  By accessing our website, you agree to be bound by these Terms of Service and to comply with 
                  all applicable laws and regulations. If you do not agree with any of these terms, you are 
                  prohibited from using or accessing this site.
                </p>
              </div>
            </section>

            <section id="services" className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
              <h2 className="text-2xl font-semibold mb-4">2. Our Services</h2>
              <div className="prose prose-purple max-w-none">
                <p className="text-gray-600 mb-4">
                  Bra Size Calculator Pro provides an online tool for calculating bra sizes. Our services include:
                </p>
                <ul className="list-disc pl-6 mb-4 text-gray-600">
                  <li>Bra size calculation based on user measurements</li>
                  <li>Educational resources about bra fitting</li>
                  <li>Size conversion charts</li>
                  <li>Fitting guides and recommendations</li>
                </ul>
              </div>
            </section>

            <section id="intellectual-property" className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
              <h2 className="text-2xl font-semibold mb-4">3. Intellectual Property Rights</h2>
              <div className="prose prose-purple max-w-none">
                <p className="text-gray-600">
                  The Service and its original content, features, and functionality are owned by 
                  Bra Size Calculator Pro and are protected by international copyright, trademark, 
                  patent, trade secret, and other intellectual property laws.
                </p>
              </div>
            </section>

            <section id="user-accounts" className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
              <h2 className="text-2xl font-semibold mb-4">4. User Accounts</h2>
              <div className="prose prose-purple max-w-none">
                <p className="text-gray-600">
                  When you create an account with us, you guarantee that the information you provide is 
                  accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete 
                  information may result in the immediate termination of your account on the Service.
                </p>
              </div>
            </section>

            <section id="prohibited" className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
              <h2 className="text-2xl font-semibold mb-4">5. Prohibited Activities</h2>
              <div className="prose prose-purple max-w-none">
                <p className="text-gray-600 mb-4">You agree not to engage in any of the following activities:</p>
                <ul className="list-disc pl-6 mb-4 text-gray-600">
                  <li>Using the service for any unlawful purpose</li>
                  <li>Attempting to gain unauthorized access to our systems</li>
                  <li>Interfering with or disrupting the service</li>
                  <li>Collecting user information without consent</li>
                </ul>
              </div>
            </section>

            <section id="disclaimer" className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
              <h2 className="text-2xl font-semibold mb-4">6. Disclaimer</h2>
              <div className="prose prose-purple max-w-none">
                <p className="text-gray-600">
                  Your use of the Service is at your sole risk. The Service is provided on an "AS IS" 
                  and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, 
                  whether express or implied.
                </p>
              </div>
            </section>

            <section id="limitation" className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
              <h2 className="text-2xl font-semibold mb-4">7. Limitation of Liability</h2>
              <div className="prose prose-purple max-w-none">
                <p className="text-gray-600">
                  In no event shall Bra Size Calculator Pro, nor its directors, employees, partners, 
                  agents, suppliers, or affiliates, be liable for any indirect, incidental, special, 
                  consequential, or punitive damages resulting from your use of the Service.
                </p>
              </div>
            </section>

            <section id="contact" className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
              <h2 className="text-2xl font-semibold mb-4">8. Contact Us</h2>
              <div className="prose prose-purple max-w-none">
                <p className="text-gray-600">
                  If you have any questions about these Terms of Service, please{' '}
                  <Link to="/contact" className="text-purple-600 hover:text-purple-700">
                    contact us through our contact page
                  </Link>.
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
