import React from 'react';
import { Link } from 'react-router-dom';

const CookiePolicy: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto">
          {/* Hero Section */}
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
              Cookie Policy
            </h1>
            <p className="text-gray-600">
              Last updated: December 7, 2024
            </p>
          </div>

          {/* Table of Contents */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 mb-8">
            <h2 className="text-xl font-semibold mb-4">Table of Contents</h2>
            <nav className="space-y-2">
              <a href="#introduction" className="block text-purple-600 hover:text-purple-700">1. Introduction</a>
              <a href="#what-are-cookies" className="block text-purple-600 hover:text-purple-700">2. What Are Cookies</a>
              <a href="#types-of-cookies" className="block text-purple-600 hover:text-purple-700">3. Types of Cookies We Use</a>
              <a href="#managing-cookies" className="block text-purple-600 hover:text-purple-700">4. Managing Cookies</a>
              <a href="#third-party" className="block text-purple-600 hover:text-purple-700">5. Third-Party Cookies</a>
              <a href="#updates" className="block text-purple-600 hover:text-purple-700">6. Updates to This Policy</a>
              <a href="#contact" className="block text-purple-600 hover:text-purple-700">7. Contact Us</a>
            </nav>
          </div>

          {/* Content Sections */}
          <div className="space-y-8">
            <section id="introduction" className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
              <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
              <div className="prose prose-purple max-w-none">
                <p className="text-gray-600">
                  This Cookie Policy explains how Bra Size Calculator Pro uses cookies and similar 
                  technologies to recognize you when you visit our website. It explains what these 
                  technologies are and why we use them, as well as your rights to control our use of them.
                </p>
              </div>
            </section>

            <section id="what-are-cookies" className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
              <h2 className="text-2xl font-semibold mb-4">2. What Are Cookies</h2>
              <div className="prose prose-purple max-w-none">
                <p className="text-gray-600">
                  Cookies are small data files that are placed on your computer or mobile device when 
                  you visit a website. They are widely used by website owners to make their websites 
                  work, or to work more efficiently, as well as to provide reporting information.
                </p>
              </div>
            </section>

            <section id="types-of-cookies" className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
              <h2 className="text-2xl font-semibold mb-4">3. Types of Cookies We Use</h2>
              <div className="prose prose-purple max-w-none">
                <p className="text-gray-600 mb-4">We use the following types of cookies:</p>
                <ul className="list-disc pl-6 mb-4 text-gray-600">
                  <li>
                    <strong>Essential Cookies:</strong> Required for the operation of our website
                  </li>
                  <li>
                    <strong>Analytical Cookies:</strong> Allow us to analyze website usage and improve performance
                  </li>
                  <li>
                    <strong>Functional Cookies:</strong> Remember your preferences and personalize your experience
                  </li>
                  <li>
                    <strong>Targeting Cookies:</strong> Record your visit, pages visited, and links followed
                  </li>
                </ul>
              </div>
            </section>

            <section id="managing-cookies" className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
              <h2 className="text-2xl font-semibold mb-4">4. Managing Cookies</h2>
              <div className="prose prose-purple max-w-none">
                <p className="text-gray-600 mb-4">
                  Most browsers allow you to refuse to accept cookies and to delete them. The methods for doing so vary from browser to browser. Here are links to instructions for some popular browsers:
                </p>
                <ul className="list-disc pl-6 mb-4 text-gray-600">
                  <li>
                    <a href="https://support.google.com/chrome/answer/95647" className="text-purple-600 hover:text-purple-700" target="_blank" rel="noopener noreferrer">
                      Google Chrome
                    </a>
                  </li>
                  <li>
                    <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop" className="text-purple-600 hover:text-purple-700" target="_blank" rel="noopener noreferrer">
                      Mozilla Firefox
                    </a>
                  </li>
                  <li>
                    <a href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" className="text-purple-600 hover:text-purple-700" target="_blank" rel="noopener noreferrer">
                      Microsoft Edge
                    </a>
                  </li>
                  <li>
                    <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" className="text-purple-600 hover:text-purple-700" target="_blank" rel="noopener noreferrer">
                      Safari
                    </a>
                  </li>
                </ul>
              </div>
            </section>

            <section id="third-party" className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
              <h2 className="text-2xl font-semibold mb-4">5. Third-Party Cookies</h2>
              <div className="prose prose-purple max-w-none">
                <p className="text-gray-600">
                  In addition to our own cookies, we may also use various third-party cookies to 
                  report usage statistics of the Service and deliver advertisements on and through the 
                  Service. You can learn more about these cookies through your browser settings.
                </p>
              </div>
            </section>

            <section id="updates" className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
              <h2 className="text-2xl font-semibold mb-4">6. Updates to This Policy</h2>
              <div className="prose prose-purple max-w-none">
                <p className="text-gray-600">
                  We may update this Cookie Policy from time to time to reflect changes to the cookies 
                  we use or for other operational, legal, or regulatory reasons. Please revisit this 
                  Cookie Policy regularly to stay informed about our use of cookies and related technologies.
                </p>
              </div>
            </section>

            <section id="contact" className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
              <h2 className="text-2xl font-semibold mb-4">7. Contact Us</h2>
              <div className="prose prose-purple max-w-none">
                <p className="text-gray-600">
                  If you have any questions about our use of cookies or this Cookie Policy, please{' '}
                  <Link to="/contact" className="text-purple-600 hover:text-purple-700">
                    contact us through our contact page
                  </Link>.
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
