import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { AlertTriangle, AlertCircle, CheckCircle } from 'lucide-react';
import {
  SizingSystem,
  SizeType,
  bandSizes,
  cupSizes,
  calculateSisterSizes,
  convertSize
} from '../data/sizingSisterData';

interface InternationalSizes {
  [key: string]: string;
}

type BraSize = {
  bandSize: string;
  cupSize: string;
  type: 'smaller' | 'current' | 'larger';
};

const NewSisterSizes: React.FC = () => {
  const [selectedSystem, setSelectedSystem] = useState<SizingSystem>('US');
  const [bandSize, setBandSize] = useState<string>('');
  const [cupSize, setCupSize] = useState<string>('');
  const [sisterSizes, setSisterSizes] = useState<BraSize[]>([]);
  const [internationalSizes, setInternationalSizes] = useState<Record<SizeType, InternationalSizes | null>>({
    smaller: null,
    current: null,
    larger: null
  });

  // Initialize with first available sizes
  useEffect(() => {
    const firstBand = bandSizes[selectedSystem][0]?.toString() || '';
    const firstCup = cupSizes[selectedSystem][0] || '';
    setBandSize(firstBand);
    setCupSize(firstCup);
  }, [selectedSystem]);

  // Calculate sister sizes when inputs change
  useEffect(() => {
    if (bandSize && cupSize) {
      const sizes = calculateSisterSizes(Number(bandSize), cupSize, selectedSystem);
      setSisterSizes(sizes.map(size => ({
        ...size,
        bandSize: size.bandSize.toString()
      })));

      // Calculate international sizes
      const newInternationalSizes: Record<SizeType, InternationalSizes | null> = {
        smaller: null,
        current: null,
        larger: null
      };

      sizes.forEach(size => {
        const international: InternationalSizes = {
          US: convertSize(size.bandSize, size.cupSize, selectedSystem, 'US') || '-',
          UK: convertSize(size.bandSize, size.cupSize, selectedSystem, 'UK') || '-',
          EU: convertSize(size.bandSize, size.cupSize, selectedSystem, 'EU') || '-',
          FR_ES: convertSize(size.bandSize, size.cupSize, selectedSystem, 'FR_ES') || '-',
          IT: convertSize(size.bandSize, size.cupSize, selectedSystem, 'IT') || '-',
          JP: convertSize(size.bandSize, size.cupSize, selectedSystem, 'JP') || '-',
          AU_NZ: convertSize(size.bandSize, size.cupSize, selectedSystem, 'AU_NZ') || '-'
        };
        newInternationalSizes[size.type] = international;
      });

      setInternationalSizes(newInternationalSizes);
    }
  }, [bandSize, cupSize, selectedSystem]);

  // Helper function to format size display
  const formatSizeDisplay = (size: BraSize) => {
    let description = '';
    if (size.type === 'smaller') {
      description = 'Tighter band (-1 band, +1 cup)';
    } else if (size.type === 'larger') {
      description = 'Looser band (+1 band, -1 cup)';
    } else {
      description = 'Your current size';
    }
    return { size: `${size.bandSize}${size.cupSize}`, description };
  };

  // Helper function to get size type class
  const getSizeTypeClass = (type: SizeType) => {
    switch (type) {
      case 'smaller':
        return 'bg-blue-50 border-blue-100 text-s text-center';
      case 'current':
        return 'bg-purple-100 border-purple-300 text-s text-center';
      case 'larger':
        return 'bg-pink-50 border-pink-10 text-s text-center';
      default:
        return '';
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white py-12">
      <Helmet>
        <title>Sister Size Calculator | Find Your Perfect Bra Size</title>
        <meta name="description" content="Find your perfect sister bra sizes with our calculator. Get accurate size conversions across different international sizing systems." />
      </Helmet>

      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          {/* Hero Section */}
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
              Sister Size Calculator
            </h1>
            <p className="text-gray-600 text-lg max-w-2xl mx-auto">
              Find alternative bra sizes with the same cup volume that may fit you just as well as your regular size.
            </p>
          </div>

          {/* Introduction Section */}
          <div className="bg-white rounded-xl shadow p-8 mb-8">
          <h2 className="text-2xl font-semibold text-purple-900 mb-4 text-center">Understanding Sister Sizes</h2>
            <p className="text-gray-600 text-medium mx-auto mb-6 text-center">
              Sister sizes are bra sizes that share the same cup volume but have different band sizes. This concept is useful when your usual size isn't available or when a brand's sizing runs differently. Our calculator helps you find these alternative sizes while maintaining a proper fit.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-green-50 p-6 rounded-lg">
                <h3 className="text-lg font-semibold mb-4 text-green-800 flex items-center gap-2">
                  <CheckCircle className="w-5 h-5" />
                  Good Uses
                </h3>
                <ul className="space-y-3">
                  <li className="flex items-start gap-2">
                    <div className="mt-0 text-green-500">•</div>
                    <span>When a brand runs small or large</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="mt-0 text-green-500">•</div>
                    <span>If your ideal size is unavailable</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="mt-0 text-green-500">•</div>
                    <span>Between different bra styles</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="mt-0 text-green-500">•</div>
                    <span>During weight fluctuations</span>
                  </li>
                </ul>
              </div>

              <div className="bg-red-50 p-6 rounded-lg">
                <h3 className="text-lg font-semibold mb-4 text-red-800 flex items-center gap-2">
                  <AlertTriangle className="w-5 h-5" />
                  Limitations
                </h3>
                <ul className="space-y-3">
                  <li className="flex items-start gap-2">
                    <div className="mt-0 text-red-500">•</div>
                    <span>Don't go more than 2 sizes from your true band</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="mt-0 text-red-500">•</div>
                    <span>Consider band support as primary factor</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="mt-0 text-red-500">•</div>
                    <span>May not work for all breast shapes</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="mt-0 text-red-500">•</div>
                    <span>Different brands may fit differently</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Size Selection Form */}
          <div className="bg-white rounded-xl shadow p-8 mb-8">
            <h2 className="text-2xl font-semibold text-purple-900 mb-4">Find Your Sister Sizes</h2>
            <div className="grid grid-cols-1 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Sizing System
                </label>
                <select
                  value={selectedSystem}
                  onChange={(e) => setSelectedSystem(e.target.value as SizingSystem)}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                >
                  {Object.keys(bandSizes).map((system) => (
                    <option key={system} value={system}>
                      {system === 'FR_ES' ? 'FR/BE/ES' : system}
                    </option>
                  ))}
                </select>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Band Size
                  </label>
                  <select
                    value={bandSize}
                    onChange={(e) => setBandSize(e.target.value)}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                  >
                    {bandSizes[selectedSystem].map((size) => (
                      <option key={size} value={size}>{size}</option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Cup Size
                  </label>
                  <select
                    value={cupSize}
                    onChange={(e) => setCupSize(e.target.value)}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                  >
                    {cupSizes[selectedSystem].map((size) => (
                      <option key={size} value={size}>{size}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          {/* Sister Sizes Display */}
          {sisterSizes.length > 0 && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white rounded-xl shadow p-8 mb-8"
            >
              <h2 className="text-2xl font-semibold text-purple-900 mb-4">Your Sister Sizes</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {sisterSizes.map((size, index) => {
                  const { size: displaySize, description } = formatSizeDisplay(size);
                  return (
                    <div
                      key={index}
                      className={`p-6 rounded-xl border-2 ${getSizeTypeClass(size.type)} transition-all duration-300 hover:shadow-md`}
                    >
                      <div className="text-4xl font-bold text-purple-600 mb-2">
                        {displaySize}
                      </div>
                      <div className="text-sm text-gray-600">
                        {description}
                      </div>
                    </div>
                  );
                })}
              </div>
            </motion.div>
          )}

          {/* International Size Chart */}
          {internationalSizes.current && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white rounded-xl shadow p-8 mb-8"
            >
              <h2 className="text-2xl font-semibold text-purple-900 mb-4">International Size Chart</h2>
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="p-4 text-left text-gray-500 font-semibold uppercase text-sm tracking-wider">Size System</th>
                      {internationalSizes.smaller && (
                        <th className="p-4 text-center text-gray-500 font-semibold uppercase text-sm tracking-wider">Smaller Sister</th>
                      )}
                      <th className="p-4 text-center text-gray-500 font-semibold uppercase text-sm tracking-wider">Your Size</th>
                      {internationalSizes.larger && (
                        <th className="p-4 text-center text-gray-500 font-semibold uppercase text-sm tracking-wider">Larger Sister</th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {Object.entries(internationalSizes.current).map(([system, size]) => (
                      <tr key={system} className="hover:bg-gray-50">
                        <td className="p-4 font-medium text-gray-900">{system === 'FR_ES' ? 'FR/BE/ES' : system}</td>
                        {internationalSizes.smaller && (
                          <td className="p-4 text-center text-gray-600">{internationalSizes.smaller[system as keyof InternationalSizes]}</td>
                        )}
                        <td className="p-4 text-center font-bold text-purple-600">{size}</td>
                        {internationalSizes.larger && (
                          <td className="p-4 text-center text-gray-600">{internationalSizes.larger[system as keyof InternationalSizes]}</td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <p className="mt-4 text-sm text-gray-400 italic">
                  Note: Sister sizes maintain the same cup volume while adjusting the band size. Going down a band size means going up a cup size, and vice versa.
                </p>
              </div>
            </motion.div>
          )}

          {/* Educational Content */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.5 }}
            className="bg-white rounded-xl shadow p-8"
          >
            <h2 className="text-2xl font-semibold text-purple-900 mb-4 text-center">How Sister Sizing Works</h2>
            <div className="space-y-6">
              <p className="text-gray-700">
                Sister sizing helps find alternative bra sizes with the same cup volume but different band and cup combinations. The rule is simple. Go up in band size and down in cup size and vice versa. This keeps the cup volume consistent despite band changes. 
              </p>

              <div className="bg-purple-50 p-4 rounded-lg">
                <h3 className="text-lg font-semibold mb-2 text-purple-800">The Sister Size Rule</h3>
                <ul className="list-disc list-inside space-y-2 text-purple-900">
                  <li>Going up a band size → Go down a cup size</li>
                  <li>Going down a band size → Go up a cup size</li>
                </ul>
              </div>

              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="text-lg font-semibold mb-4">Example with 34C</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="p-4 bg-purple-100 rounded-lg">
                    <p className="font-semibold text-lg mb-2">32D</p>
                    <p className="text-sm text-gray-600">Smaller band (-2)</p>
                    <p className="text-sm text-gray-600">Larger cup (+1)</p>
                    <p className="text-sm italic mt-2">For those who need a tighter band</p>
                  </div>
                  <div className="p-4 bg-purple-200 rounded-lg border-2 border-purple-500">
                    <p className="font-semibold text-lg mb-2">34C</p>
                    <p className="text-sm text-gray-600">Your current size</p>
                    <p className="text-sm italic mt-2">Perfect fit for you</p>
                  </div>
                  <div className="p-4 bg-purple-100 rounded-lg">
                    <p className="font-semibold text-lg mb-2">36B</p>
                    <p className="text-sm text-gray-600">Larger band (+2)</p>
                    <p className="text-sm text-gray-600">Smaller cup (-1)</p>
                    <p className="text-sm italic mt-2">For those who need a looser band</p>
                  </div>
                </div>
              </div>

              <div className="bg-yellow-50 p-4 rounded-lg">
                <h3 className="font-medium mb-2 flex items-center gap-2">
                  <AlertCircle className="w-4 h-4 text-yellow-500" />
                  Important Notes
                </h3>
                <ul className="list-disc list-inside space-y-2 text-gray-700">
                  <li>Sister sizing works best within 1-2 sizes of your true size</li>
                  <li>The band provides 80% of support, so don't compromise too much</li>
                  <li>Try on sister sizes to find your best fit</li>
                  <li>Consider your breast shape and personal comfort</li>
                </ul>
              </div>
              <div className="bg-yellow-50 p-4 rounded-lg">
                  <h3 className="text-lg font-semibold mb-2 text-yellow-800">Remember</h3>
                  <p className="text-yellow-900">
                    While these sizes have the same cup volume, the best fit will depend on your body shape and comfort preferences. 
                    The band should be snug but comfortable, providing most of the support.
                  </p>
                </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default NewSisterSizes;
