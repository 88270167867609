import { FitIssue } from '../types/fitTypes';

// New interface for the detailed bra fit guide
export interface DetailedFitIssue {
  id: string;
  category: string;
  title: string;
  causes: string[];
  solutions: string[];
  relatedMeasurements: string[];
  recommendedTypes: string[];
}

// Detailed fit issues for the Bra Size Calculator
const fitIssues: readonly FitIssue[] = [
  {
    id: 'straps-falling',
    label: 'Straps falling down',
    category: 'straps',
    sizeImplication: { bandChange: 'down' },
    solution: 'Look for bras with fully adjustable straps and consider a racerback style for better strap stability.'
  },
  {
    id: 'band-rides-up',
    label: 'Band rides up',
    category: 'band',
    sizeImplication: { bandChange: 'down' },
    solution: 'Your band might be too loose. Try fastening it on a tighter hook or consider going down a band size.'
  },
  {
    id: 'cup-spillage',
    label: 'Cup spillage',
    category: 'cups',
    sizeImplication: { cupChange: 'up' },
    solution: 'Your cups might be too small. Consider going up a cup size or trying a full coverage style.'
  },
  {
    id: 'cup-gaps',
    label: 'Gaps in cups',
    category: 'cups',
    sizeImplication: { cupChange: 'down' },
    solution: 'Your cups might be too large or the style might not match your shape. Try going down a cup size or look for styles with stretch lace tops.'
  },
  {
    id: 'wire-digging',
    label: 'Underwire digging in',
    category: 'wire',
    sizeImplication: { bandChange: 'up' },
    solution: 'Try a bra with flexible underwire or consider wireless options. The band might also be too tight.'
  },
  {
    id: 'center-gore',
    label: 'Center gore not lying flat',
    category: 'gore',
    sizeImplication: { cupChange: 'up' },
    solution: 'The cups might be too small or the style might not suit your shape. Consider a plunge style or going up a cup size.'
  },
  {
    id: 'band-tight',
    label: 'Band feels too tight',
    category: 'band',
    sizeImplication: { bandChange: 'up' },
    solution: 'Try going up a band size or look for styles with stretch bands. Make sure to fasten on the loosest hook when new.'
  },
  {
    id: 'band-loose',
    label: 'Band feels too loose',
    category: 'band',
    sizeImplication: { bandChange: 'down' },
    solution: 'Try going down a band size or fastening on a tighter hook. The band should be snug but not uncomfortable.'
  }
] as const;

// Detailed fit issues for the Bra Fit Guide page
export const detailedFitIssues: DetailedFitIssue[] = [
  // Cup Issues
  {
    id: 'empty-space',
    category: 'Cup Issues',
    title: 'Empty Space or Gaping at Cup Top',
    causes: [
      'Cup size too large for breast volume',
      'Breast shape mismatch with cup style',
      'Band too loose causing cups to stand away from body',
      'Asymmetric breast size (gap on larger side)',
      'Natural breast shape changes during menstrual cycle'
    ],
    solutions: [
      'Try going down a cup size while maintaining band size',
      'Look for cup styles that match breast shape (e.g., balconette for bottom fullness)',
      'Ensure band fits snugly on loosest hook',
      'Consider removable padding for asymmetry',
      'Try different cup styles with stretch lace tops for flexibility'
    ],
    relatedMeasurements: [
      'Standing bust measurement',
      'Leaning bust measurement',
      'Lying bust measurement'
    ],
    recommendedTypes: [
      'Balconette bras',
      'Demi-cup styles',
      'Stretch lace top cups',
      'Cut-and-sew styles',
      'T-shirt bras with molded foam'
    ]
  },
  {
    id: 'spillage',
    category: 'Cup Issues',
    title: 'Spillage Over Cup Top (Quad-boob Effect)',
    causes: [
      'Cup size too small',
      'Cup style too shallow for breast shape',
      'Band too tight pushing breast tissue up',
      'Wrong cup height for breast fullness',
      'Breast tissue migration from previous ill-fitting bras'
    ],
    solutions: [
      'Go up 1-2 cup sizes while keeping band size',
      'Look for deeper cup projections',
      'Ensure proper scoop and swoop technique',
      'Try fuller coverage styles',
      'Reassess size after tissue remigration (usually 2-3 months)'
    ],
    relatedMeasurements: [
      'Standing bust measurement',
      'Leaning bust measurement',
      'Difference between standing and leaning measurements'
    ],
    recommendedTypes: [
      'Full coverage bras',
      'Seamed cup styles',
      'Side support panels',
      'Deep projection styles',
      'Stretch top panel styles'
    ]
  },
  // Band Issues
  {
    id: 'band-riding-up',
    category: 'Band Issues',
    title: 'Band Riding Up in Back',
    causes: [
      'Band too large',
      'Band stretched out from wear',
      'Compensating for cups too small',
      'Improper band position',
      'Uneven weight distribution'
    ],
    solutions: [
      'Try a smaller band size',
      'Use middle or tightest hooks on worn bras',
      'Verify cup size is large enough',
      'Position band parallel to ground',
      'Ensure straps aren\'t over-tightened'
    ],
    relatedMeasurements: [
      'Snug underbust',
      'Tight underbust',
      'Difference between measurements'
    ],
    recommendedTypes: [
      'Firm band construction',
      'Longline styles',
      'Wide band styles',
      'Power mesh bands',
      'Side support styles'
    ]
  },
  {
    id: 'band-too-tight',
    category: 'Band Issues',
    title: 'Band Too Tight or Uncomfortable',
    causes: [
      'Band size too small',
      'Cup size too small forcing breast tissue into band',
      'Rigid band material',
      'Medical conditions affecting ribcage sensitivity',
      'Recent weight changes'
    ],
    solutions: [
      'Try band extender temporarily',
      'Go up one band size (down one cup size)',
      'Look for bands with stretch panels',
      'Consider wireless styles',
      'Try different brand sizing'
    ],
    relatedMeasurements: [
      'Snug underbust',
      'Tight underbust',
      'Breathing difference in measurements'
    ],
    recommendedTypes: [
      'Stretch material bands',
      'Wireless styles',
      'Wider band styles',
      'Cotton-lined bands',
      'Bralette styles'
    ]
  },
  // Wire Issues
  {
    id: 'underwire-on-tissue',
    category: 'Wire Issues',
    title: 'Underwire Sitting on Breast Tissue',
    causes: [
      'Wire too narrow for breast root',
      'Cup size too small',
      'Band size too large',
      'Incorrect wire placement',
      'Breast tissue migration'
    ],
    solutions: [
      'Try wider wire styles',
      'Ensure proper scoop and swoop',
      'Check for correct band tension',
      'Adjust wire position after putting on bra',
      'Consider Polish bra brands for different wire shapes'
    ],
    relatedMeasurements: [
      'Root width assessment',
      'Standing bust',
      'Leaning bust'
    ],
    recommendedTypes: [
      'Wide wire styles',
      'Side support panels',
      'U-shaped wires',
      'Full cup styles',
      'Polish brand bras'
    ]
  },
  {
    id: 'center-gore-floating',
    category: 'Wire Issues',
    title: 'Center Gore Not Lying Flat',
    causes: [
      'Cup size too small',
      'Wire width too narrow',
      'Close-set breasts',
      'Asymmetric breast tissue',
      'Rigid center gore construction'
    ],
    solutions: [
      'Increase cup size',
      'Try plunge styles',
      'Look for overlapping center wires',
      'Consider altering center gore',
      'Try different wire shapes'
    ],
    relatedMeasurements: [
      'Standing bust',
      'Distance between breasts',
      'Wire channel measurements'
    ],
    recommendedTypes: [
      'Plunge styles',
      'Overlap gore styles',
      'Low center gore designs',
      'Soft wire styles',
      'Custom altered gores'
    ]
  },
  // Strap Issues
  {
    id: 'sliding-straps',
    category: 'Strap Issues',
    title: 'Straps Sliding Off Shoulders',
    causes: [
      'Sloped or narrow shoulders',
      'Band too loose',
      'Wide-set straps',
      'Strap material too slippery',
      'Worn out elastic'
    ],
    solutions: [
      'Try racerback or multiway styles',
      'Ensure proper band fit',
      'Look for center-pull straps',
      'Consider strap cushions',
      'Use convertible strap positions'
    ],
    relatedMeasurements: [
      'Shoulder slope',
      'High point shoulder width',
      'Back width'
    ],
    recommendedTypes: [
      'Racerback styles',
      'Multiway convertible',
      'Ballet-back designs',
      'Leotard back styles',
      'U-back designs'
    ]
  },
  {
    id: 'strap-pain',
    category: 'Strap Issues',
    title: 'Strap Digging or Pain',
    causes: [
      'Straps too tight',
      'Band too loose forcing weight to straps',
      'Heavy breast tissue',
      'Narrow strap design',
      'Poor weight distribution'
    ],
    solutions: [
      'Loosen straps gradually',
      'Ensure band provides primary support',
      'Try wider comfort straps',
      'Look for better weight distribution designs',
      'Consider reduction options for medical issues'
    ],
    relatedMeasurements: [
      'Full bust measurement',
      'Band measurement ratio',
      'Strap width needs'
    ],
    recommendedTypes: [
      'Wide strap styles',
      'Cushioned straps',
      'Support panel designs',
      'Multiple strap styles',
      'Built-up shoulder styles'
    ]
  },
  // Additional Cup Issues
  {
    id: 'side-spillage',
    category: 'Additional Cup Issues',
    title: 'Side/Underarm Spillage',
    causes: [
      'Cup size too small for side tissue',
      'Wire height too low on sides',
      'Band size too tight pushing tissue up',
      'Breast tissue migration from previous ill-fitting bras',
      'Natural breast shape with significant side tissue'
    ],
    solutions: [
      'Try larger cup size maintaining same band',
      'Look for styles with higher side wings',
      'Ensure proper scoop and swoop technique to get all tissue in cups',
      'Consider side support panels for tissue containment',
      'Wait for tissue remigration (2-3 months of proper fitting)'
    ],
    relatedMeasurements: [
      'Standing bust at various angles',
      'Side tissue measurement',
      'High point of breast root'
    ],
    recommendedTypes: [
      'Full coverage with side support',
      'High side wing styles',
      'Side smoothing panels',
      'Three-part cup construction',
      'Full cup with power mesh sides'
    ]
  },
  {
    id: 'empty-bottom',
    category: 'Additional Cup Issues',
    title: 'Empty Space at Bottom of Cups',
    causes: [
      'Cup too shallow at wire',
      'Immediate projection mismatch',
      'Wire sitting too low',
      'Cup size too large',
      'Breast shape needs more immediate depth'
    ],
    solutions: [
      'Look for cups with more immediate projection',
      'Try different wire shapes',
      'Consider Polish bra brands known for depth',
      'Verify wire position in IMF',
      'Try different cup construction methods'
    ],
    relatedMeasurements: [
      'Wire root trace',
      'Immediate projection need',
      'Bottom curve measurement'
    ],
    recommendedTypes: [
      'Seamed cup construction',
      'Polish brand bras',
      'Deep bottom cup designs',
      'Three-part cups',
      'Unlined fabric cups'
    ]
  },
  // Additional Wire Issues
  {
    id: 'wire-distortion',
    category: 'Additional Wire Issues',
    title: 'Wire Distortion (Bending/Twisting)',
    causes: [
      'Cup too shallow for projection',
      'Band too tight warping wires',
      'Wire channel too loose',
      'Wire gauge too flexible',
      'Breast weight distribution issues'
    ],
    solutions: [
      'Look for more projected cups',
      'Verify proper band size',
      'Try different wire strengths',
      'Consider brands with better wire channels',
      'Look for reinforced wire channels'
    ],
    relatedMeasurements: [
      'Projection measurement',
      'Wire spring measurement',
      'Root width assessment'
    ],
    recommendedTypes: [
      'Rigid wire channels',
      'Projected seamed cups',
      'Reinforced side panels',
      'Heavy-duty construction',
      'UK/Polish brand engineering'
    ]
  },
  {
    id: 'gore-height',
    category: 'Additional Wire Issues',
    title: 'Gore Height Issues',
    causes: [
      'High gore pressing breast tissue',
      'Low gore allowing tissue escape',
      'Gore width too wide/narrow',
      'Asymmetric tissue affecting tack',
      'Medical conditions requiring specific gore height'
    ],
    solutions: [
      'Try different gore heights',
      'Consider overlapping gore alterations',
      'Look for different gore widths',
      'Try asymmetric cup adjustments',
      'Consider comfort panels on gore'
    ],
    relatedMeasurements: [
      'Gore comfort height',
      'Distance between breasts',
      'Asymmetry assessment'
    ],
    recommendedTypes: [
      'Variable gore heights',
      'Plunge styles',
      'Overlapping gore designs',
      'Comfort-panel gores',
      'Custom altered gores'
    ]
  },
  // Unique Fit Scenarios
  {
    id: 'asymmetry',
    category: 'Unique Fit Scenarios',
    title: 'Asymmetry Management',
    causes: [
      'Natural breast asymmetry',
      'Tissue density differences',
      'Root height differences',
      'Projection differences',
      'Shape differences'
    ],
    solutions: [
      'Fit to larger side',
      'Use removable padding',
      'Consider stretch lace cups',
      'Look for flexible cup materials',
      'Consider custom alterations'
    ],
    relatedMeasurements: [
      'Individual breast measurements',
      'Difference calculations',
      'Root position comparison'
    ],
    recommendedTypes: [
      'Stretch upper cup styles',
      'Cookie pockets',
      'Flexible materials',
      'Custom alteration friendly',
      'Removable padding options'
    ]
  },
  {
    id: 'post-surgical',
    category: 'Unique Fit Scenarios',
    title: 'Post-Surgical Fitting',
    causes: [
      'Recent surgery affecting tissue',
      'Scar tissue sensitivity',
      'Changed tissue distribution',
      'Modified root position',
      'Comfort requirements'
    ],
    solutions: [
      'Consider wireless options initially',
      'Look for soft materials',
      'Try front-closure styles',
      'Consider compression needs',
      'Accommodate healing timeline'
    ],
    relatedMeasurements: [
      'Comfort boundary mapping',
      'Pressure point assessment',
      'Modified root measurements'
    ],
    recommendedTypes: [
      'Front closure styles',
      'Wireless options',
      'Soft material construction',
      'Post-surgical specific',
      'Compression options'
    ]
  },
  // Seasonal/Temporal Changes
  {
    id: 'monthly-changes',
    category: 'Seasonal/Temporal Changes',
    title: 'Monthly Cycle Changes',
    causes: [
      'Hormonal volume changes',
      'Tissue sensitivity',
      'Shape changes',
      'Size fluctuations',
      'Comfort needs'
    ],
    solutions: [
      'Consider stretch cup tops',
      'Have multiple sizes available',
      'Look for flexible materials',
      'Consider adjustable features',
      'Plan for size variations'
    ],
    relatedMeasurements: [
      'Cycle tracking measurements',
      'Volume change assessment',
      'Sensitivity mapping'
    ],
    recommendedTypes: [
      'Stretch lace styles',
      'Flexible materials',
      'Multiple hook positions',
      'Adjustable cups',
      'Comfort-focused designs'
    ]
  },
  {
    id: 'temperature-impact',
    category: 'Seasonal/Temporal Changes',
    title: 'Temperature/Weather Impact',
    causes: [
      'Heat causing band expansion',
      'Moisture affecting fit',
      'Material reactions to temperature',
      'Comfort changes',
      'Activity level changes'
    ],
    solutions: [
      'Consider moisture-wicking materials',
      'Look for breathable fabrics',
      'Try different band materials',
      'Consider seasonal rotation',
      'Adjust care routine'
    ],
    relatedMeasurements: [
      'Comfort temperature range',
      'Moisture impact assessment',
      'Activity level tracking'
    ],
    recommendedTypes: [
      'Moisture-wicking materials',
      'Mesh panel designs',
      'Breathable fabrics',
      'Sport material options',
      'Lightweight constructions'
    ]
  }
];

export default fitIssues;
export { fitIssues };
