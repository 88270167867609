import { BraSize } from './sizingSystems';

// Define the sizing system type
export type SizingSystem = 'US' | 'UK' | 'EU' | 'FR_ES' | 'IT' | 'JP' | 'AU_NZ';

// Define interfaces for mappings
export interface BandSizeMapping {
  US: number;
  UK: number;
  EU: number;
  FR_ES: number;
  IT: number;
  JP: number;
  AU_NZ: number;
}

export interface CupSizeMapping {
  US: string;
  UK: string;
  EU: string;
  FR_ES: string;
  IT: string;
  JP: string;
  AU_NZ: string;
}

// Define the band size mappings
export const bandSizeMappings: BandSizeMapping[] = [
  { US: 28, UK: 28, EU: 60, FR_ES: 75, IT: 0, JP: 65, AU_NZ: 6 },
  { US: 30, UK: 30, EU: 65, FR_ES: 80, IT: 1, JP: 70, AU_NZ: 8 },
  { US: 32, UK: 32, EU: 70, FR_ES: 85, IT: 2, JP: 75, AU_NZ: 10 },
  { US: 34, UK: 34, EU: 75, FR_ES: 90, IT: 3, JP: 80, AU_NZ: 12 },
  { US: 36, UK: 36, EU: 80, FR_ES: 95, IT: 4, JP: 85, AU_NZ: 14 },
  { US: 38, UK: 38, EU: 85, FR_ES: 100, IT: 5, JP: 90, AU_NZ: 16 },
  { US: 40, UK: 40, EU: 90, FR_ES: 105, IT: 6, JP: 95, AU_NZ: 18 },
  { US: 42, UK: 42, EU: 95, FR_ES: 110, IT: 7, JP: 100, AU_NZ: 20 },
  { US: 44, UK: 44, EU: 100, FR_ES: 115, IT: 8, JP: 105, AU_NZ: 22 },
  { US: 46, UK: 46, EU: 105, FR_ES: 120, IT: 9, JP: 110, AU_NZ: 24 },
  { US: 48, UK: 48, EU: 110, FR_ES: 125, IT: 10, JP: 115, AU_NZ: 26 },
  { US: 50, UK: 50, EU: 115, FR_ES: 130, IT: 11, JP: 120, AU_NZ: 28 },
  { US: 52, UK: 52, EU: 120, FR_ES: 135, IT: 12, JP: 125, AU_NZ: 30 },
  { US: 54, UK: 54, EU: 125, FR_ES: 140, IT: 13, JP: 130, AU_NZ: 32 },
  { US: 56, UK: 56, EU: 130, FR_ES: 145, IT: 14, JP: 135, AU_NZ: 34 },
  { US: 58, UK: 58, EU: 135, FR_ES: 150, IT: 15, JP: 140, AU_NZ: 36 },
  { US: 60, UK: 60, EU: 140, FR_ES: 155, IT: 16, JP: 145, AU_NZ: 38 }
];

// Define the cup size mappings
export const cupSizeMappings: CupSizeMapping[] = [
  { US: 'AA', UK: 'AA', EU: 'AA', FR_ES: 'AA', IT: 'AA', JP: 'AA', AU_NZ: 'AA' },
  { US: 'A', UK: 'A', EU: 'A', FR_ES: 'A', IT: 'A', JP: 'A', AU_NZ: 'A' },
  { US: 'B', UK: 'B', EU: 'B', FR_ES: 'B', IT: 'B', JP: 'B', AU_NZ: 'B' },
  { US: 'C', UK: 'C', EU: 'C', FR_ES: 'C', IT: 'C', JP: 'C', AU_NZ: 'C' },
  { US: 'D', UK: 'D', EU: 'D', FR_ES: 'D', IT: 'D', JP: 'D', AU_NZ: 'D' },
  { US: 'DD/E', UK: 'DD', EU: 'E', FR_ES: 'E', IT: 'E', JP: 'E', AU_NZ: 'DD' },
  { US: 'DDD/F', UK: 'E', EU: 'F', FR_ES: 'F', IT: 'F', JP: 'F', AU_NZ: 'E' },
  { US: 'G', UK: 'F', EU: 'G', FR_ES: 'G', IT: 'G', JP: 'G', AU_NZ: 'F' },
  { US: 'H', UK: 'FF', EU: 'H', FR_ES: 'H', IT: 'H', JP: 'H', AU_NZ: 'FF' },
  { US: 'I', UK: 'G', EU: 'I', FR_ES: 'I', IT: 'I', JP: 'I', AU_NZ: 'G' },
  { US: 'J', UK: 'GG', EU: 'J', FR_ES: 'J', IT: 'J', JP: 'J', AU_NZ: 'GG' },
  { US: 'K', UK: 'H', EU: 'K', FR_ES: 'K', IT: 'K', JP: 'K', AU_NZ: 'H' }
];

// Helper functions for size conversion
export function convertBandSize(size: number, fromSystem: SizingSystem, toSystem: SizingSystem): number | null {
  const range = bandSizeRanges.find(r => {
    switch (fromSystem) {
      case 'US': return r.US === size;
      case 'UK': return r.UK === size;
      case 'EU': return r.EU === size;
      case 'FR_ES': return r.FR_ES === size;
      case 'IT': return r.IT === size;
      case 'AU_NZ': return r.AU_NZ === size;
      case 'JP': return r.JP === size;
      default: return false;
    }
  });

  if (!range) return null;

  switch (toSystem) {
    case 'US': return range.US;
    case 'UK': return range.UK;
    case 'EU': return range.EU;
    case 'FR_ES': return range.FR_ES;
    case 'IT': return range.IT;
    case 'AU_NZ': return range.AU_NZ;
    case 'JP': return range.JP;
    default: return null;
  }
}

// Convert to/from UK dress size
export function convertToUKDress(size: number, fromSystem: SizingSystem): number | null {
  const range = bandSizeRanges.find(r => r[fromSystem] === size);
  return range ? range.UKDress : null;
}

export function convertFromUKDress(dressSize: number, toSystem: SizingSystem): number | null {
  const range = bandSizeRanges.find(r => r.UKDress === dressSize);
  return range ? range[toSystem] : null;
}

// Get all valid band sizes for a system
export function getValidBandSizes(system: SizingSystem): number[] {
  return bandSizeMappings.map(mapping => mapping[system]);
}

// Get all valid cup sizes for a system
export function getValidCupSizes(system: SizingSystem): string[] {
  return cupSizeMappings.map(mapping => mapping[system]);
}

// Calculate sister sizes
export function calculateSisterSizes(bandSize: number, cupSize: string, system: SizingSystem): Array<{
  bandSize: number;
  cupSize: string;
  type: 'smaller' | 'current' | 'larger';
}> {
  // Convert to US sizing for calculations
  const usBand = system === 'US' ? bandSize : convertBandSize(bandSize, system, 'US');
  const usCup = system === 'US' ? cupSize : convertCupSize(cupSize, system, 'US');
  if (!usBand || !usCup) return [];

  const usCupIndex = cupSizeMappings.findIndex(m => m.US === usCup);
  if (usCupIndex === -1) return [];

  const sisterSizes: Array<{
    bandSize: number;
    cupSize: string;
    type: 'smaller' | 'current' | 'larger';
  }> = [];
  const bandSizeDiffs = [-2, 0, 2]; // Smaller, Current, Larger

  for (let i = 0; i < bandSizeDiffs.length; i++) {
    const newUsBand = usBand + bandSizeDiffs[i];
    const newCupIndex = usCupIndex - bandSizeDiffs[i] / 2;

    // Skip if outside valid ranges
    if (newUsBand < 28 || newUsBand > 60) continue;
    if (newCupIndex < 0 || newCupIndex >= cupSizeMappings.length) continue;

    // Convert back to target system
    const targetBand = system === 'US' ? newUsBand : convertBandSize(newUsBand, 'US', system);
    const targetCup = system === 'US' ? cupSizeMappings[Math.floor(newCupIndex)].US : 
                     convertCupSize(cupSizeMappings[Math.floor(newCupIndex)].US, 'US', system);

    if (targetBand && targetCup) {
      sisterSizes.push({
        bandSize: targetBand,
        cupSize: targetCup,
        type: i === 0 ? 'smaller' : i === 1 ? 'current' : 'larger'
      });
    }
  }

  return sisterSizes;
}

export type SizingData = {
  cupSizes: Record<SizingSystem, string[]>;
  validBandSizes: Record<SizingSystem, number[]>;
};

export const sizingData: SizingData = {
  cupSizes: {
    US: ['AA', 'A', 'B', 'C', 'D', 'DD/E', 'DDD/F', 'G', 'H', 'I', 'J', 'K'],
    UK: ['AA', 'A', 'B', 'C', 'D', 'DD', 'E', 'F', 'FF', 'G', 'GG', 'H'],
    EU: ['AA', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'],
    FR_ES: ['AA', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'],
    IT: ['AA', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'],
    JP: ['AA', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'],
    AU_NZ: ['AA', 'A', 'B', 'C', 'D', 'DD', 'E', 'F', 'FF', 'G', 'GG', 'H']
  },
  validBandSizes: {
    US: [28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60],
    UK: [28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60],
    EU: [60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140],
    FR_ES: [75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155],
    IT: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    JP: [65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145],
    AU_NZ: [6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38]
  }
};

// Export cupSizes directly for easier access
export const cupSizes = sizingData.cupSizes;

export const bandConversions = {
  // European Union standard
  US_TO_EU: 10,     // US 34 → EU 44 (Add 10)
                    // This is correct because EU sizing is based on measuring in cm and subtracting 50
  
  // France and Spain
  US_TO_FR_ES: 15,  // US 34 → FR/ES 49 (Add 15)
                    // This is correct because FR/ES sizing is based on measuring in cm and subtracting 35
  
  // Italy
  US_TO_IT: 0,      // Italian sizes are numbered 1-16
  
  // Japan
  US_TO_JP: 5,      // US 34 → JP 39 (Add 5)
                    // Japanese sizing is based on measuring in cm and subtracting 45
  
  // Australia/New Zealand
  US_TO_AU_NZ: -22  // US 34 → AU/NZ 12 (Subtract 22)
                    // AU/NZ uses dress sizes
};

export interface BandSizeRange {
  cmRange: [number, number];
  inchRange: [number, number];
  EU: number;
  FR_ES: number;
  IT: number;
  US: number;
  UK: number;
  AU_NZ: number;
  JP: number;
  UKDress: number;
}

export const bandSizeRanges: BandSizeRange[] = [
  { cmRange: [63, 67], inchRange: [24, 25], EU: 60, FR_ES: 75, IT: 1, US: 28, UK: 28, AU_NZ: 6, JP: 60, UKDress: 4 },
  { cmRange: [68, 72], inchRange: [26, 27], EU: 65, FR_ES: 80, IT: 2, US: 30, UK: 30, AU_NZ: 8, JP: 65, UKDress: 6 },
  { cmRange: [73, 77], inchRange: [28, 29], EU: 70, FR_ES: 85, IT: 3, US: 32, UK: 32, AU_NZ: 10, JP: 70, UKDress: 8 },
  { cmRange: [78, 82], inchRange: [30, 31], EU: 75, FR_ES: 90, IT: 4, US: 34, UK: 34, AU_NZ: 12, JP: 75, UKDress: 10 },
  { cmRange: [83, 87], inchRange: [32, 33], EU: 80, FR_ES: 95, IT: 5, US: 36, UK: 36, AU_NZ: 14, JP: 80, UKDress: 12 },
  { cmRange: [88, 92], inchRange: [34, 35], EU: 85, FR_ES: 100, IT: 6, US: 38, UK: 38, AU_NZ: 16, JP: 85, UKDress: 14 },
  { cmRange: [93, 97], inchRange: [36, 37], EU: 90, FR_ES: 105, IT: 7, US: 40, UK: 40, AU_NZ: 18, JP: 90, UKDress: 16 },
  { cmRange: [98, 102], inchRange: [38, 39], EU: 95, FR_ES: 110, IT: 8, US: 42, UK: 42, AU_NZ: 20, JP: 95, UKDress: 18 },
  { cmRange: [103, 107], inchRange: [40, 41], EU: 100, FR_ES: 115, IT: 9, US: 44, UK: 44, AU_NZ: 22, JP: 100, UKDress: 20 },
  { cmRange: [108, 112], inchRange: [42, 43], EU: 105, FR_ES: 120, IT: 10, US: 46, UK: 46, AU_NZ: 24, JP: 105, UKDress: 22 },
  { cmRange: [113, 117], inchRange: [44, 45], EU: 110, FR_ES: 125, IT: 11, US: 48, UK: 48, AU_NZ: 26, JP: 110, UKDress: 24 },
  { cmRange: [118, 122], inchRange: [46, 47], EU: 115, FR_ES: 130, IT: 12, US: 50, UK: 50, AU_NZ: 28, JP: 115, UKDress: 26 },
  { cmRange: [123, 127], inchRange: [48, 49], EU: 120, FR_ES: 135, IT: 13, US: 52, UK: 52, AU_NZ: 30, JP: 120, UKDress: 28 },
  { cmRange: [128, 132], inchRange: [50, 51], EU: 125, FR_ES: 140, IT: 14, US: 54, UK: 54, AU_NZ: 32, JP: 125, UKDress: 30 },
  { cmRange: [133, 137], inchRange: [52, 53], EU: 130, FR_ES: 145, IT: 15, US: 56, UK: 56, AU_NZ: 34, JP: 130, UKDress: 32 },
  { cmRange: [138, 142], inchRange: [54, 55], EU: 135, FR_ES: 150, IT: 16, US: 58, UK: 58, AU_NZ: 36, JP: 135, UKDress: 34 },
  { cmRange: [143, 147], inchRange: [56, 57], EU: 140, FR_ES: 155, IT: 17, US: 60, UK: 60, AU_NZ: 38, JP: 140, UKDress: 36 }
];

export function findBandSize(measurement: number, unit: 'cm' | 'inch'): BandSizeRange | null {
  const ranges = bandSizeRanges.find(range => {
    const [min, max] = unit === 'cm' ? range.cmRange : range.inchRange;
    return measurement >= min && measurement <= max;
  });
  return ranges || null;
}

export function convertCupSize(cupSize: string, fromSystem: SizingSystem, toSystem: SizingSystem): string | null {
  const mapping = cupSizeMappings.find(m => m[fromSystem] === cupSize);
  return mapping ? mapping[toSystem] : null;
}

export interface BrandInfo {
  bandDiff: number;
  cupMapping: 'standard' | 'full' | 'small' | 'sports';
  region: string;
}

export type BrandName = keyof typeof brandSizingData;

export const brandSizingData = {
  // US Premium Brands
  "Victoria's Secret": { bandDiff: 0, cupMapping: 'standard', region: 'US' },
  "Calvin Klein": { bandDiff: 0, cupMapping: 'standard', region: 'US' },
  "Natori": { bandDiff: 0, cupMapping: 'standard', region: 'US' },
  "CUUP": { bandDiff: 0, cupMapping: 'standard', region: 'US' },
  "ThirdLove": { bandDiff: 0, cupMapping: 'standard', region: 'US' },
  "Wacoal": { bandDiff: 0, cupMapping: 'full', region: 'US' },
  "Soma": { bandDiff: 0, cupMapping: 'standard', region: 'US' },
  "Savage X Fenty": { bandDiff: 0, cupMapping: 'standard', region: 'US' },
  "La Perla US": { bandDiff: 0, cupMapping: 'standard', region: 'US' },
  "Chantelle US": { bandDiff: 0, cupMapping: 'standard', region: 'US' },
  "b.tempt'd": { bandDiff: 0, cupMapping: 'standard', region: 'US' },
  "Journelle": { bandDiff: 0, cupMapping: 'standard', region: 'US' },
  "Cosabella US": { bandDiff: 0, cupMapping: 'standard', region: 'US' },
  "Le Mystère": { bandDiff: 0, cupMapping: 'standard', region: 'US' },
  "Simone Perele US": { bandDiff: 0, cupMapping: 'standard', region: 'US' },
  "Spanx Bras": { bandDiff: 0, cupMapping: 'standard', region: 'US' },

  // US Specialty Size Brands
  "Pepper": { bandDiff: 0, cupMapping: 'small', region: 'US' },
  "Lane Bryant": { bandDiff: 0, cupMapping: 'full', region: 'US' },
  "Glamorise": { bandDiff: 0, cupMapping: 'full', region: 'US' },
  "Torrid": { bandDiff: 0, cupMapping: 'full', region: 'US' },
  "Elomi": { bandDiff: 0, cupMapping: 'full', region: 'US' },
  "Goddess": { bandDiff: 0, cupMapping: 'full', region: 'US' },
  "Parfait": { bandDiff: 0, cupMapping: 'full', region: 'US' },
  "Leading Lady": { bandDiff: 0, cupMapping: 'full', region: 'US' },
  "Elila": { bandDiff: 0, cupMapping: 'full', region: 'US' },
  "Aviana": { bandDiff: 0, cupMapping: 'full', region: 'US' },
  "Curvy Couture": { bandDiff: 0, cupMapping: 'full', region: 'US' },

  // US Mass Market
  "Aerie": { bandDiff: 0, cupMapping: 'standard', region: 'US' },
  "Hanes": { bandDiff: 0, cupMapping: 'standard', region: 'US' },
  "Fruit of the Loom": { bandDiff: 0, cupMapping: 'standard', region: 'US' },
  "Playtex": { bandDiff: 0, cupMapping: 'full', region: 'US' },
  "Maidenform": { bandDiff: 0, cupMapping: 'standard', region: 'US' },
  "Warner's": { bandDiff: 0, cupMapping: 'standard', region: 'US' },
  "Bali": { bandDiff: 0, cupMapping: 'full', region: 'US' },
  "Jockey": { bandDiff: 0, cupMapping: 'standard', region: 'US' },
  "Vassarette": { bandDiff: 0, cupMapping: 'standard', region: 'US' },
  "Just My Size": { bandDiff: 0, cupMapping: 'full', region: 'US' },
  "Danskin": { bandDiff: 0, cupMapping: 'standard', region: 'US' },
  "Smart & Sexy": { bandDiff: 0, cupMapping: 'standard', region: 'US' },
  "Vanity Fair": { bandDiff: 0, cupMapping: 'standard', region: 'US' },

  // Sports/Athleisure
  "Nike": { bandDiff: 0, cupMapping: 'sports', region: 'US' },
  "Adidas": { bandDiff: 0, cupMapping: 'sports', region: 'US' },
  "Under Armour": { bandDiff: 0, cupMapping: 'sports', region: 'US' },
  "Lululemon": { bandDiff: 0, cupMapping: 'sports', region: 'US' },
  "Champion": { bandDiff: 0, cupMapping: 'sports', region: 'US' },
  "Athleta": { bandDiff: 0, cupMapping: 'sports', region: 'US' },
  "Brooks": { bandDiff: 0, cupMapping: 'sports', region: 'US' },
  "Reebok": { bandDiff: 0, cupMapping: 'sports', region: 'US' },
  "New Balance": { bandDiff: 0, cupMapping: 'sports', region: 'US' },
  "Puma": { bandDiff: 0, cupMapping: 'sports', region: 'US' },
  "Fabletics": { bandDiff: 0, cupMapping: 'sports', region: 'US' },
  "SheFit": { bandDiff: 0, cupMapping: 'sports', region: 'US' },

  // UK Brands
  "Marks & Spencer": { bandDiff: 0, cupMapping: 'standard', region: 'UK' },
  "Bravissimo": { bandDiff: 0, cupMapping: 'full', region: 'UK' },
  "Freya": { bandDiff: 0, cupMapping: 'full', region: 'UK' },
  "Panache": { bandDiff: 0, cupMapping: 'full', region: 'UK' },
  "Curvy Kate": { bandDiff: 0, cupMapping: 'full', region: 'UK' },
  "Fantasie": { bandDiff: 0, cupMapping: 'full', region: 'UK' },
  "Tutti Rouge": { bandDiff: 0, cupMapping: 'full', region: 'UK' },
  "Gossard": { bandDiff: 0, cupMapping: 'standard', region: 'UK' },
  "Pour Moi": { bandDiff: 0, cupMapping: 'standard', region: 'UK' },
  "Bluebella": { bandDiff: 0, cupMapping: 'standard', region: 'UK' },
  "Ultimo": { bandDiff: 0, cupMapping: 'standard', region: 'UK' },
  "Lepel": { bandDiff: 0, cupMapping: 'standard', region: 'UK' },
  "Shock Absorber": { bandDiff: 0, cupMapping: 'sports', region: 'UK' },
  "Ann Summers": { bandDiff: 0, cupMapping: 'standard', region: 'UK' },
  "Figleaves": { bandDiff: 0, cupMapping: 'standard', region: 'UK' },
  "Simply Be": { bandDiff: 0, cupMapping: 'full', region: 'UK' },

  // French Brands
  "Chantelle": { bandDiff: 15, cupMapping: 'standard', region: 'FR' },
  "Simone Perele": { bandDiff: 15, cupMapping: 'standard', region: 'FR' },
  "Aubade": { bandDiff: 15, cupMapping: 'standard', region: 'FR' },
  "Maison Lejaby": { bandDiff: 15, cupMapping: 'standard', region: 'FR' },
  "Empreinte": { bandDiff: 15, cupMapping: 'full', region: 'FR' },
  "Etam": { bandDiff: 15, cupMapping: 'standard', region: 'FR' },
  "Princess Tam Tam": { bandDiff: 15, cupMapping: 'standard', region: 'FR' },
  "Undiz": { bandDiff: 15, cupMapping: 'standard', region: 'FR' },
  "Darjeeling": { bandDiff: 15, cupMapping: 'standard', region: 'FR' },
  "Lou Paris": { bandDiff: 15, cupMapping: 'standard', region: 'FR' },
  "Passionata": { bandDiff: 15, cupMapping: 'standard', region: 'FR' },

  // Italian Brands
  "La Perla": { bandDiff: 0, cupMapping: 'standard', region: 'IT' },
  "Intimissimi": { bandDiff: 0, cupMapping: 'standard', region: 'IT' },
  "Cosabella": { bandDiff: 0, cupMapping: 'standard', region: 'IT' },
  "Yamamay": { bandDiff: 0, cupMapping: 'standard', region: 'IT' },
  "Calzedonia": { bandDiff: 0, cupMapping: 'standard', region: 'IT' },
  "Tezenis": { bandDiff: 0, cupMapping: 'standard', region: 'IT' },
  "Lovable": { bandDiff: 0, cupMapping: 'standard', region: 'IT' },

  // German Brands
  "Triumph": { bandDiff: 10, cupMapping: 'standard', region: 'DE' },
  "Anita": { bandDiff: 10, cupMapping: 'full', region: 'DE' },
  "Felina": { bandDiff: 10, cupMapping: 'standard', region: 'DE' },
  "Marie Jo": { bandDiff: 10, cupMapping: 'standard', region: 'DE' },
  "Prima Donna": { bandDiff: 10, cupMapping: 'full', region: 'DE' },
  "Esprit Bodywear": { bandDiff: 10, cupMapping: 'standard', region: 'DE' },
  "Schiesser": { bandDiff: 10, cupMapping: 'standard', region: 'DE' },
  "Mey": { bandDiff: 10, cupMapping: 'standard', region: 'DE' },
  "Rosa Faia": { bandDiff: 10, cupMapping: 'full', region: 'DE' },
  "Naturana": { bandDiff: 10, cupMapping: 'standard', region: 'DE' },
  "Sloggi": { bandDiff: 10, cupMapping: 'standard', region: 'DE' },
  "Skiny": { bandDiff: 10, cupMapping: 'standard', region: 'DE' },
  "Marc O'Polo Body & Beach": { bandDiff: 10, cupMapping: 'standard', region: 'DE' },
  "Lascana": { bandDiff: 10, cupMapping: 'standard', region: 'DE' },
  "Palmers": { bandDiff: 10, cupMapping: 'standard', region: 'DE' },
  "Calida": { bandDiff: 10, cupMapping: 'standard', region: 'DE' },

  // Dutch Brands
  "Hunkemöller": { bandDiff: 10, cupMapping: 'standard', region: 'NL' },
  "Marlies Dekkers": { bandDiff: 10, cupMapping: 'standard', region: 'NL' },
  "LOVE Stories": { bandDiff: 10, cupMapping: 'standard', region: 'NL' },
  "Sapph": { bandDiff: 10, cupMapping: 'standard', region: 'NL' },
  "Livera": { bandDiff: 10, cupMapping: 'standard', region: 'NL' },
  "Lincherie": { bandDiff: 10, cupMapping: 'standard', region: 'NL' },
  "Van de Velde": { bandDiff: 10, cupMapping: 'standard', region: 'NL' },
  "Pastunette": { bandDiff: 10, cupMapping: 'standard', region: 'NL' },
  "Ten Cate": { bandDiff: 10, cupMapping: 'standard', region: 'NL' },
  "LingaDore": { bandDiff: 10, cupMapping: 'standard', region: 'NL' },
  "Muchachomalo": { bandDiff: 10, cupMapping: 'standard', region: 'NL' },
  "Wolford NL": { bandDiff: 10, cupMapping: 'standard', region: 'NL' },
  "Skipper Lingerie": { bandDiff: 10, cupMapping: 'standard', region: 'NL' },
  "YES!Lingerie": { bandDiff: 10, cupMapping: 'standard', region: 'NL' },

  // Spanish Brands
  "Women'secret": { bandDiff: 15, cupMapping: 'standard', region: 'ES' },
  "Oysho": { bandDiff: 15, cupMapping: 'standard', region: 'ES' },
  "TCN": { bandDiff: 15, cupMapping: 'standard', region: 'ES' },
  "Andres Sarda": { bandDiff: 15, cupMapping: 'standard', region: 'ES' },
  "Promise": { bandDiff: 15, cupMapping: 'standard', region: 'ES' },

  // Australian Brands
  "Bendon": { bandDiff: 0, cupMapping: 'standard', region: 'AU' },
  "Berlei": { bandDiff: 0, cupMapping: 'standard', region: 'AU' },
  "Bonds": { bandDiff: 0, cupMapping: 'standard', region: 'AU' },
  "Cotton On Body": { bandDiff: 0, cupMapping: 'standard', region: 'AU' },
  "Honey Birdette": { bandDiff: 0, cupMapping: 'standard', region: 'AU' },
  "Bras N Things": { bandDiff: 0, cupMapping: 'standard', region: 'AU' },
  "Nancy Ganz": { bandDiff: 0, cupMapping: 'full', region: 'AU' },
  "Pleasure State": { bandDiff: 0, cupMapping: 'standard', region: 'AU' },
  "Fine Lines": { bandDiff: 0, cupMapping: 'standard', region: 'AU' },

  // Japanese Brands
  "Wacoal Japan": { bandDiff: 5, cupMapping: 'small', region: 'JP' },
  "Uniqlo": { bandDiff: 5, cupMapping: 'small', region: 'JP' },

  // Fast Fashion
  "H&M": { bandDiff: 10, cupMapping: 'standard', region: 'EU' },
  "Zara": { bandDiff: 15, cupMapping: 'standard', region: 'EU' },
  "ASOS": { bandDiff: 0, cupMapping: 'standard', region: 'UK' },
  "Primark": { bandDiff: 0, cupMapping: 'standard', region: 'UK' },

  // Luxury
  "Agent Provocateur": { bandDiff: 0, cupMapping: 'standard', region: 'UK' },
  "Fleur du Mal": { bandDiff: 0, cupMapping: 'standard', region: 'US' },
  "I.D. Sarrieri": { bandDiff: 15, cupMapping: 'standard', region: 'EU' },

  // Sustainable/Eco-Friendly
  "Organic Basics": { bandDiff: 10, cupMapping: 'standard', region: 'EU' },
  "Base Range": { bandDiff: 10, cupMapping: 'standard', region: 'EU' },
  "Arq": { bandDiff: 0, cupMapping: 'standard', region: 'US' }
} as const;
