import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { cupSizes, bandSizes, SizingSystem, sizeConversions } from '../data/sizingConverterData';

const QuickConverter: React.FC = () => {
  const [currentSize, setCurrentSize] = useState({
    band: '',
    cup: '',
    system: 'US' as SizingSystem
  });

  const convertSize = (size: { band: string; cup: string; system: SizingSystem }) => {
    if (!size.band || !size.cup) return null;
    
    const bandSize = parseInt(size.band);
    if (isNaN(bandSize)) return null;

    // Find the corresponding size conversion
    const conversion = sizeConversions.find(conv => conv[size.system] === bandSize);
    if (!conversion) return null;

    // Find cup size index
    const cupIndex = cupSizes[size.system].indexOf(size.cup);
    if (cupIndex === -1) return null;

    // Create conversions for all systems
    return Object.entries(conversion).reduce((acc, [system, band]) => {
      if (system === 'JP' && cupIndex > cupSizes.JP.length - 1) {
        acc[system] = 'N/A'; // JP sizes don't go as high as other systems
      } else {
        acc[system] = `${band}${cupSizes[system as SizingSystem][cupIndex]}`;
      }
      return acc;
    }, {} as Record<string, string>);
  };

  const conversions = currentSize.band && currentSize.cup ? convertSize(currentSize) : null;

  const getSystemDisplayName = (system: string): string => {
    switch (system) {
      case 'US': return 'US Size';
      case 'UK': return 'UK Size';
      case 'EU': return 'EU Size';
      case 'FR_ES': return 'FR/ES Size';
      case 'IT': return 'IT Size';
      case 'AU_NZ': return 'AU/NZ Size';
      case 'JP': return 'JP Size';
      default: return system;
    }
  };

  return (
    <div className="bg-white rounded-xl border border-gray-100 p-8 max-w-5xl mx-auto">
      <div className="text-center mb-8">
        <h2 className="text-2xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
          Quick Bra Size Converter
        </h2>
        <p className="text-gray-600">
          Convert your bra size between different international sizing systems
        </p>
      </div>
      
      <div className="grid md:grid-cols-3 gap-4 mb-8">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Size System
          </label>
          <select
            className="w-full p-3 border rounded-lg bg-white hover:border-purple-500 focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-colors"
            value={currentSize.system}
            onChange={(e) => setCurrentSize(prev => ({ ...prev, system: e.target.value as SizingSystem }))}
          >
            {Object.keys(bandSizes).map((system) => (
              <option key={system} value={system}>
                {getSystemDisplayName(system)}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Band Size
          </label>
          <select
            className="w-full p-3 border rounded-lg bg-white hover:border-purple-500 focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-colors"
            value={currentSize.band}
            onChange={(e) => setCurrentSize(prev => ({ ...prev, band: e.target.value }))}
          >
            <option value="">Select Band</option>
            {bandSizes[currentSize.system].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Cup Size
          </label>
          <select
            className="w-full p-3 border rounded-lg bg-white hover:border-purple-500 focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-colors"
            value={currentSize.cup}
            onChange={(e) => setCurrentSize(prev => ({ ...prev, cup: e.target.value }))}
          >
            <option value="">Select Cup</option>
            {cupSizes[currentSize.system].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
      </div>

      {conversions ? (
        <div className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-lg p-6">
          <h3 className="font-semibold mb-4 text-lg">Size Conversions</h3>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {Object.entries(conversions).map(([system, size]) => (
              <div key={system} className="bg-white rounded-lg p-4 shadow-sm">
                <div className="text-gray-800 mb-1">{getSystemDisplayName(system)}</div>
                <div className="text-xl text-purple-600 font-bold">{size}</div>
              </div>
            ))}
          </div>
        </div>
      ) : currentSize.band || currentSize.cup ? (
        <div className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-lg p-6">
          <div className="text-center text-gray-500">
            <p>Please select both band and cup size to see conversions</p>
          </div>
        </div>
      ) : (
        <div className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-lg p-6">
          <div className="text-center text-gray-500">
            <svg className="w-12 h-12 mx-auto mb-4 text-gray-400" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
            </svg>
            <p>Enter your current size to see conversions</p>
          </div>
        </div>
      )}

      <div className="mt-6 text-center">
        <Link
          to="/bra-size-converter"
          className="text-purple-600 hover:text-purple-700 font-medium"
        >
          Need more options? Try our full Bra Size Converter →
        </Link>
      </div>
    </div>
  );
};

export default QuickConverter;
