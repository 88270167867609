import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-50 border-t">
      <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* About Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4">BraSizeCalculatorPro</h3>
            <p className="text-gray-600">
              Helping you find your perfect bra size with accurate measurements and expert guidance.
            </p>
          </div>
          
          {/* Quick Links Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/" className="text-gray-600 hover:text-purple-500">
                  Bra Size Calculator
                </Link>
              </li>
              <li>
                <Link to="/bra-size-converter" className="text-gray-600 hover:text-purple-500">
                Bra Size Converter
                </Link>
              </li>
              <li>
                <Link to="/bra-sister-sizes" className="text-gray-600 hover:text-purple-500">
                  Sister Size Calculator
                </Link>
              </li>
              <li>
                <Link to="/bra-size-charts" className="text-gray-600 hover:text-purple-500">
                Bra Size Charts
                </Link>
              </li>
            </ul>
          </div>

          {/* Resources Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Resources</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/bra-size-measuring-guide" className="text-gray-600 hover:text-purple-500">
                Bra Measuring Guide
                </Link>
              </li>
              <li>
                <Link to="/bra-fitting-guide" className="text-gray-600 hover:text-purple-500">
                  Bra Fitting Guide
                </Link>
              </li>
              <li>
                <Link to="/bra-types-guide" className="text-gray-600 hover:text-purple-500">
                  Bra Types Guide
                </Link>
              </li>
              <li>
                <Link to="/breast-shape-guide" className="text-gray-600 hover:text-purple-500">
                  Breast Shape Guide
                </Link>
              </li>
              <li>
                <Link to="/bra-fit-issues" className="text-gray-600 hover:text-purple-500">
                  Bra Fit Issues Guide
                </Link>
              </li>
            </ul>
          </div>

          {/* Legal Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Legal</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/privacy-policy" className="text-gray-600 hover:text-purple-500">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/terms-of-service" className="text-gray-600 hover:text-purple-500">
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link to="/cookie-policy" className="text-gray-600 hover:text-purple-500">
                  Cookie Policy
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-gray-600 hover:text-purple-500">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="mt-8 pt-8 border-t border-gray-200">
          <p className="text-center text-gray-400 text-sm">
            {new Date().getFullYear()} Bra Size Calculator Pro. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
