import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import {
  bandSizes,
  cupSizes,
  convertBraSize,
  SizingSystem,
  getSystemDisplayName
} from '../data/sizingConverterData';

interface ConversionResult {
  system: SizingSystem;
  bandSize: number | null;
  cupSize: string | null;
}

const NewConverterPage: React.FC = () => {
  const [selectedSystem, setSelectedSystem] = useState<SizingSystem>('US');
  const [bandSize, setBandSize] = useState<string>('');
  const [cupSize, setCupSize] = useState<string>('');
  const [conversions, setConversions] = useState<ConversionResult[]>([]);

  // Available sizing systems
  const sizingSystems: SizingSystem[] = ['US', 'UK', 'EU', 'FR_ES', 'IT', 'JP', 'AU_NZ'];

  // Update conversions when input changes
  useEffect(() => {
    if (bandSize && cupSize) {
      const results: ConversionResult[] = sizingSystems
        .filter(system => system !== selectedSystem)
        .map(system => {
          const result = convertBraSize(Number(bandSize), cupSize, selectedSystem, system);
          return {
            system,
            bandSize: result.bandSize,
            cupSize: result.cupSize
          };
        });
      setConversions(results);
    } else {
      setConversions([]);
    }
  }, [selectedSystem, bandSize, cupSize]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white py-12">
      <Helmet>
        <title>Bra Size Converter | International Bra Size Calculator</title>
        <meta
          name="description"
          content="Convert your bra size between different international sizing systems including US, UK, EU, FR/ES, IT, JP, and AU/NZ sizes."
        />
      </Helmet>

      <div className="container mx-auto px-4 py-8">
        {/* Hero Section */}
        <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
              International Bra Size Converter
            </h1>
            <p className="text-gray-600 text-lg">
            Convert your bra size across international sizing systems to find your perfect fit anywhere in the world and match with popular lingerie brands!
            </p>
          </div>
        <div className="max-w-6xl mx-auto">
          {/* Main Converter Section */}
          <div className="bg-white rounded-xl shadow-lg p-8 mb-8">
            <div className="grid md:grid-cols-2 gap-8">
              {/* Input Section - Left Side */}
              <div>
                <div className="space-y-6">
                  <div>
                    <label className="block text-gray-700 text-sm font-medium mb-2">
                      Size System
                    </label>
                    <select
                      className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                      value={selectedSystem}
                      onChange={(e) => setSelectedSystem(e.target.value as SizingSystem)}
                    >
                      <option value="">Select Size System</option>
                      {sizingSystems.map((system) => (
                        <option key={system} value={system}>
                          {getSystemDisplayName(system)}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label className="block text-gray-700 text-sm font-medium mb-2">
                      Band & Cup Size
                    </label>
                    <div className="grid grid-cols-2 gap-4">
                      <select
                        className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                        value={bandSize}
                        onChange={(e) => setBandSize(e.target.value)}
                      >
                        <option value="">Band Size</option>
                        {bandSizes[selectedSystem].map((size) => (
                          <option key={size} value={size}>
                            {size}
                          </option>
                        ))}
                      </select>
                      <select
                        className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                        value={cupSize}
                        onChange={(e) => setCupSize(e.target.value)}
                      >
                        <option value="">Cup Size</option>
                        {cupSizes[selectedSystem].map((size) => (
                          <option key={size} value={size}>
                            {size}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {/* Output Section - Right Side */}
              <div className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-lg p-6">
                <h3 className="font-semibold mb-4 text-lg">Bra Size Conversions</h3>
                {selectedSystem && bandSize && cupSize ? (
                  <div className="grid grid-cols-2 gap-4">
                    {conversions.map((conversion) => (
                      <div key={conversion.system} className="bg-white rounded-lg p-4 shadow-sm">
                        <div className="text-gray-800 mb-1">{getSystemDisplayName(conversion.system)}</div>
                        <div className="text-xl text-purple-600 font-bold">
                          {conversion.bandSize && conversion.cupSize ? `${conversion.bandSize}${conversion.cupSize}` : '-'}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center text-gray-500 py-12">
                    <svg className="w-12 h-12 mx-auto mb-4 text-gray-400" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                      <path d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
                    </svg>
                    <p>Enter your current size to see conversions</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Information Section */}
          <div className="mt-12 bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="bg-white p-8">
              <h2 className="text-2xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
                About Bra Size Conversion
              </h2>
            </div>

            <div className="p-8">
              <div className="max-w-3xl mx-auto">
                <div className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-xl p-6 mb-6">
                  <div className="flex items-start">
                    <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center mr-4 mt-1 flex-shrink-0">
                      <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>
                    <div>
                      <p className="text-gray-700 text-s mb-4">
                        Bra sizes can vary significantly between countries and brands. This converter helps you find your approximate size in different international sizing systems.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-xl p-6">
                  <h3 className="text-lg font-semibold text-purple-800 mb-4">Important Considerations</h3>
                  <div className="grid gap-4">
                    {[
                      {
                        icon: "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2",
                        text: "The same bra size might fit differently across brands"
                      },
                      {
                        icon: "M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z",
                        text: "Some brands may use slightly different conversion charts"
                      },
                      {
                        icon: "M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122",
                        text: "Your best fit might be different from the converted size"
                      },
                      {
                        icon: "M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z",
                        text: "Always try on bras when possible to ensure the best fit"
                      }
                    ].map((item, index) => (
                      <div key={index} className="flex items-center bg-white/80 rounded-lg p-4">
                        <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center mr-4 flex-shrink-0">
                          <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={item.icon} />
                          </svg>
                        </div>
                        <p className="text-gray-700">{item.text}</p>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="mt-6 bg-gradient-to-br from-purple-50 to-pink-50 rounded-xl p-6">
                  <div className="flex items-center">
                    <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center mr-4 flex-shrink-0">
                      <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                      </svg>
                    </div>
                    <p className="text-gray-700">
                      For the most accurate fit, we recommend using our measuring guide and trying on bras in person whenever possible.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* How It Works Section */}
          <div className="space-y-8 mt-12">
            {/* Main Explanation */}
            <div className="bg-white rounded-xl shadow-lg p-8">
              <h2 className="text-2xl font-bold mb-6 text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
                How Bra Size Conversion Works
              </h2>
              
              <div className="grid md:grid-cols-2 gap-6">
                <div className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-lg p-6">
                  <div className="flex items-center mb-4">
                    <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center mr-4">
                      <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>
                    <h3 className="text-lg font-semibold">Brand Variations</h3>
                  </div>
                  <div className="space-y-4">
                    <div className="bg-white/80 rounded-lg p-4">
                      <div className="flex items-center">
                        <span className="text-purple-500 font-medium w-36">Standard Brands</span>
                        <span className="text-gray-300 mx-6">|</span>
                        <span className="ml-2">Follow regular conversion charts</span>
                      </div>
                    </div>
                    <div className="bg-white/80 rounded-lg p-4">
                      <div className="flex items-center">
                        <span className="text-purple-500 font-medium w-36">Full-Cup Brands</span>
                        <span className="text-gray-300 mx-6">|</span>
                        <span className="ml-2">May run larger in cups</span>
                      </div>
                    </div>
                    <div className="bg-white/80 rounded-lg p-4">
                      <div className="flex items-center">
                        <span className="text-purple-500 font-medium w-36">Sports Brands</span>
                        <span className="text-gray-300 mx-6">|</span>
                        <span className="ml-2">Often use S, M, L sizing</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-lg p-6">
                  <div className="flex items-center mb-4">
                    <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center mr-4">
                      <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                      </svg>
                    </div>
                    <h3 className="text-lg font-semibold">Band Size Conversion</h3>
                  </div>
                  <div className="space-y-4">
                    <div className="bg-white/80 rounded-lg p-4">
                      <div className="flex items-center">
                        <span className="text-purple-600 font-medium w-24">US → UK</span>
                        <span className="text-gray-400 mx-4">|</span>
                        <span>Direct conversion <span className="text-gray-500 ml-2">(34 → 34)</span></span>
                      </div>
                    </div>
                    <div className="bg-white/80 rounded-lg p-4">
                      <div className="flex items-center">
                        <span className="text-purple-600 font-medium w-24">US → AU/NZ</span>
                        <span className="text-gray-400 mx-4">|</span>
                        <span>Subtract 22 to band size <span className="text-gray-500 ml-2">(34 → 12)</span></span>
                      </div>
                    </div>
                    <div className="bg-white/80 rounded-lg p-4">
                      <div className="flex items-center">
                        <span className="text-purple-600 font-medium w-24">US → IT</span>
                        <span className="text-gray-400 mx-4">|</span>
                        <span>Divide by 2, then subtract 13 <span className="text-gray-500 ml-2">(34 → 12)</span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-6 bg-gradient-to-br from-purple-50 to-pink-50 rounded-lg p-6">
                <div className="flex items-center mb-4">
                  <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center mr-4">
                    <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <h3 className="text-lg font-semibold">Cup Size Conversion</h3>
                </div>
                <p className="text-sm text-gray-500 mb-4">
                  Cup letters usually stay the same across regions, with some exceptions.
                </p>
                <div className="overflow-x-auto bg-white/80 rounded-lg">
                <table className="w-full text-sm">
    <thead>
      <tr className="border-b border-purple-100">
        <th className="p-3 text-left font-medium text-purple-900">US</th>
        <th className="p-3 text-left font-medium text-purple-900">UK</th>
        <th className="p-3 text-left font-medium text-purple-900">EU</th>
        <th className="p-3 text-left font-medium text-purple-900">FR/ES</th>
        <th className="p-3 text-left font-medium text-purple-900">IT</th>
        <th className="p-3 text-left font-medium text-purple-900">JP</th>
        <th className="p-3 text-left font-medium text-purple-900">AU/NZ</th>
      </tr>
    </thead>
    <tbody>
      <tr className="border-b">
        <td className="p-3">A, B, C, D</td>
        <td className="p-3">A, B, C, D</td>
        <td className="p-3">A, B, C, D</td>
        <td className="p-3">A, B, C, D</td>
        <td className="p-3">A, B, C, D</td>
        <td className="p-3">A, B, C, D</td>
        <td className="p-3">A, B, C, D</td>
      </tr>
      <tr className="border-b">
        <td className="p-3">DD/E</td>
        <td className="p-3">DD</td>
        <td className="p-3">E</td>
        <td className="p-3">E</td>
        <td className="p-3">DD</td>
        <td className="p-3">E</td>
        <td className="p-3">DD</td>
      </tr>
      <tr className="border-b">
        <td className="p-3">DDD/F</td>
        <td className="p-3">E</td>
        <td className="p-3">F</td>
        <td className="p-3">F</td>
        <td className="p-3">E</td>
        <td className="p-3">F</td>
        <td className="p-3">F</td>
      </tr>
      <tr className="border-b">
        <td className="p-3">G</td>
        <td className="p-3">FF</td>
        <td className="p-3">G</td>
        <td className="p-3">G</td>
        <td className="p-3">FF</td>
        <td className="p-3">G</td>
        <td className="p-3">G</td>
      </tr>
      <tr className="border-b">
        <td className="p-3">H</td>
        <td className="p-3">GG</td>
        <td className="p-3">H</td>
        <td className="p-3">H</td>
        <td className="p-3">GG</td>
        <td className="p-3">H</td>
        <td className="p-3">H</td>
      </tr>
      <tr className="border-b">
        <td className="p-3">I, J, K</td>
        <td className="p-3">H, HH, J</td>
        <td className="p-3">I, J, K</td>
        <td className="p-3">I, J, K</td>
        <td className="p-3">H, HH, J</td>
        <td className="p-3">I, J, K</td>
        <td className="p-3">I, J, K</td>
      </tr>
      <tr>
        <td className="p-3">L, M, N</td>
        <td className="p-3">JJ, K, KK</td>
        <td className="p-3">L, M, N</td>
        <td className="p-3">L, M, N</td>
        <td className="p-3">JJ, K, KK</td>
        <td className="p-3">L, M, N</td>
        <td className="p-3">L, M, N</td>
      </tr>
    </tbody>
  </table>
</div>
              </div>

              <div className="mt-6 bg-gradient-to-br from-purple-50 to-pink-50 rounded-lg p-6">
                <div className="flex items-center mb-4">
                  <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center mr-4">
                    <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <h3 className="text-lg font-semibold">Pro Tips for Perfect Fit</h3>
                </div>
                <div className="grid md:grid-cols-2 gap-4 text-sm">
                  <ul className="space-y-2">
                    <li className="flex items-center">
                      <span className="w-2 h-2 bg-purple-400 rounded-full mr-2"></span>
                      Always measure before converting
                    </li>
                    <li className="flex items-center">
                      <span className="w-2 h-2 bg-purple-400 rounded-full mr-2"></span>
                      Consider sister sizes for better fit
                    </li>
                  </ul>
                  <ul className="space-y-2">
                    <li className="flex items-center">
                      <span className="w-2 h-2 bg-purple-400 rounded-full mr-2"></span>
                      Check brand-specific guides
                    </li>
                    <li className="flex items-center">
                      <span className="w-2 h-2 bg-purple-400 rounded-full mr-2"></span>
                      Try different styles within brands
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Detailed Size Charts */}
            <div className="bg-white rounded-xl shadow-lg p-8">
              <h2 className="text-2xl font-bold mb-2 text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
                Detailed International Size Charts
              </h2>
              <div className="max-w-4xl mx-auto p-6">
                {/* Band Size Chart */}
                <div className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-lg p-6 mb-8">
                  <h3 className="text-lg font-semibold mb-4">Band Size Chart</h3>
                  <div className="overflow-x-auto">
                    <table className="w-full text-sm">
                      <thead className="bg-white">
                        <tr>
                          <th className="p-2 text-left font-semibold border-b">US/UK</th>
                          <th className="p-2 text-left font-semibold border-b">AU/NZ</th>
                          <th className="p-2 text-left font-semibold border-b">EU</th>
                          <th className="p-2 text-left font-semibold border-b">FR/ES</th>
                          <th className="p-2 text-left font-semibold border-b">IT</th>
                          <th className="p-2 text-left font-semibold border-b">JP</th>
                          <th className="p-2 text-left font-semibold border-b">Inches</th>
                          <th className="p-2 text-left font-semibold border-b">CM</th>
                        </tr>
                      </thead>
                      <tbody>
                        {[
                          { usuk: "28", aunz: "6", eu: "60", fres: "75", it: "1", jp: "55", inches: "26-27", cm: "66-69" },
                          { usuk: "30", aunz: "8", eu: "65", fres: "80", it: "2", jp: "60", inches: "28-29", cm: "71-74" },
                          { usuk: "32", aunz: "10", eu: "70", fres: "85", it: "3", jp: "65", inches: "30-31", cm: "76-79" },
                          { usuk: "34", aunz: "12", eu: "75", fres: "90", it: "4", jp: "70", inches: "32-33", cm: "81-84" },
                          { usuk: "36", aunz: "14", eu: "80", fres: "95", it: "5", jp: "75", inches: "34-35", cm: "86-89" },
                          { usuk: "38", aunz: "16", eu: "85", fres: "100", it: "6", jp: "80", inches: "36-37", cm: "91-94" },
                          { usuk: "40", aunz: "18", eu: "90", fres: "105", it: "7", jp: "85", inches: "38-39", cm: "96-99" },
                          { usuk: "42", aunz: "20", eu: "95", fres: "110", it: "8", jp: "90", inches: "40-41", cm: "101-104" },
                          { usuk: "44", aunz: "22", eu: "100", fres: "115", it: "9", jp: "95", inches: "42-43", cm: "106-109" },
                          { usuk: "46", aunz: "24", eu: "105", fres: "120", it: "10", jp: "100", inches: "44-45", cm: "111-114" },
                          { usuk: "48", aunz: "26", eu: "110", fres: "125", it: "11", jp: "105", inches: "46-47", cm: "116-119" },
                          { usuk: "50", aunz: "28", eu: "115", fres: "130", it: "12", jp: "110", inches: "48-49", cm: "121-124" }
                        ].map((row, index) => (
                          <tr key={index} className="border-b">
                            <td className="p-2">{row.usuk}</td>
                            <td className="p-2">{row.aunz}</td>
                            <td className="p-2">{row.eu}</td>
                            <td className="p-2">{row.fres}</td>
                            <td className="p-2">{row.it}</td>
                            <td className="p-2">{row.jp}</td>
                            <td className="p-2">{row.inches}"</td>
                            <td className="p-2">{row.cm}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* Cup Size Chart */}
                <div className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-lg p-6">
                  <h3 className="text-lg font-semibold mb-4">Cup Size Chart</h3>
                  <div className="overflow-x-auto">
                    <table className="w-full text-sm">
                      <thead className="bg-white">
                        <tr>
                          <th className="p-2 text-left font-semibold border-b">US</th>
                          <th className="p-2 text-left font-semibold border-b">UK</th>
                          <th className="p-2 text-left font-semibold border-b">AU/NZ</th>
                          <th className="p-2 text-left font-semibold border-b">EU</th>
                          <th className="p-2 text-left font-semibold border-b">FR/ES</th>
                          <th className="p-2 text-left font-semibold border-b">IT</th>
                          <th className="p-2 text-left font-semibold border-b">JP</th>
                          <th className="p-2 text-left font-semibold border-b">Inches</th>
                          <th className="p-2 text-left font-semibold border-b">CM</th>
                        </tr>
                      </thead>
                      <tbody>
                        {[
                          { us: "AA", uk: "AA", aunz: "AA", eu: "AA", fres: "AA", it: "AA", jp: "AAA", inches: "½", cm: "1.3" },
                          { us: "A", uk: "A", aunz: "A", eu: "A", fres: "A", it: "A", jp: "AA", inches: "1", cm: "2.5" },
                          { us: "B", uk: "B", aunz: "B", eu: "B", fres: "B", it: "B", jp: "A", inches: "2", cm: "5.1" },
                          { us: "C", uk: "C", aunz: "C", eu: "C", fres: "C", it: "C", jp: "B", inches: "3", cm: "7.6" },
                          { us: "D", uk: "D", aunz: "D", eu: "D", fres: "D", it: "D", jp: "C", inches: "4", cm: "10.2" },
                          { us: "DD/E", uk: "DD", aunz: "DD", eu: "E", fres: "E", it: "E", jp: "D", inches: "5", cm: "12.7" },
                          { us: "DDD/F", uk: "E", aunz: "E", eu: "F", fres: "F", it: "F", jp: "E", inches: "6", cm: "15.2" },
                          { us: "G", uk: "F", aunz: "F", eu: "G", fres: "G", it: "G", jp: "F", inches: "7", cm: "17.8" },
                          { us: "H", uk: "FF", aunz: "G", eu: "H", fres: "H", it: "H", jp: "G", inches: "8", cm: "20.3" },
                          { us: "I", uk: "G", aunz: "H", eu: "I", fres: "I", it: "I", jp: "H", inches: "9", cm: "22.9" },
                          { us: "J/K", uk: "GG/H", aunz: "J", eu: "J/K", fres: "J/K", it: "J/K", jp: "I/J", inches: "10-11", cm: "25.4-27.9" },
                          { us: "L", uk: "H", aunz: "K", eu: "L", fres: "L", it: "L", jp: "K", inches: "12", cm: "30.5" },
                          { us: "M", uk: "HH", aunz: "L", eu: "M", fres: "M", it: "M", jp: "L", inches: "13", cm: "33.0" },
                          { us: "N", uk: "J", aunz: "M", eu: "N", fres: "N", it: "N", jp: "M", inches: "14", cm: "35.6" },
                          { us: "O", uk: "JJ", aunz: "N", eu: "O", fres: "O", it: "O", jp: "N", inches: "15", cm: "38.1" }
                        ].map((row, index) => (
                          <tr key={index} className="border-b">
                            <td className="p-2">{row.us}</td>
                            <td className="p-2">{row.uk}</td>
                            <td className="p-2">{row.aunz}</td>
                            <td className="p-2">{row.eu}</td>
                            <td className="p-2">{row.fres}</td>
                            <td className="p-2">{row.it}</td>
                            <td className="p-2">{row.jp}</td>
                            <td className="p-2">{row.inches}"</td>
                            <td className="p-2">{row.cm}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  };

export default NewConverterPage;
