import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

const MeasuringGuide: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>How to Measure Bra Size - Step by Step Guide | BraSizeCalculatorPro</title>
        <meta name="description" content="Learn how to measure your bra size accurately at home. Step-by-step guide with tips for measuring band and cup size for the perfect fit." />
        <meta name="keywords" content="how to measure bra size, bra measurement guide, measure band size, measure cup size, bra fitting instructions" />
        <link rel="canonical" href="https://brasizecalculatorpro.com/measuring-guide" />
      </Helmet>
      <div className="min-h-screen bg-gradient-to-b from-purple-50 via-white to-purple-50">
        {/* Hero Section */}
        <div className="bg-gradient-to-r from-purple-100 to-pink-100">
          <div className="container mx-auto px-4 py-12">
            <div className="max-w-4xl mx-auto text-center">
              <h1 className="text-5xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
                How to Measure Your Bra Size
              </h1>
              <p className="text-lg text-gray-600 mb-8">
                Get the perfect fit with our comprehensive measuring guide
              </p>
              <div className="inline-flex items-center text-md font-semibold text-purple-600">
                <span className="mr-2">⏱️</span>
                Time needed: 5-10 minutes
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto px-4 py-12">
          <div className="max-w-4xl mx-auto">
            {/* What You'll Need Section */}
            <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-8 mb-8">
              <h2 className="text-2xl font-bold mb-6 flex items-center">
                <span className="text-purple-600 mr-3">📋</span>
                What You'll Need
              </h2>
              <div className="grid md:grid-cols-2 gap-6">
                {[
                  { icon: "📏", item: "A soft measuring tape", detail: "For accurate measurements" },
                  { icon: "🪞", item: "A mirror", detail: "To ensure proper tape placement" },
                  { icon: "👚", item: "An unpadded bra (or no bra)", detail: "For most accurate results" },
                  { icon: "⏰", item: "A few minutes of time", detail: "5-10 minutes should be enough" }
                ].map((tool, index) => (
                  <div key={index} className="flex items-start p-4 bg-gray-50 rounded-xl">
                    <span className="text-2xl mr-4">{tool.icon}</span>
                    <div>
                      <h3 className="font-medium">{tool.item}</h3>
                      <p className="text-sm text-gray-600">{tool.detail}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Steps Section */}
            <div className="space-y-8">
              {/* Step 1 */}
              <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-8">
                <div className="flex items-center mb-6">
                  <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center text-purple-600 font-bold mr-4">
                    1
                  </div>
                  <h2 className="text-2xl text-purple-600 font-bold">Measure Your Band Size</h2>
                </div>
                <div className="grid md:grid-cols-2 gap-8">
                  <div className="space-y-4">
                    <p className="text-gray-600">
                      Wrap the measuring tape around your ribcage, just under your bust:
                    </p>
                    <ul className="space-y-3">
                      {[
                        "Keep the tape parallel to the ground",
                        "The tape should be snug but not tight",
                        "Round to the nearest whole number",
                        "If even, add 4 inches",
                        "If odd, add 5 inches"
                      ].map((tip, index) => (
                        <li key={index} className="flex items-center text-gray-700">
                          <svg className="w-5 h-5 text-purple-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                          {tip}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="relative h-64 bg-purple-50 rounded-xl overflow-hidden">
                    {/* Add image here */}
                    <div className="absolute inset-0 flex items-center justify-center text-gray-400">
                      Band measurement illustration
                    </div>
                  </div>
                </div>
              </div>

              {/* Step 2 */}
              <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-8">
                <div className="flex items-center mb-6">
                  <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center text-purple-600 font-bold mr-4">
                    2
                  </div>
                  <h2 className="text-2xl text-purple-600 font-bold">Measure Your Bust Size</h2>
                </div>
                <div className="grid md:grid-cols-2 gap-8">
                  <div className="space-y-4">
                    <p className="text-gray-600">
                      Wrap the measuring tape around the fullest part of your bust:
                    </p>
                    <ul className="space-y-3">
                      {[
                        "Keep the tape parallel to the ground",
                        "The tape should be snug but not tight",
                        "Make sure the tape is not twisted",
                        "Round to the nearest whole number"
                      ].map((tip, index) => (
                        <li key={index} className="flex items-center text-gray-700">
                          <svg className="w-5 h-5 text-purple-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                          {tip}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="relative h-64 bg-purple-50 rounded-xl overflow-hidden">
                    {/* Add image here */}
                    <div className="absolute inset-0 flex items-center justify-center text-gray-400">
                      Bust measurement illustration
                    </div>
                  </div>
                </div>
              </div>

              {/* Step 3 */}
              <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-8">
                <div className="flex items-center mb-6">
                  <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center text-purple-600 font-bold mr-4">
                    3
                  </div>
                  <h2 className="text-2xl text-purple-600 font-bold">Calculate Your Cup Size</h2>
                </div>
                <p className="text-gray-600 mb-6">
                  Subtract your band size from your bust measurement to find your cup size:
                </p>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                  {[
                    { diff: "0″", cup: "AA" },
                    { diff: "1″", cup: "A" },
                    { diff: "2″", cup: "B" },
                    { diff: "3″", cup: "C" },
                    { diff: "4″", cup: "D" },
                    { diff: "5″", cup: "DD/E" },
                    { diff: "6″", cup: "DDD/F" },
                    { diff: "7″", cup: "G" }
                  ].map((size, index) => (
                    <div key={index} className="bg-purple-50 p-4 rounded-xl text-center">
                      <div className="text-sm text-gray-600">Difference</div>
                      <div className="font-bold text-purple-600">{size.diff}</div>
                      <div className="text-sm text-gray-600 mt-2">Cup Size</div>
                      <div className="font-bold">{size.cup}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Tips Section */}
            <div className="bg-gradient-to-r from-purple-100 to-pink-100 rounded-2xl p-8 mt-8">
              <h2 className="text-2xl font-bold mb-6 flex items-center">
                <span className="mr-3">💡</span>
                Pro Tips for Accurate Measurement
              </h2>
              <div className="grid md:grid-cols-2 gap-6">
                {[
                  { tip: "Measure while standing straight", detail: "Maintain good posture for accuracy" },
                  { tip: "Skip the padded bra", detail: "Use an unpadded bra or no bra for best results" },
                  { tip: "Take multiple measurements", detail: "Double-check for consistency" },
                  { tip: "Get help if possible", detail: "A friend can help ensure proper tape placement" }
                ].map((tip, index) => (
                  <div key={index} className="bg-white/80 rounded-xl p-4">
                    <h3 className="font-medium mb-1">{tip.tip}</h3>
                    <p className="text-sm text-gray-600">{tip.detail}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* Common Mistakes Section */}
            <div className="mb-12 mt-12">
              <h2 className="text-2xl font-bold mb-6 text-purple-800 text-center">Common Mistakes to Avoid</h2>
              <div className="grid gap-4 md:grid-cols-2">
                {[
                  {
                    title: "1. Using an Unlevel Measuring Tape",
                    description: "Measuring tape that dips at the back or front instead of staying horizontal leads to inaccurate bust or underbust measurements. Ensure the tape is parallel to the floor, using a mirror or assistance from another person to check its position."
                  },
                  {
                    title: "2. Measuring Over Bulky Clothing",
                    description: "Taking measurements over thick or loose-fitting clothes like sweaters or padded bras adds bulk and results in larger measurements than your actual size. Always measure directly against the skin or over a thin, unpadded bra for accuracy."
                  },
                  {
                    title: "3. Measuring Too Tightly or Too Loosely",
                    description: "Pulling the tape too tight causes it to dig into the skin and underestimates your size, while leaving it too loose creates a gap and overestimates the measurement. The tape should be snug but not constrictive, lying flat against your body."
                  },
                  {
                    title: "4. Incorrect Tape Placement for Bust Measurement",
                    description: "Placing the tape too high (near the underarms) or too low (below the fullest part of the chest) fails to measure the correct circumference. Position the tape around the widest part of your chest, usually at nipple level, for an accurate bust size."
                  },
                  {
                    title: "5. Incorrect Tape Placement for Underbust Measurement",
                    description: "Placing the tape above or below the underbust area gives incorrect band size measurements. The tape should be positioned snugly and horizontally around your ribcage, directly under your chest, where the bra band would sit."
                  },
                  {
                    title: "6. Not Relaxing the Body During Measurement",
                    description: "Standing rigidly, puffing out the chest, or slouching while measuring alters the natural chest shape, resulting in distorted measurements. Maintain a natural posture, standing upright with arms relaxed at your sides or appropriately positioned based on the measuring technique."
                  },
                  {
                    title: "7. Skipping Re-Measurements",
                    description: "Taking a single measurement without rechecking can lead to errors caused by tape movement or improper placement. Measure at least twice and average the results if discrepancies occur to ensure accuracy."
                  },
                  {
                    title: "8. Confusing Bust and Band Measurements",
                    description: "Mixing up bust measurements (the fullest part of the chest) with band measurements (just below the chest) leads to incorrect bra sizing. Clearly distinguish between the two: bust is measured at the widest part, and underbust is measured directly beneath."
                  },
                  {
                    title: "9. Not Accounting for Breathing",
                    description: "Holding your breath or fully exhaling during measurement changes the natural chest circumference. Breathe normally, keeping the tape snug and steady, to capture your actual size."
                  },
                  {
                    title: "10. Using a Stretchy or Worn Measuring Tape",
                    description: "Using a worn-out or elastic tape that has stretched over time results in inconsistent or inaccurate measurements. Always use a sturdy, non-stretchable measuring tape in good condition for reliable results."
                  },
                  {
                    title: "11. Measuring from Underarm to Underarm",
                    description: "Measuring from underarm to underarm only provides the width of your chest, not the full circumference required to determine your bust size. This leads to an incomplete measurement, resulting in inaccurate bra sizing. For accurate results, the tape should wrap completely around your torso, covering the fullest part of your chest."
                  }
                ].map((mistake, index) => (
                  <div key={index} className="bg-white p-6 rounded-lg shadow-md border border-gray-200 hover:shadow-lg transition-shadow">
                    <h3 className="font-semibold text-gray-800 mb-3">{mistake.title}</h3>
                    <p className="text-gray-600 leading-relaxed">{mistake.description}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* Button to Calculator */}
            <div className="text-center mt-12">
              <button
                onClick={() => window.location.href = '/'}
                className="inline-flex items-center bg-white border border-purple-600 text-purple-600 text-lg font-semibold px-8 py-3 rounded-lg transition-colors group"
              >
                <span>Bra Size Calculator</span>
                <svg
                  className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MeasuringGuide;
