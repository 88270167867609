import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { detailedFitIssues } from '../data/fitIssues';

const BraFitIssuesGuide: React.FC = () => {
  const [expandedSection, setExpandedSection] = useState<string>('');
  const [activeTab, setActiveTab] = useState('overview');

  // Group issues by category
  const issuesByCategory = detailedFitIssues.reduce((acc, issue) => {
    if (!acc[issue.category]) {
      acc[issue.category] = [];
    }
    acc[issue.category].push(issue);
    return acc;
  }, {} as Record<string, typeof detailedFitIssues>);

  const toggleSection = (id: string) => {
    setExpandedSection(expandedSection === id ? '' : id);
  };

  const tabs = [
    { id: 'overview', name: 'Overview' },
    { id: 'detailed', name: 'Detailed Issues' },
    { id: 'adjustments', name: 'Size Adjustments' },
    { id: 'shapes', name: 'Breast Shapes' },
  ];

  const quickIssues = [
    {
      title: 'Band Too Loose',
      description: 'Cups may gap even if they\'re the correct size. Straps often slip due to lack of tension. The gore might not tack properly.',
    },
    {
      title: 'Cups Too Small',
      description: 'Band feels tight even in correct size. Underwires sit on breast tissue. Gore floats away from sternum.',
    },
    {
      title: 'Band Too Tight',
      description: 'Band creates uncomfortable pressure and leaves deep marks. Bra rides up when arms are raised. Underwires dig into ribs and distort.',
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <Helmet>
        <title>Common Bra Fit Issues & Solutions Guide | BraSizeCalculatorPro</title>
        <meta name="description" content="Solve common bra fitting problems with our expert guide. Learn to identify and fix issues like band tightness, cup overflow, and more for the perfect bra fit." />
        <meta name="keywords" content="bra fit issues, bra fitting problems, bra size problems, bra fitting solutions, how to fix bra fit" />
        <link rel="canonical" href="https://brasizecalculatorpro.com/bra-fit-issues" />
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
            Bra Fit Issues & Solutions Guide
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Understanding common fit issues and finding the right solutions for your perfect fit
          </p>
        </motion.div>

        {/* Tab Navigation */}
        <div className="mb-8">
          <div className="border-b border-gray-200">
            <nav className="flex justify-center" aria-label="Tabs">
              <div className="flex space-x-8">
                {tabs.map((tab) => (
                  <button
                    key={tab.id}
                    onClick={() => setActiveTab(tab.id)}
                    className={`
                      py-4 px-6 border-b-2 font-medium text-lg transition-colors duration-200
                      ${activeTab === tab.id
                        ? 'border-purple-500 text-purple-900'
                        : 'border-transparent text-gray-500 hover:text-purple-700 hover:border-purple-300'
                      }
                    `}
                  >
                    {tab.name}
                  </button>
                ))}
              </div>
            </nav>
          </div>
        </div>

        {/* Overview Tab Content */}
        {activeTab === 'overview' && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="space-y-8 max-w-7xl mx-auto"
          >
            <div className="bg-white rounded-xl shadow p-8 mb-8">
              <h2 className="text-2xl font-semibold text-purple-900 mb-4 text-center">Common Fit Issues</h2>
              <p className="text-gray-600 text-medium mx-auto mb-6 text-center">
                Understanding common bra fit issues helps you identify and solve problems quickly. Most fit issues can be resolved by adjusting size or trying different styles.
              </p>
              <div className="grid md:grid-cols-3 gap-6">
                {quickIssues.map((issue, index) => (
                  <div key={index} className="bg-purple-50 rounded-lg p-6">
                    <h3 className="font-medium text-purple-900 mb-3">{issue.title}</h3>
                    <p className="text-gray-600">{issue.description}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-white rounded-xl shadow p-8">
              <h2 className="text-2xl font-semibold text-purple-900 mb-4 text-center">Key Measurements</h2>

              {/* Single column for "Relationships" */}
              <div className="mb-6">
                <div className="bg-purple-50 rounded-lg p-6">
                  <h3 className="text-lg font-medium text-purple-900 mb-4">Relationships</h3>
                  <ul className="space-y-3 text-gray-600">
                    {[
                      "Large difference between standing/leaning indicates soft tissue",
                      "Small difference between underbust measurements suggests less band flexibility",
                      "Asymmetry over 1 cup size may need specific fitting approaches",
                    ].map((item, i) => (
                      <li key={i} className="flex text-gray-600">
                        <span className="text-purple-500 mr-2">•</span>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              {/* Two-column layout for "Underbust" and "Bust" */}
              <div className="grid md:grid-cols-2 gap-6">
                {[
                  {
                    title: "Underbust",
                    items: ["Snug: Primary band size indicator", "Tight: Shows band size flexibility"],
                  },
                  {
                    title: "Bust",
                    items: ["Standing: Basic cup size indicator", "Leaning: Shows maximum tissue volume", "Lying: Helps account for tissue softness"],
                  },
                ].map((section, idx) => (
                  <div key={idx} className="bg-purple-50 rounded-lg p-6">
                    <h3 className="text-lg font-medium text-purple-900 mb-4">{section.title}</h3>
                    <ul className="space-y-3">
                      {section.items.map((item, i) => (
                        <li key={i} className="flex text-gray-600">
                          <span className="text-purple-500 mr-2">•</span>
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>

            {/* Button to Calculator */}
            <div className="text-center mt-12">
              <button
                onClick={() => window.location.href = '/#calculator'}
                className="inline-flex items-center bg-white border border-purple-600 text-purple-600 text-lg font-semibold px-8 py-3 rounded-lg transition-colors group"
              >
                <span>Calculate Bra Size</span>
                <svg
                  className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>
          </motion.div>
        )}

        {/* Detailed Issues Tab Content */}
        {activeTab === 'detailed' && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="space-y-8"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-7xl mx-auto">
              {Object.entries(issuesByCategory).map(([category, issues]) => (
                issues.map((issue) => (
                  <div key={issue.id} className="bg-white rounded-xl shadow-sm p-6">
                    <h3 className="text-xl font-semibold text-purple-900 mb-4">{issue.title}</h3>
                    
                    {/* Causes Card */}
                    <div className="bg-purple-50 rounded-lg p-4 mb-4">
                      <h4 className="font-medium text-purple-900 mb-2">Common Causes</h4>
                      <ul className="space-y-2">
                        {issue.causes.map((cause, index) => (
                          <li key={index} className="flex text-gray-600">
                            <span className="text-purple-500 mr-2">•</span>
                            {cause}
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* Solutions Card */}
                    <div className="bg-green-50 rounded-lg p-4 mb-4">
                      <h4 className="font-medium text-green-800 mb-2">Solutions</h4>
                      <ul className="space-y-2">
                        {issue.solutions.map((solution, index) => (
                          <li key={index} className="flex text-gray-600">
                            <span className="text-green-500 mr-2">•</span>
                            {solution}
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* Related Measurements */}
                    {issue.relatedMeasurements && issue.relatedMeasurements.length > 0 && (
                      <div className="bg-gray-50 rounded-lg p-4 mb-4">
                        <h4 className="font-medium text-gray-500 mb-4">Related Measurements</h4>
                        <div className="flex flex-wrap gap-2">
                          {issue.relatedMeasurements.map((measurement, index) => (
                            <span
                              key={index}
                              className="inline-flex items-center px-3 py-1 rounded-full text-md font-medium bg-blue-100 text-blue-800"
                            >
                              {measurement}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}

                    {/* Recommended Styles */}
                    {issue.recommendedTypes && issue.recommendedTypes.length > 0 && (
                      <div className="bg-gray-50 rounded-lg p-4 mb-4">
                        <h4 className="font-medium text-gray-500 mb-4">Recommended Bra Types</h4>
                        <div className="flex flex-wrap gap-2">
                          {issue.recommendedTypes.map((style, index) => (
                            <span
                              key={index}
                              className="inline-flex items-center px-3 py-1 rounded-full text-md font-medium bg-green-100 text-green-800"
                            >
                              {style}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))
              ))}
            </div>

            {/* Button to Calculator */}
            <div className="text-center mt-12">
              <button
                onClick={() => window.location.href = '/#calculator'}
                className="inline-flex items-center bg-white border border-purple-600 text-purple-600 text-lg font-semibold px-8 py-3 rounded-lg transition-colors group"
              >
                <span>Bra Size Calculator</span>
                <svg
                  className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>
          </motion.div>
        )}

        {/* Size Adjustments Tab Content */}
        {activeTab === 'adjustments' && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="space-y-8 max-w-7xl mx-auto"
          >
            {/* Band Size Adjustments Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="bg-white rounded-xl shadow-sm p-6">
                <h3 className="text-xl font-semibold text-purple-900 mb-4">Current Band Rides Up/Feels Loose</h3>
                <div className="bg-purple-50 rounded-lg p-4 mb-4">
                  <ul className="space-y-2">
                    <li className="flex text-gray-600">
                      <span className="text-purple-500 mr-2">•</span>
                      Go down one band size
                    </li>
                    <li className="flex text-gray-600">
                      <span className="text-purple-500 mr-2">•</span>
                      Go up one cup letter to maintain volume
                    </li>
                    <li className="flex text-gray-600">
                      <span className="text-purple-500 mr-2">•</span>
                      Example: 36D → 34DD → 32DDD/F if needed
                    </li>
                  </ul>
                </div>
              </div>

              <div className="bg-white rounded-xl shadow-sm p-6">
                <h3 className="text-xl font-semibold text-purple-900 mb-4">Current Band Feels Too Tight</h3>
                <div className="bg-purple-50 rounded-lg p-4 mb-4">
                  <ul className="space-y-2">
                    <li className="flex text-gray-600">
                      <span className="text-purple-500 mr-2">•</span>
                      Verify if cups are too small (causing band tightness)
                    </li>
                    <li className="flex text-gray-600">
                      <span className="text-purple-500 mr-2">•</span>
                      If cups fit, go up one band size
                    </li>
                    <li className="flex text-gray-600">
                      <span className="text-purple-500 mr-2">•</span>
                      Go down one cup letter to maintain volume
                    </li>
                    <li className="flex text-gray-600">
                      <span className="text-purple-500 mr-2">•</span>
                      Example: 34DD → 36D → 38C if needed
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Cup Size Adjustments Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="bg-white rounded-xl shadow-sm p-6">
                <h3 className="text-xl font-semibold text-purple-900 mb-4">Gaping Cups</h3>
                <div className="bg-purple-50 rounded-lg p-4 mb-4">
                  <ul className="space-y-2">
                    <li className="flex text-gray-600">
                      <span className="text-purple-500 mr-2">•</span>
                      Verify band isn't too loose
                    </li>
                    <li className="flex text-gray-600">
                      <span className="text-purple-500 mr-2">•</span>
                      If band fits, go down one cup size
                    </li>
                    <li className="flex text-gray-600">
                      <span className="text-purple-500 mr-2">•</span>
                      Keep same band size (e.g., 34DD → 34D)
                    </li>
                    <li className="flex text-gray-600">
                      <span className="text-purple-500 mr-2">•</span>
                      Consider breast shape before multiple cup adjustments
                    </li>
                  </ul>
                </div>
              </div>

              <div className="bg-white rounded-xl shadow-sm p-6">
                <h3 className="text-xl font-semibold text-purple-900 mb-4">Overflowing Cups</h3>
                <div className="bg-purple-50 rounded-lg p-4 mb-4">
                  <ul className="space-y-2">
                    <li className="flex text-gray-600">
                      <span className="text-purple-500 mr-2">•</span>
                      Go up one cup size at a time
                    </li>
                    <li className="flex text-gray-600">
                      <span className="text-purple-500 mr-2">•</span>
                      Keep same band size (e.g., 34D → 34DD)
                    </li>
                    <li className="flex text-gray-600">
                      <span className="text-purple-500 mr-2">•</span>
                      May need multiple cup adjustments (e.g., 34D → 34DD → 34DDD/F)
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Button to Sister Sizes */}
            <div className="text-center mt-12">
              <button
                onClick={() => window.location.href = '/sister-sizes'}
                className="inline-flex items-center bg-white border border-purple-600 text-purple-600 text-lg font-semibold px-8 py-3 rounded-lg transition-colors group"
              >
                <span>Find Your Sister Sizes</span>
                <svg
                  className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>
          </motion.div>
        )}

        {/* Breast Shapes Tab Content */}
        {activeTab === 'shapes' && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="space-y-8"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-7xl mx-auto">
              {[
                {
                  title: "Full On Top (FOT)",
                  characteristics: ["More tissue above nipple", "Often experiences gaping at bottom of cups", "May have spillage at top of cups"],
                  solutions: ["Open-on-top cup styles", "Stretch lace upper sections", "Avoid full coverage styles", "Consider Polish bra brands", "Half-cup styles often work well"]
                },
                {
                  title: "Full On Bottom (FOB)",
                  characteristics: ["More tissue below nipple", "Often experiences gaping at top of cups", "May have folding at bottom of cups"],
                  solutions: ["Balconette styles", "Three-part cups", "Bottom support slings", "Avoid plunges", "Consider side support panels"]
                },
                {
                  title: "Center Full",
                  characteristics: ["More tissue in center", "Often experiences spillage at gore", "May need lower gore height"],
                  solutions: ["Plunge styles", "Overlapping gores", "Deep center cups", "Avoid high gores", "Consider altered gore styles"]
                },
                {
                  title: "Outer Full",
                  characteristics: ["More tissue on outer sides", "May experience side spillage", "Often needs wider wires"],
                  solutions: ["Side support panels", "Wider wires", "Full coverage styles", "Avoid narrow cup styles"]
                }
              ].map((shape, idx) => (
                <motion.div
                  key={idx}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: idx * 0.1 }}
                  className="bg-white rounded-xl shadow-sm p-6"
                >
                  <h3 className="text-xl font-semibold text-purple-900 mb-4">{shape.title}</h3>
                  
                  <div className="bg-purple-50 rounded-lg p-4 mb-4">
                    <h4 className="font-medium text-purple-900 mb-2">Characteristics</h4>
                    <ul className="space-y-2">
                      {shape.characteristics.map((char, i) => (
                        <li key={i} className="flex text-gray-600">
                          <span className="text-purple-500 mr-2">•</span>
                          {char}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="bg-green-50 rounded-lg p-4">
                    <h4 className="font-medium text-green-800 mb-2">Recommended Styles</h4>
                    <ul className="space-y-2">
                      {shape.solutions.map((sol, i) => (
                        <li key={i} className="flex text-gray-600">
                          <span className="text-green-500 mr-2">•</span>
                          {sol}
                        </li>
                      ))}
                    </ul>
                  </div>
                </motion.div>
              ))}
            </div>

            {/* Button to Breast Shape Guide */}
            <div className="text-center mt-12">
              <button
                onClick={() => window.location.href = '/breast-shape-guide'}
                className="inline-flex items-center bg-white border border-purple-600 text-purple-600 text-md font-semibold px-8 py-3 rounded-lg transition-colors group"
              >
                <span>Explore Breast Shape Guide</span>
                <svg
                  className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>
          </motion.div>
        )}

      </div>
    </div>
  );
};

export default BraFitIssuesGuide;
