import { Measurements } from '../types/braSizeTypes';
import { ConfidenceScore } from '../types/fitTypes';

export function calculateConfidence(measurements: Measurements): number {
  let score = 0;

  // Base measurements (60% of total score)
  if (measurements.underbust && measurements.bust) {
    score += 60;
  }

  // Current size (20% of total score)
  if (measurements.currentSize) {
    score += 20;
  }

  // Fit issues (20% of total score)
  const fitIssuesLength = measurements.fitIssues?.length ?? 0;
  if (fitIssuesLength > 0) {
    score += Math.min(20, fitIssuesLength * 5);
  }

  return score / 100; // Convert to decimal for legacy compatibility
}

export function generateConfidenceScore(measurements: Measurements): ConfidenceScore {
  let score = 0;
  const reasons: string[] = [];
  const improvements: string[] = [];

  // Base measurements (60% of total score)
  if (measurements.underbust && measurements.bust) {
    score += 60;
    reasons.push('Basic measurements provided');
  } else {
    improvements.push('Add underbust and bust measurements for basic sizing');
  }

  // Current size (20% of total score)
  if (measurements.currentSize) {
    score += 20;
    reasons.push('Current size reference provided');
  } else {
    improvements.push('Add your current bra size for more accurate recommendations');
  }

  // Fit issues (20% of total score)
  const fitIssuesLength = measurements.fitIssues?.length ?? 0;
  if (fitIssuesLength > 0) {
    const fitIssueScore = Math.min(20, fitIssuesLength * 5);
    score += fitIssueScore;
    reasons.push(`${fitIssuesLength} fit issues identified`);
  } else {
    improvements.push('Share any current fit issues to help refine recommendations');
  }

  return {
    score,
    reasons,
    improvements,
    level: getConfidenceLevel(score)
  };
}

function getConfidenceLevel(score: number): 'high' | 'medium' | 'low' {
  if (score >= 75) return 'high';
  if (score >= 50) return 'medium';
  return 'low';
}
