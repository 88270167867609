import { brandDatabase } from '../data/brandDatabase';
import { SizeResult } from '../types/braSizeTypes';
import { EnhancedMeasurements, FitIssue } from '../types/fitTypes';
import { BrandProfile, RecommendationResult } from '../types/brandTypes';
import fitIssues from '../data/fitIssues';

export function generateBrandRecommendations(
  sizeResult: SizeResult,
  measurements: EnhancedMeasurements,
  selectedFitIssues: string[] = []
): RecommendationResult {
  const brandScores = brandDatabase.map(brand => {
    let score = 0;
    let reasoning: string[] = [];
    
    // Check if size is within brand's range
    const bandInRange = brand.sizeRange.bands.includes(parseInt(sizeResult.bandSize));
    const cupInRange = brand.sizeRange.cups.includes(sizeResult.cupSize);
    if (!bandInRange || !cupInRange) return null;

    score += 20;
    reasoning.push("Size is within brand's range");

    // Price match
    if (measurements.pricePreference && brand.price === measurements.pricePreference) {
      score += 15;
      reasoning.push("Price matches preference");
    }

    // Style preferences
    if (measurements.stylePreferences) {
      const matchingStyles = brand.style.filter(style => 
        measurements.stylePreferences?.includes(style)
      );
      if (matchingStyles.length > 0) {
        score += matchingStyles.length * 10;
        reasoning.push(`Matches ${matchingStyles.length} style preferences`);
      }
    }

    // Fit issues
    if (selectedFitIssues.length > 0) {
      const matchingFeatures = brand.features.filter(feature =>
        selectedFitIssues.some(issueId => {
          const issue = fitIssues.find((fi: FitIssue) => fi.id === issueId);
          return issue?.sizeImplication !== undefined;
        })
      );
      if (matchingFeatures.length > 0) {
        score += matchingFeatures.length * 15;
        reasoning.push(`Addresses ${matchingFeatures.length} fit issues`);
      }
    }

    return score > 0 ? {
      brand: brand.name,
      score,
      reasoning: reasoning.join(". ")
    } : null;
  }).filter((result): result is { brand: string; score: number; reasoning: string } => result !== null);

  // Sort by score in descending order
  brandScores.sort((a, b) => b.score - a.score);

  // Generate fit tips
  const fitTips = selectedFitIssues.map(issueId => {
    const issue = fitIssues.find((fi: FitIssue) => fi.id === issueId);
    return issue?.solution || '';
  }).filter(tip => tip !== '');

  return {
    brands: brandScores.map(b => b.brand),
    scores: brandScores.map(b => b.score),
    reasonings: brandScores.map(b => b.reasoning),
    fitTips
  };
}
