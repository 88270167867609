// Type definitions
export type SizingSystem = 'US' | 'UK' | 'UK_DRESS' | 'EU' | 'FR_ES' | 'IT' | 'AU_NZ' | 'JP';

export interface BraSize {
  band: number;
  cup: string;
}

interface BandSizeRanges {
  US: number[];
  UK: number[];
  EU: number[];
  FR_ES: number[];
  AU_NZ: number[];
  IT: number[];
  JP: number[];
}

interface SizeRange {
  band: BandSizeRanges;
  cups: string[];
}

interface SizingData {
  validBandSizes: Record<SizingSystem, number[]>;
  cupSizes: Record<SizingSystem, string[]>;
  sizeMapping: Record<string, SizeRange>;
}

export const sizingData: SizingData = {
  validBandSizes: {
    US: [28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60],
    UK: [28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60],
    UK_DRESS: [4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36],
    EU: [60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140],
    FR_ES: [75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155],
    IT: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    JP: [65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145],
    AU_NZ: [6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38]
  },
  cupSizes: {
    US: ['AA', 'A', 'B', 'C', 'D', 'DD/E', 'DDD/F', 'G', 'H', 'I', 'J', 'K'],
    UK: ['AA', 'A', 'B', 'C', 'D', 'DD', 'E', 'F', 'FF', 'G', 'GG', 'H'],
    UK_DRESS: [], // Dress sizes don't have cup sizes
    EU: ['AA', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'],
    FR_ES: ['AA', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'],
    IT: ['AA', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'],
    AU_NZ: ['AA', 'A', 'B', 'C', 'D', 'DD', 'E', 'F', 'FF', 'G', 'GG', 'H'],
    JP: ['AA', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K']
  },
  sizeMapping: {
    XXS: {
      band: { US: [28, 30], UK: [28, 30], EU: [60, 65], FR_ES: [75, 80], AU_NZ: [6, 8], IT: [0, 1], JP: [65, 70] },
      cups: ['AA', 'A', 'B', 'C', 'D', 'DD/E'] // Reflecting Nike's 30A - 30E
    },
    XS: {
      band: { US: [30, 32], UK: [30, 32], EU: [65, 70], FR_ES: [80, 85], AU_NZ: [8, 10], IT: [1, 2], JP: [70, 75] },
      cups: ['AA', 'A', 'B', 'C', 'D', 'DD/E', 'DDD/F', 'G'] // Reflecting Nike's 30F - 30G and 32A - 32G
    },
    S: {
      band: { US: [32, 34], UK: [32, 34], EU: [70, 75], FR_ES: [85, 90], AU_NZ: [10, 12], IT: [2, 3], JP: [75, 80] },
      cups: ['AA', 'A', 'B', 'C', 'D', 'DD/E', 'DDD/F', 'G', 'H'] // Reflecting Nike's 34A - 34G and 32C - 32G
    },
    M: {
      band: { US: [36, 38], UK: [36, 38], EU: [80, 85], FR_ES: [95, 100], AU_NZ: [14, 16], IT: [4, 5], JP: [85, 90] },
      cups: ['AA', 'A', 'B', 'C', 'D', 'DD/E', 'DDD/F', 'G', 'H', 'I'] // Reflecting Nike's 36A - 36G and 34D - 34G
    },
    L: {
      band: { US: [38, 40], UK: [38, 40], EU: [85, 90], FR_ES: [100, 105], AU_NZ: [16, 18], IT: [5, 6], JP: [90, 95] },
      cups: ['AA', 'A', 'B', 'C', 'D', 'DD/E', 'DDD/F', 'G', 'H', 'I', 'J'] // Reflecting Nike's 38A - 38G and 36D - 36G
    },
    XL: {
      band: { US: [40, 42], UK: [40, 42], EU: [90, 95], FR_ES: [105, 110], AU_NZ: [18, 20], IT: [6, 7], JP: [95, 100] },
      cups: ['AA', 'A', 'B', 'C', 'D', 'DD/E', 'DDD/F', 'G', 'H', 'I', 'J', 'K'] // Reflecting Nike's 40A - 40G and 38D - 38G
    },
    XXL: {
      band: { US: [42, 44], UK: [42, 44], EU: [95, 100], FR_ES: [110, 115], AU_NZ: [20, 22], IT: [7, 8], JP: [100, 105] },
      cups: ['AA', 'A', 'B', 'C', 'D', 'DD/E', 'DDD/F', 'G', 'H', 'I', 'J', 'K'] // Reflecting Nike's 42A - 42G and 40D - 40G
    },
    XXXL: {
      band: { US: [44, 46], UK: [44, 46], EU: [100, 105], FR_ES: [115, 120], AU_NZ: [22, 24], IT: [8, 9], JP: [105, 110] },
      cups: ['AA', 'A', 'B', 'C', 'D', 'DD/E', 'DDD/F', 'G', 'H', 'I', 'J', 'K'] // Reflecting Nike's 44A - 44G and 42D - 42G
    },
    XXXXL: {
      band: { US: [46, 48, 50], UK: [46, 48, 50], EU: [105, 110, 115], FR_ES: [120, 125, 130], AU_NZ: [24, 26, 28], IT: [9, 10, 11], JP: [110, 115, 120] },
      cups: ['AA', 'A', 'B', 'C', 'D', 'DD/E', 'DDD/F', 'G', 'H', 'I', 'J', 'K'] // Reflecting Nike's 46A - 50G
    }
  }
};

// Export validBandSizes and cupSizes for backward compatibility
export const validBandSizes = sizingData.validBandSizes;
export const cupSizes = sizingData.cupSizes;

// Helper functions
export function convertSize(size: BraSize, fromSystem: SizingSystem, toSystem: SizingSystem): BraSize | null {
  const { band, cup } = size;
  
  // Get the band size conversion
  const fromBandSizes = sizingData.validBandSizes[fromSystem];
  const toBandSizes = sizingData.validBandSizes[toSystem];
  
  // Find the closest band size in the target system
  const fromIndex = fromBandSizes.indexOf(band);
  if (fromIndex === -1) return null;
  
  const toIndex = Math.min(fromIndex, toBandSizes.length - 1);
  const convertedBand = toBandSizes[toIndex];
  
  // Get the cup size conversion
  const fromCupSizes = sizingData.cupSizes[fromSystem];
  const toCupSizes = sizingData.cupSizes[toSystem];
  const cupIndex = fromCupSizes.indexOf(cup);
  
  if (cupIndex === -1) return null;
  const convertedCup = toCupSizes[Math.min(cupIndex, toCupSizes.length - 1)];
  
  return {
    band: convertedBand,
    cup: convertedCup
  };
}

// Helper function to get letter size (XXS to XXXXL) from band size and cup size
export function getLetterSize(bandSize: number, cupSize: string, system: SizingSystem): string | null {
  for (const [letterSize, ranges] of Object.entries(sizingData.sizeMapping)) {
    const systemRange = ranges.band[system as keyof typeof ranges.band];
    if (systemRange && systemRange.includes(bandSize)) {
      // If the cup size is within the allowed range for this letter size, return it
      if (ranges.cups.includes(cupSize)) {
        return letterSize;
      }
      // If cup size is larger than what's allowed for this band size range,
      // move up one size unless we're already at the largest size
      if (cupSize > ranges.cups[ranges.cups.length - 1] && letterSize !== 'XXXXL') {
        continue; // Check the next size up
      }
      return letterSize;
    }
  }
  return null;
}

// Helper function to find nearest band size
export function findNearestBandSize(size: number, system: SizingSystem): number {
  const bandSizes = sizingData.validBandSizes[system];
  return bandSizes.reduce((prev: number, curr: number) => 
    Math.abs(curr - size) < Math.abs(prev - size) ? curr : prev
  );
}