import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface BraType {
  id: string;
  title: string;
  description: string;
  impact: string;
  sizingTips: string[];
  imagePath?: string;
}

const BraTypesGuide: React.FC = () => {
  const [expandedSection, setExpandedSection] = useState<string>('');

  const braTypes: BraType[] = [
    {
      id: 'plunge',
      title: 'Plunge',
      description: 'Plunge bras feature a deep V-neckline with angled cups that create natural cleavage. The low center gore and strategic cup positioning make them ideal for low-cut tops. Their unique construction changes how breast tissue sits in the cups.',
      impact: 'Your regular size usually works, but you might need to go up a cup size if you have fuller breast tissue at the top.',
      sizingTips: [
        'If the center gore floats, try going up a cup size while keeping the same band',
        'Check for gaping at the top of cups - common in plunge styles if cups are too large',
        'Ensure side wings are high enough to contain tissue movement',
        'For asymmetrical breasts, fit to the larger side as the plunge cut provides less coverage'
      ]
    },
    {
      id: 'pushup',
      title: 'Push-Up',
      description: 'Push-up styles incorporate padding at the cup bottom and sides, designed to lift breast tissue upward and inward. This construction fundamentally changes how cup volume works. Because of the built-in padding, you might need to adjust your usual size.',
      impact: "Consider going down a cup size if you're trying a heavily padded push-up style. The padding takes up space in the cup that would normally accommodate breast tissue. For example, if you typically wear a 36D, you might need a 36C in a push-up style to accommodate both your breast tissue and the padding without overflow.",
      sizingTips: [
        'Check padding thickness - more padding means you might need to adjust size more',
        'Ensure the center gore lies flat against sternum despite the push-together effect',
        'Watch for spillage at the center - common in push-up styles if cup is too small',
        'Consider how much lift you want - more dramatic push-up effects might require different sizing'
      ]
    },
    {
      id: 'underwired',
      title: 'Underwired',
      description: 'Underwired bras feature a semi-circular or U-shaped wire sewn into the bottom and sides of each cup. This structured support element follows the natural curve under the breast tissue and extends up the sides. While many bra styles (like balconette or full cup) can be underwired, the presence of wires creates specific fitting considerations.',
      impact: 'Wire placement significantly affects how a bra fits and what size you need. For example, if you wear a 36C in a non-wired bra, you might need a 34D in an underwired style because the firmer band ensures proper wire placement. The wire should sit flat against your ribcage in the center and follow your breast root (where breast tissue meets chest wall) - if it doesn\'t, you likely need a different size. A too-small cup will cause the wire to sit on breast tissue, while a too-large cup will have the wire extending beyond your breast root.',
      sizingTips: [
        'Consider going down a band size and up a cup size for better wire placement',
        'Ensure the center gore (wire between cups) sits flat against your sternum',
        'Check that wires fully encapsulate breast tissue without sitting on it',
        'If wires dig in under arms, try a different cup size or style rather than a larger band'
      ]
    },
    {
      id: 'fullcup',
      title: 'Full Cup',
      description: 'These bras offer complete coverage with cups that fully encapsulate breast tissue. Their construction typically includes multiple seams for support and shaping. They distribute breast tissue evenly and prevent spillage.',
      impact: "Full cup bras often require your true size or even a cup size larger because they're designed to contain all breast tissue without compression. There should be no gapping at the top or overflow at the sides. A 38D in a full cup style might feel more generous than a 38D in other styles.",
      sizingTips: [
        'Check that the cup fully encases all breast tissue, including under the arms',
        'Ensure the center gore sits completely flat against your sternum',
        'Try raising your arms - cups should stay in place without tissue escaping',
        'If you get wrinkling at the top, try going down a cup size while keeping the same band'
      ]
    },
    {
      id: 'halfcup',
      title: 'Half Cup/Demi',
      description: 'Half cup or demi bras feature cups that cover approximately half to three-quarters of the breast, with a horizontal cut across the top. Their partial coverage design creates a natural lifting effect while showing more upper breast tissue than full coverage styles. These bras often incorporate vertical seams and side support panels for shaping.',
      impact: 'The partial coverage design means you might need to adjust your typical size based on how your breast tissue sits in the cup. For example, if you normally wear a 34D in full coverage styles, you might need a 34DD in a half cup to prevent spillage at the top edge if you have fuller upper tissue. Conversely, someone with less upper fullness might find they need to go down to a 34C in half cups to prevent gaping. This is because the horizontal cut of the cup intersects your breast tissue differently than a full coverage style would.',
      sizingTips: [
        'If you have full-on-top breasts, consider going up a cup size',
        'For full-on-bottom shapes, your regular size often works well',
        'Pay special attention to where the cup edge cuts across your breast tissue',
        'Look for styles with stretch lace at the top if between sizes'
      ]
    },
    {
      id: 'triangle',
      title: 'Triangle',
      description: 'Triangle bras take a different approach to support compared to structured styles. With their minimal construction and stretchy materials, these bras work best for smaller busts or casual wear. Instead of relying on rigid cup architecture, they use the natural properties of their flexible fabrics to provide gentle support.',
      impact: 'You might find yourself going down a band size in triangle styles due to the stretchier materials. What fits as a 32B in a structured bra might work better as a 30C in a triangle style to achieve proper support.',
      sizingTips: [
        'Consider fabric stretch when choosing size - more elastic materials might need sizing down',
        'Try the bra on with a fitted shirt to check support and shape',
        'For fuller busts, consider sizing up in the band for more coverage',
        'Remember that these will stretch with wear, so a snugger initial fit is better'
      ]
    },
    {
      id: 'minimizer',
      title: 'Minimizer',
      description: 'They\'re popular among those who want a more streamlined silhouette under clothing. These bras use specific engineering to reduce the projected size of your bust and redistribute breast tissue across a wider area of the chest wall, reducing projection.',
      impact: 'You might need to go up a cup size in minimizer styles to accommodate how they redistribute breast tissue. While you might wear a 36DD in standard styles, a minimizer might require a 36DDD/F because the tissue redistribution needs more cup surface area to work effectively.',
      sizingTips: [
        'Measure across the fullest part of bust while wearing current bra to compare minimized measurement',
        'Ensure the sides of the cups are tall enough to prevent tissue escape',
        'Check that the band stays horizontal and doesn\'t ride up',
        'If you feel compressed rather than redistributed, try going up a cup size'
      ]
    },
    {
      id: 'sports',
      title: 'Sports',
      description: 'Compression (pressing breast tissue close to the chest) and encapsulation (individual cups) in sports bras work together to reduce movement. Their firm bands and reinforced cups work together to provide maximum support.',
      impact: 'High-impact sports bras typically require a smaller band size but larger cup size than your everyday bras. The intense movement during exercise means you need a firmer band - go down one band size but up one cup size to maintain the same volume. For example, a 34D might find better support in a 32DD sports bra.',
      sizingTips: [
        'Jump test the bra to check bounce control - if excessive movement, try a smaller band size',
        'For high-impact activities, ensure absolutely no tissue escapes during movement',
        'With encapsulation styles, check each cup fits without compression or spillage',
        'When between alpha sizes, size up for comfort or down for maximum support'
      ]
    },
    {
      id: 'bralette',
      title: 'Bralette',
      description: 'Bralettes prioritize comfort with wireless designs and soft, stretchy materials. They typically come in simplified sizing (S, M, L) rather than band/cup combinations. The relaxed construction means they work best for smaller to medium busts.',
      impact: 'For traditional bra sizes 32A-34B, choose size Small; 34C-36B fits Medium; 36C-38B fits Large. If you want more support, size down - for instance, if you wear a 34C (typically Medium), try a Small for firmer hold.',
      sizingTips: [
        'Consider both your band and cup size when converting to alpha sizing',
        'Check the stretch recovery of the fabric - poor recovery means size down',
        'Try the bralette with different arm positions to test support',
        'For layering purposes, consider how the bralette looks under fitted clothing'
      ]
    },
    {
      id: 'stickon',
      title: 'Stick-On',
      description: 'Stick-on bras use medical-grade adhesive to attach directly to the skin, providing a completely strapless and backless solution. They typically come with reusable adhesive and are designed for specific clothing needs like backless dresses or plunging necklines.',
      impact: 'These bras typically come in standard cup sizes only (no band size). Choose your regular cup size (e.g., B, C, D) but consider going up a cup size if you want more coverage, as these bras tend to create a more compressed shape.',
      sizingTips: [
        'Measure your cup size while wearing an unpadded bra for most accurate selection',
        'If between sizes, size up as adhesive works better with fuller coverage',
        'Consider breast weight - heavier breasts might need supplementary support tape',
        'For special occasions, test the size a few weeks before to ensure proper adhesion and support'
      ]
    },
    {
      id: 'bandeau',
      title: 'Bandeau',
      description: 'Bandeau bras are simple, strip-style bras that wrap around the chest without straps. They typically offer light support through compression and stretchy materials, similar to a tube top design.',
      impact: 'Usually sized as XS-XL. For A-B cups, choose your regular band size equivalent. For C cups and larger, consider going up one size to avoid over-compression. For example, if you typically wear a 34B (medium), stick with medium, but if you wear a 34D, consider a large.',
      sizingTips: [
        'Look at both your band and cup size when choosing alpha sizing (32C might want a different size than 38C)',
        'Consider the fabric stretch factor - less stretchy materials might require sizing up',
        'If wearing for longer periods, size up for comfort as compression increases with wear',
        'For layering purposes, your regular size usually works best'
      ]
    },
    {
      id: 'corset',
      title: 'Corset Bra',
      description: 'Corset bras combine the support of a traditional bra with the shaping properties of a corset. They typically extend down to the waist or hips and feature boning for structure and hook-and-eye closures.',
      impact: 'Size primarily by your underbust measurement (like band size) but go down one size for firmer support, as corsets should be snug to provide proper shaping. Cup sizing follows regular bra sizing, but consider going up one cup size to accommodate the pushing effect of the corset structure.',
      sizingTips: [
        'Measure both standing and sitting to ensure comfort in all positions',
        'Check the length - corset should sit properly at waist when seated',
        'Consider your torso length - longer torsos might need a size up for proper coverage',
        'If planning to "season" the corset (gradually breaking it in), your true underbust measurement might be best'
      ]
    },
    {
      id: 'racerback',
      title: 'Racerback Bra',
      description: 'Racerback bras feature straps that come together between the shoulder blades, creating a Y or T shape at the back. This design provides enhanced support through strap placement and is particularly useful for those with sloping shoulders or high-impact activities. The converged straps help distribute weight more evenly across the back while preventing straps from slipping.',
      impact: 'The unique strap configuration can affect how the bra fits compared to standard styles. You might need to adjust your band size because the pulled-together straps can create different tension across your back. For example, if you wear a 34D in regular styles, you might find a 36D more comfortable in racerback, as the pulled straps can make the band feel tighter.',
      sizingTips: [
        'If converting from regular bra size, consider going up one band size as racerback construction can feel tighter',
        'Check shoulder comfort - if straps dig in, try a larger cup size to better distribute weight',
        'Ensure the band stays parallel to the ground despite the pulled-back design',
        'Test the fit by reaching forward - the center front should stay anchored to your chest'
      ]
    },
    {
      id: 'lace',
      title: 'Lace Bra',
      description: 'Lace bras combine aesthetic appeal with functionality, featuring delicate patterns that can either be rigid or stretch. The construction can vary from unlined (sheer) to lined or padded, with each type affecting fit differently. The elasticity and pattern of the lace itself plays a crucial role in how the bra supports and shapes.',
      impact: 'Sizing can vary significantly based on whether the lace is stretch or rigid. Stretch lace bras might require sizing down as they mold to your shape, while rigid lace often needs your true size or even a size up to prevent the pattern from distorting. For instance, in stretch lace you might need a 34C instead of your usual 34D.',
      sizingTips: [
        'Test the stretch factor of the lace - more stretch usually means size down, rigid lace might need sizing up',
        'Check for pattern distortion when fitted - stretched lace patterns indicate too small a size',
        'For unlined styles, ensure coverage remains adequate in different positions',
        'Consider the season - lace has less give in colder weather, more in warm weather'
      ]
    },
    {
      id: 'nursing',
      title: 'Nursing Bra',
      description: 'Nursing bras are designed with convenient feeding access while providing necessary support during lactation. They feature special clips or panels that can be easily opened with one hand, and often include extra hook settings to accommodate size fluctuations throughout the day and during different nursing stages.',
      impact: 'Breasts can change size significantly during nursing, often increasing 1-2 cup sizes from pre-pregnancy size. Buy nursing bras to fit the larger size but with a firm band, as the band provides primary support. For example, if you wore 34D pre-pregnancy, you might need 34E/F or 36E/F during nursing.',
      sizingTips: [
        'Choose a size that fits comfortably on the loosest hook when breasts are fullest',
        'Ensure cups contain breast tissue without compression - check both before and after feeding',
        'Look for at least 3-4 sets of hooks to accommodate size fluctuations',
        'Consider buying two sizes - one for early nursing when breasts are fullest, another for later stages'
      ]
    },
    {
      id: 'convertible',
      title: 'Convertible Bra',
      description: 'Convertible bras feature removable and adjustable straps that can be worn multiple ways: traditional, halter, crossback, strapless, or one-shoulder. This versatility makes them ideal for various outfit needs, but the construction must be especially sturdy to maintain support in all configurations.',
      impact: "When choosing a size, consider how you'll wear it most often. The band needs to be particularly snug since it must support independently when worn strapless. If you typically wear 34D, you might want 32DD in a convertible style to ensure adequate support in all configurations.",
      sizingTips: [
        'Test the fit in all strap configurations you plan to use - especially strapless',
        'Size down in band if you plan to wear it strapless frequently',
        'Check that the cup volume remains consistent across different strap positions',
        'Ensure the band stays in place during movement in each conversion style'
      ]
    },
    {
      id: 'multiway',
      title: 'Multiway Bra',
      description: 'Multiway bras are engineered for maximum versatility, featuring strategically placed strap attachments that allow for multiple wearing configurations. Unlike simple convertible bras, multiway styles often incorporate additional support elements in the cups and band to maintain effective support regardless of strap position. This innovative design includes reinforced side panels, specially designed cups that maintain their shape across configurations, and multiple attachment points that can accommodate classic, halter, racerback, asymmetric, or strapless styles.',
      impact: 'The adaptable nature of multiway bras requires careful sizing consideration as the bra needs to perform effectively in all configurations. Since the support structure must work without straps, sizing follows similar principles to strapless bras but with additional considerations for strap arrangements. For instance, if you typically wear a 34D in regular bras, you might find a 32DD fits better in a multiway style to ensure adequate support in strapless mode while remaining comfortable in strapped configurations.',
      sizingTips: [
        'Start by fitting the bra in its most challenging configuration (usually strapless) - if it works there, it will work in other positions',
        'Check that the band sits firmly and horizontally in all wearing positions - if it rides up in any configuration, try a smaller band size',
        'Ensure cup volume remains consistent when switching between strap positions - if you get spillage in certain configurations, go up a cup size',
        'Test the fit with movement in each strap arrangement - jumping, reaching up, bending forward should all feel secure'
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      <Helmet>
        <title>Bra Types & Sizing Guide | Understanding Different Bra Styles</title>
        <meta name="description" content="Comprehensive guide to different bra types and styles. Learn which bra style works best for your shape and needs." />
        <meta name="keywords" content="bra types, bra styles, t-shirt bra, balconette, plunge bra, push-up bra, full coverage bra, bra fitting guide" />
        <meta property="og:title" content="Bra Types & SizingGuide | Understanding Different Bra Styles" />
        <meta property="og:description" content="Comprehensive guide to different bra types and styles. Learn which bra style works best for your shape and needs." />
        <link rel="canonical" href="https://yourdomain.com/bra-types-guide" />
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
            Bra Types & Sizing Nuances
          </h1>
          <p className="text-lg md:text-xl text-gray-600 max-w-3xl mx-auto mb-8">
          Different bra styles serve different purposes. Understanding these differences helps you choose the perfect bra for every outfit and occasion.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {braTypes.map((braType) => (
            <div
              key={braType.id}
              className="bg-white rounded-xl shadow overflow-hidden transition-all duration-300 hover:shadow-md"
            >
              {braType.imagePath && (
                <div className="mb-4 flex justify-center">
                  <img
                    src={braType.imagePath}
                    alt={`${braType.title} bra type illustration`}
                    className="w-32 h-32 object-contain"
                  />
                </div>
              )}
              <div className="p-6">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => setExpandedSection(expandedSection === braType.id ? '' : braType.id)}
                >
                  <h3 className="text-xl font-semibold text-purple-900">{braType.title}</h3>
                  {expandedSection === braType.id ? (
                    <ChevronUp className="w-5 h-5 text-purple-600" />
                  ) : (
                    <ChevronDown className="w-5 h-5 text-purple-600" />
                  )}
                </div>

                <div
                  className={`mt-4 transition-all duration-300 ${
                    expandedSection === braType.id ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
                  }`}
                >
                  <p className="text-gray-600 mb-4">{braType.description}</p>
                  
                  <div className="bg-purple-50 rounded-lg p-4 mb-4">
                    <h4 className="font-semibold text-purple-900 mb-2">Sizing Impact</h4>
                    <p className="text-gray-600">{braType.impact}</p>
                  </div>

                  <div className="bg-gray-50 rounded-lg p-4">
                    <h4 className="font-semibold text-purple-900 mb-2">Sizing Tips</h4>
                    <ul className="space-y-2">
                      {braType.sizingTips.map((tip, index) => (
                        <li key={index} className="text-gray-600 flex items-start">
                          <span className="text-purple-500 mr-2">•</span>
                          {tip}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="bg-white rounded-xl max-w-4xl mx-auto shadow-sm p-8 mb-12 mt-8">
          <h2 className="text-2xl font-semibold text-center text-purple-900 mb-6">The Science Behind Bra Sizing Variations</h2>
          <p className="text-gray-600 mb-6">
          Many women are surprised to discover that their perfect size in one bra style might not be ideal in another. Understanding how different bra types are constructed helps explain why your size might vary and how to find your best fit in each style.
            When we talk about bra sizes, we're really discussing two key elements: the band measurement and cup volume. However, how these measurements translate into fit can vary significantly between styles due to:
          </p>
          <div className="grid md:grid-cols-2 gap-6 mb-8">
          <div className="bg-white rounded-xl p-6 border border-purple-500">
                <h3 className="text-xl font-semibold text-purple-900 mb-4">Construction Elements</h3>
                <ul className="space-y-3 text-gray-600">
                  <li className="flex items-start">
                    <span className="text-purple-500 mr-2">•</span>
                    Cup construction and angle
                  </li>
                  <li className="flex items-start">
                    <span className="text-purple-500 mr-2">•</span>
                    Support mechanisms
                  </li>
                  <li className="flex items-start">
                    <span className="text-purple-500 mr-2">•</span>
                    Material properties
                  </li>
                  <li className="flex items-start">
                    <span className="text-purple-500 mr-2">•</span>
                    Design purpose
                  </li>
                </ul>
              </div>

              <div className="bg-white rounded-xl p-6 border border-purple-500">
                <h3 className="text-xl font-semibold text-purple-900 mb-4">Sizing Variations</h3>
                <ul className="space-y-3 text-gray-600">
                  <li className="flex items-start">
                    <span className="text-purple-500 mr-2">•</span>
                    Band elasticity differences
                  </li>
                  <li className="flex items-start">
                    <span className="text-purple-500 mr-2">•</span>
                    Cup volume distribution
                  </li>
                  <li className="flex items-start">
                    <span className="text-purple-500 mr-2">•</span>
                    Coverage variations
                  </li>
                  <li className="flex items-start">
                    <span className="text-purple-500 mr-2">•</span>
                    Support requirements
                  </li>
                </ul>
              </div>
          </div>
          <h3 className="text-xl font-semibold text-purple-900 mb-4 mt-12">Understanding Your Size Across Styles</h3>
          <div className="grid md:grid-cols-3 gap-6 mb-8">
            <div className="bg-purple-50 rounded-lg p-6">
              <h4 className="font-semibold text-purple-900 mb-3">1. Band Fit Changes</h4>
              <p className="text-gray-600 mb-2">Your band size might need to change based on:</p>
              <ul className="list-disc list-inside text-gray-600">
                <li>Material elasticity</li>
                <li>Support requirements</li>
                <li>Style construction</li>
              </ul>
            </div>
            <div className="bg-purple-50 rounded-lg p-6">
              <h4 className="font-semibold text-purple-900 mb-3">2. Cup Volume Variations</h4>
              <p className="text-gray-600 mb-2">Cup size needs often vary due to:</p>
              <ul className="list-disc list-inside text-gray-600">
                <li>Padding presence</li>
                <li>Compression levels</li>
                <li>Coverage amount</li>
                <li>Breast tissue redistribution</li>
              </ul>
            </div>
            <div className="bg-purple-50 rounded-lg p-6">
              <h4 className="font-semibold text-purple-900 mb-3">3. Style-Specific Sizing</h4>
              <p className="text-gray-600 mb-2">Different styles create different shapes because:</p>
              <ul className="list-disc list-inside text-gray-600">
                <li>Support structures vary</li>
                <li>Coverage levels change</li>
                <li>Seam placement affects tissue distribution</li>
              </ul>
            </div>
          </div>

          <div className="bg-purple-100 rounded-lg p-6 mb-8">
            <h3 className="text-xl font-semibold text-purple-900 mb-4">Mastering Your Personal Bra Size Range</h3>
            <p className="text-gray-600">
              Your "true size" is actually a range that varies by style. When trying a new bra style, consider how its construction might affect your size needs. A good rule of thumb is to start with your usual size but be prepared to adjust based on the style's specific characteristics. Remember that sizing variations between styles don't indicate a fitting problem – they're a natural result of different design purposes and construction methods.
            </p>
          </div>

          {/* Key Factors to Consider Card */}
<div className="bg-gray-100 rounded-xl p-6 mb-8">
  <h3 className="text-xl font-semibold text-purple-900 mb-4">Key Factors to Consider</h3>
  <div className="text-gray-600 mb-8">
    <p className="mb-4">
      It's important to understand that the same size can fit differently across various bra types. A 34C in a balcony style might not feel the same as a 34C in a push-up bra. This is because each style is designed to shape and support your breasts in different ways.
    </p>
  </div>
  <ul className="space-y-3 text-gray-600 ml-5">
    <li className="flex items-start">
      <span className="text-purple-500 mr-2">•</span>
      Material elasticity and support requirements
    </li>
    <li className="flex items-start">
      <span className="text-purple-500 mr-2">•</span>
      Padding presence and compression levels
    </li>
    <li className="flex items-start">
      <span className="text-purple-500 mr-2">•</span>
      Coverage amount and tissue redistribution
    </li>
    <li className="flex items-start">
      <span className="text-purple-500 mr-2">•</span>
      Style-specific support structures
    </li>
  </ul>
</div>
	
        </div>
            
        <div className="mt-12 max-w-4xl mx-auto bg-white rounded-xl shadow p-8">
          <h2 className="text-2xl font-semibold text-center text-purple-900 mb-4">How To Choose The Right Bra Style</h2>
          <p className="text-gray-600 mb-6">
            The perfect bra style depends on your outfit, occasion, and personal comfort preferences. Here are some general guidelines:
          </p>
          <div className="grid md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div className="bg-purple-50 rounded-lg p-4">
                <h3 className="font-semibold text-purple-800 mb-2">Everyday Wear</h3>
                <p className="text-gray-600">T-shirt bras and full coverage styles offer comfort and versatility.</p>
              </div>
              <div className="bg-purple-50 rounded-lg p-4">
                <h3 className="font-semibold text-purple-800 mb-2">Special Occasions</h3>
                <p className="text-gray-600">Plunge and push-up styles work well with evening wear and low-cut outfits.</p>
              </div>
            </div>
            <div className="space-y-4">
              <div className="bg-purple-50 rounded-lg p-4">
                <h3 className="font-semibold text-purple-800 mb-2">Active Wear</h3>
                <p className="text-gray-600">Sports bras provide necessary support for different impact levels.</p>
              </div>
              <div className="bg-purple-50 rounded-lg p-4">
                <h3 className="font-semibold text-purple-800 mb-2">Comfort Focus</h3>
                <p className="text-gray-600">Wireless bras and bralettes offer comfort without sacrificing style.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BraTypesGuide;
