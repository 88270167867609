import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/card';
import { AlertCircle, CheckCircle2, ChevronRight, Ruler, Camera, ChevronDown } from 'lucide-react';

const BraFittingGuide = () => {
  const [activeSection, setActiveSection] = useState('');
  const [showMeasuringTips, setShowMeasuringTips] = useState(false);

  const fitCheckItems = [
    {
      good: "Band sits level around the body",
      bad: "Band rides up in back",
      icon: <ChevronRight className="w-4 h-4" />
    },
    {
      good: "Cups fully contain breast tissue",
      bad: "Overflow or gaping in cups",
      icon: <ChevronRight className="w-4 h-4" />
    },
    {
      good: "Center gore lies flat against sternum",
      bad: "Center gore floats away from body",
      icon: <ChevronRight className="w-4 h-4" />
    },
    {
      good: "Straps stay in place without digging",
      bad: "Straps slip or cause pain",
      icon: <ChevronRight className="w-4 h-4" />
    }
  ];

  const guidelineSections = [
    {
      id: 'band',
      title: 'Band Fit Guidelines',
      icon: '👕',
      content: [
        'Should be snug but not tight',
        'Should be parallel to the ground',
        'Should fasten on loosest hook when new',
        'Should allow for two fingers underneath'
      ]
    },
    {
      id: 'cups',
      title: 'Cup Fit Guidelines',
      icon: '🎯',
      content: [
        'Breast tissue should fill cups without spillage',
        'No gaps or wrinkling in the cups',
        'Underwire should sit flat against ribcage',
        'Center gore should touch sternum'
      ]
    },
    {
      id: 'straps',
      title: 'Strap Fit Guidelines',
      icon: '➰',
      content: [
        'Should stay in place without slipping',
        'Should not dig into shoulders',
        'Should be able to fit two fingers under straps',
        'Should provide only 20% of support'
      ]
    }
  ];

  return (
    <>
      <Helmet>
        <title>Expert Bra Fitting Guide - How to Find Your Perfect Fit | BraSizeCalculatorPro</title>
        <meta name="description" content="Master the art of bra fitting with our comprehensive guide. Learn proper measuring techniques, understand fit indicators, and discover how to achieve the perfect bra fit." />
        <meta name="keywords" content="bra fitting guide, how to fit a bra, bra measurement guide, proper bra fit, bra sizing tips" />
        <link rel="canonical" href="https://brasizecalculatorpro.com/bra-fitting-guide" />
      </Helmet>
      <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white py-12">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Header Section */}
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
              Perfect Bra Fitting Guide
            </h1>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Master the art of finding your perfect bra fit with our comprehensive guide
            </p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-12">
            {/* Quick Fit Check Section - Now a sidebar */}
            <div className="lg:col-span-1">
              <Card className="sticky top-4 hover:shadow-lg transition-shadow">
                <CardHeader className="border-b">
                  <CardTitle className="flex items-center gap-2 text-lg">
                    <CheckCircle2 className="w-5 h-5 text-green-500" />
                    Quick Fit Checklist
                  </CardTitle>
                </CardHeader>
                <CardContent className="p-4">
                  <div className="space-y-4">
                    {fitCheckItems.map((item, index) => (
                      <div key={index} className="bg-white rounded-lg p-4 border border-gray-100">
                        <div className="flex items-start gap-2 text-green-600 mb-2">
                          <CheckCircle2 className="w-4 h-4 mt-1 flex-shrink-0" />
                          <span className="font-medium text-sm">{item.good}</span>
                        </div>
                        <div className="flex items-start gap-2 text-red-600">
                          <AlertCircle className="w-4 h-4 mt-1 flex-shrink-0" />
                          <span className="text-sm">{item.bad}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            </div>

            {/* Main Content Area */}
            <div className="lg:col-span-2 space-y-8">
              {/* Measuring Guide Section */}
              <Card className="hover:shadow-lg transition-shadow">
                <CardHeader 
                  className="cursor-pointer border-b"
                  onClick={() => setShowMeasuringTips(!showMeasuringTips)}
                >
                  <CardTitle className="flex items-center justify-between text-lg">
                    <div className="flex items-center gap-2">
                      <Camera className="w-5 h-5 text-blue-500" />
                      How to Measure
                    </div>
                    <ChevronDown 
                      className={`w-5 h-5 transition-transform ${showMeasuringTips ? 'rotate-180' : ''}`}
                    />
                  </CardTitle>
                </CardHeader>
                {showMeasuringTips && (
                  <CardContent className="p-6">
                    <div className="space-y-6">
                      <div className="border-l-4 border-blue-500 pl-4 py-2">
                        <h3 className="font-semibold mb-2 text-blue-700">Band Size</h3>
                        <p className="text-gray-700">Measure directly under your breasts. Round to the nearest even number.</p>
                      </div>
                      <div className="border-l-4 border-pink-500 pl-4 py-2">
                        <h3 className="font-semibold mb-2 text-pink-700">Cup Size</h3>
                        <p className="text-gray-700">Measure around the fullest part of your bust. Subtract your band measurement.</p>
                      </div>
                      <div className="mt-4 text-gray-600 text-right">
  <Link to="/bra-size-measuring-guide" className="text-purple-600 hover:text-purple-800 inline-flex items-center">
    <span className="leading-none">View detailed measuring guide</span>
    <ChevronRight className="w-4 h-4 ml-1 leading-none" />
  </Link>
</div>

                    </div>
                  </CardContent>
                )}
              </Card>

              {/* Guidelines Sections */}
              <div className="space-y-4">
                {guidelineSections.map((section) => (
                  <Card key={section.id} className="hover:shadow-lg transition-shadow">
                    <CardHeader 
                      className={`cursor-pointer ${activeSection === section.id ? 'border-b' : ''}`}
                      onClick={() => setActiveSection(activeSection === section.id ? '' : section.id)}
                    >
                      <CardTitle className="flex items-center justify-between text-lg">
                        <div className="flex items-center gap-2">
                          <span className="text-xl">{section.icon}</span>
                          {section.title}
                        </div>
                        <ChevronDown 
                          className={`w-5 h-5 transition-transform ${activeSection === section.id ? 'rotate-180' : ''}`}
                        />
                      </CardTitle>
                    </CardHeader>
                    {activeSection === section.id && (
                      <CardContent className="p-6">
                        <ul className="space-y-3">
                          {section.content.map((item, index) => (
                            <li key={index} className="flex items-start gap-3 text-gray-700">
                              <ChevronRight className="w-4 h-4 mt-1 text-purple-500 flex-shrink-0" />
                              <span>{item}</span>
                            </li>
                          ))}
                        </ul>
                      </CardContent>
                    )}
                  </Card>
                ))}
              </div>

              {/* Common Issues Section */}
              <Card className="hover:shadow-lg transition-shadow">
                <CardHeader className="border-b">
                  <CardTitle className="flex items-center gap-2 text-lg">
                    <AlertCircle className="w-5 h-5 text-yellow-500" />
                    Common Fit Issues & Solutions
                  </CardTitle>
                </CardHeader>
                <CardContent className="p-6">
                  <div className="grid sm:grid-cols-2 gap-4">
                    {[
                      { title: 'Band Riding Up', solution: 'Try a smaller band size or tighten the band' },
                      { title: 'Cup Overflow', solution: 'Go up a cup size while keeping the same band size' },
                      { title: 'Straps Digging In', solution: 'Loosen straps or try a smaller band size' },
                      { title: 'Gaping Cups', solution: 'Try a smaller cup size or different cup style' }
                    ].map((issue, index) => (
                      <div key={index} className="bg-white rounded-lg p-4 border border-gray-100">
                        <h3 className="font-semibold mb-2 text-purple-700">{issue.title}</h3>
                        <p className="text-gray-700">{issue.solution}</p>
                      </div>
                    ))}
                  </div>
                  <div className="mt-4 text-gray-600 text-right">
  <Link to="/bra-fit-issues" className="text-purple-600 hover:text-purple-800 inline-flex items-center">
    <span className="leading-none">View complete fit issues guide</span>
    <ChevronRight className="w-4 h-4 ml-1 leading-none" />
  </Link>
</div>

                </CardContent>
              </Card>

              {/* When to Get Fitted Section */}
              <Card className="hover:shadow-lg transition-shadow">
                <CardHeader className="border-b">
                  <CardTitle className="flex items-center gap-2 text-lg">
                    <Ruler className="w-5 h-5 text-purple-500" />
                    When to Get Professionally Fitted
                  </CardTitle>
                </CardHeader>
                <CardContent className="p-6">
                  <div className="grid sm:grid-cols-3 gap-4">
                    {[
                      { timing: 'After significant weight changes', icon: '⚖️' },
                      { timing: 'During/after pregnancy', icon: '🤰' },
                      { timing: 'Every 6-12 months', icon: '📅' }
                    ].map((item, index) => (
                      <div key={index} className="text-center p-4 border rounded-lg bg-white hover:border-purple-200 transition-colors">
                        <div className="text-2xl mb-2">{item.icon}</div>
                        <p className="text-gray-700">{item.timing}</p>
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BraFittingGuide;
