import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto">
          {/* Hero Section */}
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
              Privacy Policy
            </h1>
            <p className="text-gray-600">
              Last updated: December 7, 2024
            </p>
          </div>

          {/* Table of Contents */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 mb-8">
            <h2 className="text-xl font-semibold mb-4">Table of Contents</h2>
            <nav className="space-y-2">
              <a href="#introduction" className="block text-purple-600 hover:text-purple-700">1. Introduction</a>
              <a href="#information-collection" className="block text-purple-600 hover:text-purple-700">2. Information We Collect</a>
              <a href="#information-use" className="block text-purple-600 hover:text-purple-700">3. How We Use Your Information</a>
              <a href="#data-security" className="block text-purple-600 hover:text-purple-700">4. Data Security</a>
              <a href="#cookies" className="block text-purple-600 hover:text-purple-700">5. Cookies</a>
              <a href="#third-party" className="block text-purple-600 hover:text-purple-700">6. Third-Party Services</a>
              <a href="#your-rights" className="block text-purple-600 hover:text-purple-700">7. Your Rights</a>
              <a href="#contact" className="block text-purple-600 hover:text-purple-700">8. Contact Us</a>
            </nav>
          </div>

          {/* Content Sections */}
          <div className="space-y-8">
            <section id="introduction" className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
              <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
              <div className="prose prose-purple max-w-none">
                <p className="text-gray-600">
                  At Bra Size Calculator Pro, we take your privacy seriously. This Privacy Policy explains how we collect, use, 
                  disclose, and safeguard your information when you visit our website.
                </p>
              </div>
            </section>

            <section id="information-collection" className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
              <h2 className="text-2xl font-semibold mb-4">2. Information We Collect</h2>
              <div className="prose prose-purple max-w-none">
                <h3 className="text-xl font-medium mb-3">Personal Information</h3>
                <p className="text-gray-600 mb-4">
                  We may collect personal information that you voluntarily provide when using our calculator or contacting us, including:
                </p>
                <ul className="list-disc pl-6 mb-4 text-gray-600">
                  <li>Name and email address (when you contact us)</li>
                  <li>Measurement data (when using our calculator)</li>
                  <li>Browser information and device data</li>
                </ul>
              </div>
            </section>

            <section id="information-use" className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
              <h2 className="text-2xl font-semibold mb-4">3. How We Use Your Information</h2>
              <div className="prose prose-purple max-w-none">
                <p className="text-gray-600 mb-4">We use the collected information for:</p>
                <ul className="list-disc pl-6 mb-4 text-gray-600">
                  <li>Providing accurate bra size calculations</li>
                  <li>Responding to your inquiries</li>
                  <li>Improving our calculator and services</li>
                  <li>Sending newsletters (if subscribed)</li>
                </ul>
              </div>
            </section>

            <section id="data-security" className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
              <h2 className="text-2xl font-semibold mb-4">4. Data Security</h2>
              <div className="prose prose-purple max-w-none">
                <p className="text-gray-600">
                  We implement appropriate security measures to protect your personal information. However, 
                  no method of transmission over the internet is 100% secure.
                </p>
              </div>
            </section>

            <section id="cookies" className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
              <h2 className="text-2xl font-semibold mb-4">5. Cookies</h2>
              <div className="prose prose-purple max-w-none">
                <p className="text-gray-600">
                  We use cookies to enhance your experience on our website. You can control cookie settings 
                  through your browser preferences. For more information, please see our{' '}
                  <a href="/cookie-policy" className="text-purple-600 hover:text-purple-700">
                    Cookie Policy
                  </a>.
                </p>
              </div>
            </section>

            <section id="third-party" className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
              <h2 className="text-2xl font-semibold mb-4">6. Third-Party Services</h2>
              <div className="prose prose-purple max-w-none">
                <p className="text-gray-600">
                  We may use third-party services for analytics and improvement purposes. These services 
                  may collect information about your use of our website.
                </p>
              </div>
            </section>

            <section id="your-rights" className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
              <h2 className="text-2xl font-semibold mb-4">7. Your Rights</h2>
              <div className="prose prose-purple max-w-none">
                <p className="text-gray-600 mb-4">You have the right to:</p>
                <ul className="list-disc pl-6 mb-4 text-gray-600">
                  <li>Access your personal information</li>
                  <li>Request correction of your data</li>
                  <li>Request deletion of your data</li>
                  <li>Opt-out of marketing communications</li>
                </ul>
              </div>
            </section>

            <section id="contact" className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
              <h2 className="text-2xl font-semibold mb-4">8. Contact Us</h2>
              <div className="prose prose-purple max-w-none">
                <p className="text-gray-600">
                  If you have any questions about this Privacy Policy, please{' '}
                  <Link to="/contact" className="text-purple-600 hover:text-purple-700">
                    contact us through our contact page
                  </Link>.
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
